import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { COLORS } from "../../Constants";
import { Tooltip } from "antd";
import { Input, Pagination, Select, Table } from "antd";
import { Link } from "react-router-dom";
import axiosConfig from "../../axiosConfig";
import { Switch } from "antd";
import { CSVLink } from "react-csv";
import downloadIcon from "../../assets/images/Export CSV.svg";
import eyeIcon from "../../assets/images/eye.svg";

import {
  CaretUpOutlined,
  CaretDownOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import UseDebounce from "../general/UseDebounce";
import HistoryDrawer from "./HistoryDrawer/HistoryDrawer";
import { useDispatch, useSelector } from "react-redux";
import { setSitePerfomanceRowData } from "../redux/reducers/UserReducer";
import SiteHoldUnHoldModal from "./Modals/SiteHoldUnHoldModal";

function SitePerformance2() {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [pageCount, setPageCount] = useState(10);
  const [sortFeild, setSortFeild] = useState("SiteHold");
  const [sortOrder, setSortOrder] = useState("0");
  const [currentPage, setCurrentPage] = useState(1);
  const [siteVal, setSiteVal] = useState();
  const [siteCode, setSiteCode] = useState("");
  const [csvData, setCSVData] = useState([]);
  const [sortClicked, setSortClicked] = useState(false);
  const [search, setSearch] = useState({
    siteCode: null,
    siteName: null,
    startDate: null,
    endDate: null,
  });

  const debouncingSiteCode = UseDebounce(siteCode, 500);

  const { userId } = useSelector((state) => state?.user.userProfile);
  const scrollableDivRef = useRef(null);
  const [openHistoryDrawer, setOpenHistoryDrawer] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [latestHistoryData, setLatestHistoryData] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [historyPage, setHistoryPage] = useState(5);
  const [openSiteHoldUnHoldModal, setOpenSiteHoldUnHoldModal] = useState(false);
  const [commentTypedFromModal, setCommentTypedFromModal] = useState("");
  const [historyDataCount, setHistoryDataCount] = useState(0);
  const rowData = useSelector((state) => state?.user?.sitePerfomanceRowData);

  const showHoldUnHoldModal = () => {
    setOpenSiteHoldUnHoldModal(true);
  };

  const hideHoldUnHoldModal = () => {
    setOpenSiteHoldUnHoldModal(false);
  };

  const handleCustomSort = (column, currentSort) => {
    if (currentSort === "0") {
      setSortFeild(column);
      setSortOrder("1");
      setSortClicked(true);
    } else if (currentSort === "1") {
      setSortFeild(null);
      setSortOrder(null);
      setSortClicked(true);
    } else {
      setSortFeild(column);
      setSortOrder("0");
      setSortClicked(true);
    }
  };

  const handleSwitch = (data) => {
    setSiteVal(data.site);
    showHoldUnHoldModal(true);

    if (data?.hasHistory === "true") {
      fetchHistoryData("paramsChange", data);
    }

    dispatch(setSitePerfomanceRowData(data));
  };

  //-------| History Drawer |-------//

  //-------| History Drawer Get Api |-------//
  const fetchHistoryData = (change, data) => {
    setLoading(true);

    const pageSize = change === "paramsChange" ? 1 : historyPage;
    const currentSiteCode = change ? data?.site : siteCode;

    const queryParams = {
      SiteHoldHistory: JSON.stringify({
        site: currentSiteCode,
        pageNumber: 1,
        pageSize: pageSize,
      }),
    };

    axiosConfig
      .get(`GetSiteHoldHistory`, {
        params: queryParams,
      })

      .then((res) => {
        const { statusCode, data, paginationData } = res.data;

        if (statusCode === 200) {
          setHistoryData(data);
          setLatestHistoryData(data[0]);
          setHistoryPage((prevPage) => prevPage + 5);
          setHistoryDataCount(paginationData?.count);
        } else {
          setLoading(false);
        }

        if (data?.length === 0 || data?.length < 10) {
          setHasMore(false);
        }

        setLoading(false);
      })

      .catch((err) => {
        console.error("Error in History Api", err);
        setHasMore(false);
      });
  };

  const showHistoryDrawer = (rowData) => {
    dispatch(setSitePerfomanceRowData(rowData));
    setOpenHistoryDrawer(true);
    fetchHistoryData("drawerItem", rowData);
  };

  const hideHistoryDrawer = () => {
    setOpenHistoryDrawer(false);
  };

  const handleScrollHistory = debounce(() => {
    const scrollableDiv = scrollableDivRef?.current;

    const scrollTop = scrollableDiv?.scrollTop;
    const scrollHeight = scrollableDiv?.scrollHeight;
    const clientHeight = scrollableDiv?.clientHeight;

    const isNearBottom =
      scrollTop > 0 && scrollHeight - scrollTop - clientHeight < 10;
    const dataCountCheck = historyData?.length !== historyDataCount;

    if (isNearBottom && dataCountCheck) {
      fetchHistoryData("scrollItem", rowData);
    }
  }, 200);

  //-------| Handling Multiple Api Calls |-------//
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  const handleCommentFromModal = (newComment) => {
    setCommentTypedFromModal(newComment);
  };

  const columns = [
    {
      title: (
        <TableHeader>
          <Top>
            <HeaderName>Site Code</HeaderName>
          </Top>
          <Input
            placeholder="Search..."
            prefix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            onChange={(e) => {
              setSiteCode(e.target.value);
              setCurrentPage(1);
            }}
            style={{ width: 150, padding: "5px 10px" }}
          />
        </TableHeader>
      ),
      dataIndex: "site",
      key: "site",
      // Enable sorting for this column
      className: "custom-sort-header", // Add the custom class
    },

    {
      title: (
        <TableHeader>
          <Top>
            <HeaderName>Records to be processed</HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: "recordsToBeProcessed",
      key: "recordsToBeProcessed",
      // Enable sorting for this column
      className: "custom-sort-header", // Add the custom class
    },

    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("SiteHold", sortOrder)}>
            <HeaderName>Hold/Unhold</HeaderName>
            <Sort>
              {sortFeild === "SiteHold" ? (
                sortOrder === "0" ? (
                  <Icon>
                    <CaretUpOutlined
                      style={{
                        width: "30px",

                        color: "#00a7f4",
                      }}
                    />
                  </Icon>
                ) : (
                  <Icon>
                    <CaretUpOutlined
                      style={{
                        width: "30px",
                        color: "#888888",
                      }}
                    />
                  </Icon>
                )
              ) : (
                <Icon>
                  <CaretUpOutlined
                    style={{
                      width: "30px",

                      color: "#888888",
                    }}
                  />
                </Icon>
              )}
              {sortFeild === "SiteHold" ? (
                sortOrder === "1" ? (
                  <Icon>
                    <CaretDownOutlined
                      style={{
                        width: "30px",

                        color: "#00a7f4",
                      }}
                    />
                  </Icon>
                ) : (
                  <Icon>
                    <CaretDownOutlined
                      style={{
                        width: "30px",

                        color: "#888888",
                      }}
                    />
                  </Icon>
                )
              ) : (
                <Icon>
                  <CaretDownOutlined
                    style={{
                      width: "30px",

                      color: "#888888",
                    }}
                  />
                </Icon>
              )}
            </Sort>
          </Top>
        </TableHeader>
      ),
      align: "right",
      dataIndex: ["siteHold", "site"],
      key: "siteHold",

      render: (text, data) => {
        return (
          <ButtonContainer>
            <Edit>
              <Switch
                checked={data.siteHold === 1 ? true : false}
                // // checked={switchValue}
                onChange={() => handleSwitch(data)}
              />
            </Edit>
          </ButtonContainer>
        );
      },
    },
    {
      title: (
        <TableHeader>
          <Top>
            <HeaderName>View History</HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: "hasHistory",
      key: "hasHistory",

      render: (text, data) => {
        return text === "true" ? (
          <HistoryBox onClick={() => showHistoryDrawer(data)}>
            <img src={eyeIcon} alt="eye" />
            <ViewText>View</ViewText>
          </HistoryBox>
        ) : (
          <ViewText>--</ViewText>
        );
      },
    },
  ];

  const handlePageSizeChange = (size) => {
    setPageCount(size);
    setCurrentPage(1);
  };

  const fetchData = () => {
    setLoading(true);

    const queryParams = {
      SitePerformanceInput: JSON.stringify({
        site: siteCode ? siteCode : null,
        pageNumber: currentPage,
        pageSize: pageCount,
        sortBy: sortClicked ? sortFeild : null,
        sortOrder: sortClicked ? sortOrder : null,
      }),
    };
    axiosConfig
      .get(
        `GetSitePerformance?`,

        {
          params: queryParams,
        }
      )
      .then((res) => {
        const { statusCode, data, paginationData } = res.data;
        if (statusCode === 200) {
          setTableData(data);

          setPaginationData(paginationData);
        } else {
          setTableData([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const [csv, setCsv] = useState([]);

  const fetchDataCsv = () => {
    setLoading(true);
    const queryParams = {
      SitePerformanceInput: JSON.stringify({
        site: null,
        pageNumber: currentPage,
        pageSize: 100000,
      }),
    };
    axiosConfig
      .get(
        `GetSitePerformance?`,

        {
          params: queryParams,
        }
      )
      .then((res) => {
        const { statusCode, data, paginationData } = res?.data;
        if (statusCode === 200) {
          setCsv(data);

          setPaginationData(paginationData);
        } else {
          setCsv([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  //-------| Update the Site Hold/Unhold With Reason Api |-------//
  const fetchHoldUnholdApi = (site) => {
    setLoading(true);
    setSortClicked(false);

    const queryParams = {
      SiteHoldInput: JSON.stringify({
        site: rowData?.site,
        reason: commentTypedFromModal,
        webuserid: userId,
      }),
    };

    axiosConfig
      .get(
        `UpdateSiteHoldFromUI?`,

        {
          params: queryParams,
        }
      )
      .then((res) => {
        setLoading(false);
        fetchData();
        fetchDataCsv();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    setSortClicked(false);
  }, [
    pageCount,
    currentPage,
    // siteCode,
    debouncingSiteCode,
    sortFeild,
    sortOrder,
  ]);

  useEffect(() => {
    fetchDataCsv();
  }, []);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter?.column?.key) {
      const { field, order } = sorter;
      setSortFeild(order === "ascend" || "descend" ? field : null);
      setSortOrder(order === "ascend" ? "0" : order === "descend" ? "1" : "0");
    }
  };

  const [formatedArray, setFormatedArray] = useState([]);

  useEffect(() => {
    const temp = csv?.map((data, i) => ({
      siteCode: data?.site,
      siteHold: data?.siteHold === 0 ? "Unhold" : "Hold",
      recordsToBeProcessed: data.recordsToBeProcessed,
    }));

    setFormatedArray(temp);
  }, [csv]);
  var dateField = ["", "", ""];
  if (csv.length) {
    dateField = [];
    for (let i = 0; i < csv.length; i++) {
      dateField.push(csv);
    }
  }

  const headers = [
    { label: "Site Code", key: "siteCode" },
    { label: "Records to br processed", key: "recordsToBeProcessed" },
    { label: "Hold/Unhold", key: "siteHold" },
  ];

  return (
    <>
      <Container>
        <TopSection>
          <Title>Site Performance</Title>
          <RightSection>
            <CsvIcon
              data={formatedArray}
              headers={headers}
              filename={"Site Performance.csv"}
            >
              <Tooltip title="Download CSV">
                <img
                  style={{ width: "35px", height: "35px" }}
                  src={downloadIcon}
                />
              </Tooltip>
            </CsvIcon>
          </RightSection>
        </TopSection>
        <SiteListContainer>
          {" "}
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            onChange={handleTableChange}
            loading={isLoading}
          />
          <BottomSection>
            <div>
              <span>Rows per page: </span>
              <Select
                defaultValue={pageCount}
                onChange={handlePageSizeChange}
                style={{ width: 80 }}
              >
                <Select.Option value={10}>10</Select.Option>
                <Select.Option value={15}>15</Select.Option>
                <Select.Option value={30}>30</Select.Option>
                <Select.Option value={50}>50</Select.Option>
                <Select.Option value={100}>100</Select.Option>
              </Select>
            </div>

            <PaginationCover>
              <Pagination
                total={paginationData?.count}
                currentPage={currentPage}
                pageSize={pageCount}
                onChange={handlePageChange}
                current={currentPage}
              />
            </PaginationCover>
          </BottomSection>
        </SiteListContainer>
      </Container>

      {openHistoryDrawer && (
        <HistoryDrawer
          open={openHistoryDrawer}
          hideHistoryDrawer={hideHistoryDrawer}
          historyData={historyData}
          hasMore={hasMore}
          loading={isLoading}
          handleScrollHistory={handleScrollHistory}
          scrollableDivRef={scrollableDivRef}
          historyDataCount={historyDataCount}
        />
      )}

      {openSiteHoldUnHoldModal && (
        <SiteHoldUnHoldModal
          openModal={openSiteHoldUnHoldModal}
          closeModal={hideHoldUnHoldModal}
          latestHistoryData={latestHistoryData}
          handleCommentFromModal={handleCommentFromModal}
          fetchHoldUnholdApi={fetchHoldUnholdApi}
        />
      )}
    </>
  );
}

export default SitePerformance2;
const Container = styled.div`
  padding: 20px;
  .ant-pagination-options {
    display: none;
  }
  :where(.css-dev-only-do-not-override-1vr7spz).ant-picker {
    padding: 0 11px;
  }
  :where(.css-dev-only-do-not-override-1vr7spz).ant-table-wrapper
    .ant-table-column-title,
  :where(.css-dev-only-do-not-override-1vr7spz).ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    font-size: 14px;
    font-weight: 500;
  }
  :where(.css-dev-only-do-not-override-3mqfnx).ant-table-wrapper
    .ant-table-thead
    > tr
    > th {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .custom-sort-header {
    cursor: pointer;
  }
`;
const TopSection = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
`;
const Title = styled.h2`
  color: ${COLORS.THEME_COLOR};
  font-size: 18px;
  font-weight: 500;
`;
const RightSection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
const SiteListContainer = styled.div`
  margin-top: 10px;
  max-height: calc(100vh - 180px);
  min-height: calc(100vh - 180px);
  overflow: scroll;
  border: 0.5px solid #d0d1d2;
  border-radius: 10px;
  .ant-table {
    min-height: calc(100vh - 240px) !important;
    max-height: calc(100vh - 240px) !important;
    overflow: scroll;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: 0.5px solid #d0d1d2;
    border-collapse: collapse;
    /* background-color: #fff; */
    padding: 10px 15px;
    font-size: 12px;
  }
  .ant-table-thead > tr > th {
    font-weight: 600;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
  }
  .ant-pagination-item a {
    color: #000 !important;
  }
`;
const ButtonContainer = styled.div`
  display: block;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
`;
const Edit = styled(Link)``;
const Delete = styled.span``;
const TableHeader = styled.div`
  input {
    padding: 0 10px;
  }
`;

const HeaderName = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
  white-space: nowrap;
`;
const BottomSection = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
`;
const PaginationCover = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;
const Top = styled.div`
  display: flex;
  align-items: center;
`;
const Sort = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translate(0, -5px);
  margin-left: 10px;
`;
const Icon = styled.span`
  display: block;
  height: 10px;
`;
const CsvIcon = styled(CSVLink)`
  padding: 4px;
`;

const HistoryBox = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

const ViewText = styled.span`
  font-size: 1rem;
  font-weight: 400;
  color: #3b7ffc;
  user-select: none;
`;
