import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { COLORS } from "../../Constants";
import {
	DatePicker,
	Pagination,
	Select as ANTDSelect,
	notification,
} from "antd";
import search_icon from "../../assets/images/search.svg";
import CarDetailsCard from "../includes/clean-pairs/CarDetailsCard";

import axiosConfig from "../../axiosConfig";
import DataFetchIngLoader from "../general/DataFetchIngLoader";
import NoData from "../general/NoData";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { updateDataProcessed } from "../redux/reducers/UserReducer";

function CleanPairs() {
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(50); // I

	const { userId } = useSelector((state) => state?.user?.userProfile);

	const [isLoading, setLoading] = useState(true);
	const [pageCount, setPageCount] = useState(0);
	const [siteCodes, setSiteCodes] = useState([]);
	const [cleanPairData, setCleanPairData] = useState([]);
	const [paginationData, setPaginationData] = useState({});
	const [searchValue, setSearchValue] = useState({});

	const [cleanPairIds, setCleanPairIds] = useState([]);
	const [search, setSearch] = useState({
		siteCode: null,
		siteName: null,
		startDate: null,
		endDate: null,
		plate: null,
	});

	const dispatch = useDispatch();

	//function to get all the id of clean pairs of current page
	function getIdsAsObjectsFromArray(arr) {
		return arr.map((obj) => ({ id: obj.id }));
	}

	useEffect(() => {
		const ids = getIdsAsObjectsFromArray(cleanPairData);
		setCleanPairIds(ids);
	}, [cleanPairData]);

	const handlePageChange = (page, pageSize) => {
		setCurrentPage(page);
	};

	const handlePageSizeChange = (size) => {
		setPageSize(size);
		setCurrentPage(1);
	};
	const handleSelectChange = (event, id) => {
		const selectedValue = event.target.value;

		// Update the state to add the "reason" property to the selected object
		setCleanPairData((prevData) =>
			prevData.map((item) =>
				item.id === id ? { ...item, reason: selectedValue } : item
			)
		);
	};
	function formatAntdDatePickerDate(selectedDate) {
		// Use the `format` function from moment.js to format the date
		return selectedDate.format("YYYY-MM-DD");
	}

	//function to handle the search
	const handleSearch = (column, value) => {
		const data = { ...search };

		if (column === "startDate" || column === "endDate") {
			if (value) {
				const selectedDate = value;
				const formattedDate = formatAntdDatePickerDate(selectedDate);
				data[column] = formattedDate;
			} else {
				data[column] = null;
			}
		} else {
			data[column] = value;
		}

		setSearch(data);
	};
	const fetchProcessedData = () => {
		axiosConfig
			.get(`GetProcessingProcessedCountClean/?UserId=${1}`)
			.then((res) => {
				const { statusCode, processedCount, processingCount } =
					res.data;
				if (statusCode === 200) {
					dispatch(
						updateDataProcessed({
							processed: processedCount,
							processing: processingCount,
						})
					);
				} else {
				}

				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};
	useEffect(() => {
		fetchProcessedData();
	}, []);
	//api to fetch the Clean pairs

	const fetchCleanPairs = () => {
		const queryParams = {
			CleanPairInput: JSON.stringify({
				site: search.siteCode?.value,
				startDate: search.startDate,
				endDate: search.endDate,
				plate: search?.plate?.length > 0 ? search.plate : null,
				pageSize: pageSize,
				pageNumber: currentPage,
				userId: userId,
			}),
		};
		axiosConfig
			.get(`GetCleanPairsforUI/`, {
				params: queryParams,
			})
			.then((res) => {
				const { statusCode, data, paginationData } = res.data;
				if (statusCode === 200) {
					setCleanPairData(data);
					setPageCount(paginationData.pageCount);
					setPaginationData(paginationData);
					setLoading(false);
				} else {
					setCleanPairData([]);
					setPaginationData(null);
					setLoading(false);
				}
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				setCleanPairData([]);
				setPaginationData(null);
			});
	};

	useEffect(() => {
		setLoading(true);
		fetchCleanPairs();
	}, [pageSize, currentPage, search]);

	//api to fetch the site codes
	const fetchSitecodes = () => {
		axiosConfig
			.get(`cleanPairsGetSiteConfigforUI/`)
			.then((res) => {
				const { statusCode, data } = res.data;
				if (statusCode === 200) {
					const formattedOptions = data.map((item) => ({
						value: item.site,
						label: item.site,
					}));
					setSiteCodes(formattedOptions);
				} else {
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	useEffect(() => {
		fetchSitecodes();
	}, []);

	//Good to Print API

	//ACCEPT ALL API CALL
	const submitCleanPlates = () => {
		setLoading(true);
		const queryParams = {
			AcceptAllCleanpairsforUI: JSON.stringify([...cleanPairIds]),
		};

		axiosConfig
			.get(`AcceptAllCleanpairsforUI/`, { params: queryParams })
			.then((res) => {
				const { statusCode, data } = res.data;
				if (statusCode === 200) {
					fetchCleanPairs();
					fetchProcessedData();
					notification.success({
						duration: 2,
						message: "Successfully accepted all the pairs",
					});
				} else {
					notification.success({
						duration: 2,
						message: "Something went wrong",
					});
				}
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				notification.success({
					duration: 2,
					message: "Something went wrong",
				});
			});
	};

	//Reject a Pair
	const RejectCleanPair = (id, reason) => {
		// setLoading(true);
		const queryParams = {
			rejectedInput: JSON.stringify({
				id: id,
				rejectionReason: reason,
			}),
		};

		axiosConfig
			.get(`updateRejectedforUI/`, { params: queryParams })
			.then((res) => {
				const { statusCode, data, paginationData } = res.data;
				if (statusCode === 200) {
					notification.success({
						message: "Successfully updated",
						duration: 2,
					});
					fetchCleanPairs();
					fetchProcessedData();
				} else {
					notification.error({
						message: "Something went wrong",
						duration: 2,
					});
				}

				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				notification.error({
					message: "Something went wrong",
					duration: 2,
				});
			});
	};

	return (
		<Container>
			<TopSection>
				<Title>Clean Pairs</Title>
				<RightSection>
					<Cover>
						<Select
							style={{
								width: 120,
							}}
							placeholder="Site Code"
							onChange={(e) => handleSearch("siteCode", e)}
							options={siteCodes}
							isClearable
							styles={{
								minWidth: 120,
								clearIndicator: () => ({
									color: "red",
									marginTop: "3px",
								}),
							}}
						/>
					</Cover>

					<Search>
						<Icon>
							<img src={search_icon} alt="Search" />
						</Icon>
						<input
							type="text"
							placeholder="Plate Number"
							onChange={(e) =>
								handleSearch("plate", e.target.value)
							}
						/>
					</Search>
				</RightSection>
			</TopSection>
			<CarsList>
				{isLoading ? (
					<Loader>
						<DataFetchIngLoader />
					</Loader>
				) : cleanPairData?.length > 0 ? (
					cleanPairData?.map((data) => (
						<CarDetailsCard
							fetchCleanPairs={fetchCleanPairs}
							key={data.id}
							id={data.id}
							handleSelectChange={handleSelectChange}
							data={data}
							RejectCleanPair={RejectCleanPair}
						/>
					))
				) : (
					<NodataCover>
						<NoData />
					</NodataCover>
				)}
			</CarsList>
			<BottomSection>
				<div>
					<span>Rows per page: </span>
					<ANTDSelect
						defaultValue={pageSize}
						onChange={handlePageSizeChange}
						style={{ width: 80 }}
					>
						<ANTDSelect.Option value={10}>10</ANTDSelect.Option>
						<ANTDSelect.Option value={20}>20</ANTDSelect.Option>
						<ANTDSelect.Option value={50}>50</ANTDSelect.Option>
					</ANTDSelect>
				</div>
				<PaginationCover>
					<Pagination
						total={paginationData?.count} // Total number of items in the data source
						currentPage={currentPage}
						pageSize={pageSize}
						onChange={handlePageChange}
						current={currentPage}
					/>
				</PaginationCover>

				<Button onClick={submitCleanPlates}>Accept All</Button>
			</BottomSection>
		</Container>
	);
}

export default CleanPairs;
const Container = styled.div`
	padding: 20px;
	padding-top: 10px;
	.ant-pagination-options {
		display: none;
	}
	.css-1u9des2-indicatorSeparator {
		display: none;
	}
`;
const TopSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const Title = styled.h2`
	color: ${COLORS.THEME_COLOR};
	font-size: 18px;
	font-weight: 600;
`;
const RightSection = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 10px;
`;
const Cover = styled.div`
	border-radius: 5px;
	position: relative;
	.ant-picker {
	}
	/* border: 1px solid #d0d1d2; */
`;
const Search = styled.div`
	border-radius: 5px;
	border: 1px solid #d9d9d9;
	display: flex;
	align-items: center;
	width: 150px;
	padding: 0 10px;
	height: 38px;
	input {
		width: 100%;
		padding: 3px 10px;
	}
`;
const Icon = styled.span`
	display: block;
	width: 20px;
	img {
		display: block;
		width: 100%;
	}
`;
const CarsList = styled.div`
	margin-top: 10px;
	max-height: calc(100vh - 210px);
	min-height: calc(100vh - 210px);
	overflow: scroll;
`;
const BottomSection = styled.div`
	margin-top: 10px;
	display: flex;
	justify-content: flex-end;
`;
const Button = styled.span`
	height: 30px;
	border: 1px solid #3d619b;
	width: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #3d619b;
	color: #fff;
	font-size: 13px;
	border-radius: 5px;
	cursor: pointer;
	transition: 0.3s;
	&:hover {
		color: #3d619b;
		background-color: #fff;
	}
`;
const PaginationCover = styled.div`
	display: flex;
	justify-content: center;
	flex: 1;
`;
const Loader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: calc(100vh - 270px);
`;
const NodataCover = styled.div`
	height: calc(100vh - 230px);
	display: flex;
	justify-content: center;
	align-items: center;
`;
const Clear = styled.span`
	background-color: #fff;
	position: absolute;
	top: 10px;
	right: 14px;
`;
