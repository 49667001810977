import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import close from "../../../assets/images/close.svg";
import { COLORS } from "../../../Constants";
import { DatePicker, Pagination, Select, Tooltip, notification } from "antd";
import search__icon from "../../../assets/images/search.svg";
import delete_icon from "../../../assets/images/delete 2.svg";
import save from "../../../assets/images/save.svg";

import entry_img from "../../../assets/images/clean_pairs/entry_img.png";
import exit_img from "../../../assets/images/clean_pairs/car_back.png";
import numberplate from "../../../assets/images/clean_pairs/numberplate.png";
import SimilarPlatesCard from "./general/SimilarPlatesCard";
import { formatDatev2, formatTime } from "../../../utils/HelperFunctions";
import axiosConfig from "../../../axiosConfig";
import DataFetchIngLoader from "../../general/DataFetchIngLoader";
import NoData from "../../general/NoData";

import CleanPairsSilmilarPairs from "../clean-pairs/CleanPairsSilmilarPairs";
import { useSelector } from "react-redux";

function CorrectThePlate({ setModal, data, fetchCleanPairs, id }) {
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10); // I
	const [isLoading, setLoading] = useState(false);
	const [entryPlate, setEntryPlate] = useState("");
	const [paginationData, setPaginationData] = useState({});
	const [exitPlate, setExitPlate] = useState("");
	const { userId } = useSelector((state) => state?.user?.userProfile);
	const [search, setSearch] = useState({
		siteCode: null,
		siteName: null,
		startDate: null,
		endDate: null,
		plate: null,
	});
	useEffect(() => {
		setEntryPlate(data.entryPlate);
		setExitPlate(data.exitPlate);
	}, [data]);

	const handlePageChange = (page, pageSize) => {
		setCurrentPage(page);
	};

	const handlePageSizeChange = (size) => {
		setPageSize(size);
		setCurrentPage(1);
	};
	const submitCorrectPlate = (plateId, motion, plateValue) => {
		setLoading(true);
		const queryParams = {
			correctdPlate: JSON.stringify({
				id: data.id,
				correctedPlate: plateValue,
				plateId: plateId,
				carEntryExit: motion,
				category: "CleanPair",
				userid: userId,
			}),
		};

		axiosConfig
			.get(`cleanPaircorrectPlate/`, { params: queryParams })
			.then((res) => {
				const { statusCode, data, paginationData } = res.data;
				if (statusCode === 200) {
					notification.success({
						message: "Plate successfully updated",
						duration: 2,
					});
					fetchCleanPairs();

					setModal(false);
				} else {
					notification.error({
						message: "Something went wrong",
						duration: 2,
					});
				}

				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				notification.error({
					message: "Something went wrong",
					duration: 2,
				});
			});
	};

	function formatAntdDatePickerDate(selectedDate) {
		return selectedDate.format("YYYY-MM-DD");
	}
	const handleSearch = (column, value) => {
		const data = { ...search };

		if (column === "startDate" || column === "endDate") {
			if (value) {
				const selectedDate = value;
				const formattedDate = formatAntdDatePickerDate(selectedDate);
				data[column] = formattedDate;
			} else {
				data[column] = null;
			}
		} else {
			data[column] = value;
		}

		setSearch(data);
	};
	const [similarPairs, setSimilarPairs] = useState([]);
	const [isSilmLoading, setSimLoading] = useState(true);

	const fetchSilmilarPairs = async () => {
		setSimLoading(true);
		const queryParams = {
			uncleanPairFuzzy: JSON.stringify({
				entryPlateid: data.entryPlateId,
				exitPlateid: data.exitPlateId,
				category: "Fuzzy",
				ArrivalTime: search.startDate,
				departureTime: search.endDate,
				plate: search.plate,
				pagesize: pageSize,
				pagenumber: currentPage,
			}),
		};
		axiosConfig
			.get(`GetUncleanSimilarpairs/`, {
				params: queryParams,
			})
			.then((res) => {
				const { statusCode, data, paginationData } = res.data;
				if (statusCode === 200) {
					setSimilarPairs(data);
					setPaginationData(paginationData);
				} else {
					setSimilarPairs([]);
				}
				setSimLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setSimLoading(false);
				setSimilarPairs([]);
			});
	};
	useEffect(() => {
		fetchSilmilarPairs();
	}, [data, search, pageSize, currentPage]);

	const submitPlate = () => {
		if (entryPlate !== data.entryPlate || exitPlate !== data.entryPlate) {
			if (entryPlate !== data.entryPlate) {
				submitCorrectPlate(data?.entryPlateId, "Entry", entryPlate);
			}

			if (exitPlate !== data.entryPlate) {
				submitCorrectPlate(data?.exitPlateId, "Exit", exitPlate);
			}
		} else {
			notification.error({
				message: "Plate values has no change",
				duration: 2,
			});
		}
	};

	function convertToUpperCaseAndRemoveSpaces(inputString) {
		// Convert the string to uppercase
		let uppercasedString = inputString.toUpperCase();

		// Remove spaces from the string
		let stringWithoutSpaces = uppercasedString.replace(/\s/g, "");

		return stringWithoutSpaces;
	}

	return (
		<Container i>
			<ModalContent>
				<Close onClick={() => setModal(false)}>
					<img src={close} alt="" />
				</Close>
				<Title>Break the plates</Title>
				<CarCover>
					<CarDetails>
						<Cover>
							<SectionTitle>Entry Details:</SectionTitle>
							<Entry>
								<Tooltip
									overlayInnerStyle={{ width: "620px" }}
									title={
										<img
											src={
												data.entryfullImage ||
												data.entryImage2
											}
											alt=""
											style={{
												maxWidth: "600px",
												width: "100%",
											}}
										/>
									}
									color={"black"}
								>
									<CarImage>
										<img
											src={
												data.entryfullImage ||
												data.entryImage2
											}
											alt="Entry Img"
										/>
									</CarImage>
								</Tooltip>
								<EntryDetails>
									<Feilds>
										<FeildName>Plate image:</FeildName>
										<FeildData>
											<Tooltip
												title={
													<img
														src={
															data.entryCroppedimage ||
															data.entryImage1
														}
														alt=""
														style={{
															maxWidth: "300px",
														}}
													/>
												}
												color={"black"}
											>
												<NumberPlate>
													<img
														src={
															data.entryCroppedimage ||
															data.entryImage1
														}
														alt=""
													/>
												</NumberPlate>
											</Tooltip>
										</FeildData>
									</Feilds>
									<Feilds>
										<FeildName>Plate No:</FeildName>
										<FeildData
											style={{
												fontFamily: "Roboto Mono",
												fontWeight: 600,
												fontSize: "18px",
											}}
										>
											{data.entryPlate}
										</FeildData>
									</Feilds>
									<Feilds>
										<FeildName>Entry Date:</FeildName>
										<FeildData
											style={{
												// fontFamily: "Roboto Mono",
												fontWeight: 600,
												fontSize: 16,
											}}
										>
											{formatDatev2(
												data?.arrivalTime ||
													data?.entryTime
											)}
										</FeildData>
									</Feilds>
									<Feilds>
										<FeildName>Entry Time:</FeildName>
										<FeildData
											style={{
												// fontFamily: "Roboto Mono",
												fontWeight: 600,
												fontSize: 16,
											}}
										>
											{formatTime(
												data?.arrivalTime ||
													data?.entryTime
											)}
										</FeildData>
									</Feilds>
									<Feilds>
										<FeildName>Motion:</FeildName>
										<FeildData>
											{data.entryMotion}
										</FeildData>
									</Feilds>
								</EntryDetails>
							</Entry>
							<CorrectThePlateSection>
								Correct the plate
								<input
									type="text"
									value={entryPlate}
									style={{
										fontFamily: "Roboto Mono",
										fontWeight: 600,
									}}
									onChange={(e) =>
										setEntryPlate(
											convertToUpperCaseAndRemoveSpaces(
												e.target.value
											)
										)
									}
								/>
							</CorrectThePlateSection>
						</Cover>
						<Cover>
							<SectionTitle>Exit Details:</SectionTitle>
							<Exit>
								{" "}
								<Tooltip
									overlayInnerStyle={{ width: "620px" }}
									title={
										<img
											src={
												data.exitfullImage ||
												data.exitImage2
											}
											alt=""
											style={{
												maxWidth: "600px",
												width: "100%",
											}}
										/>
									}
									color={"black"}
								>
									<CarImage>
										<img
											src={
												data.exitfullImage ||
												data.exitImage2
											}
											alt="Entry Img"
										/>
									</CarImage>
								</Tooltip>
								<EntryDetails>
									<Feilds>
										<FeildName>Plate image:</FeildName>
										<FeildData>
											<Tooltip
												// overlayInnerStyle={{ width: "320px" }}
												title={
													<img
														src={
															data.exitCroppedImage ||
															data.exitImage1
														}
														alt=""
														style={{
															maxWidth: "300px",
														}}
													/>
												}
												color={"black"}
											>
												<NumberPlate>
													<img
														src={
															data.exitCroppedImage ||
															data.exitImage1
														}
														alt=""
													/>
												</NumberPlate>
											</Tooltip>
										</FeildData>
									</Feilds>
									<Feilds>
										<FeildName>Plate No:</FeildName>
										<FeildData
											style={{
												fontFamily: "Roboto Mono",
												fontWeight: 600,
												fontSize: "18px",
											}}
										>
											{data.exitPlate}
										</FeildData>
									</Feilds>
									<Feilds>
										<FeildName>Exit Date:</FeildName>
										<FeildData
											style={{
												// fontFamily: "Roboto Mono",
												fontWeight: 600,
												fontSize: 16,
											}}
										>
											{formatDatev2(
												data?.departureTime ||
													data?.exitTime
											)}
										</FeildData>
									</Feilds>
									<Feilds>
										<FeildName>Exit Time:</FeildName>
										<FeildData
											style={{
												// fontFamily: "Roboto Mono",
												fontWeight: 600,
												fontSize: 16,
											}}
										>
											{formatTime(
												data?.departureTime ||
													data?.exitTime
											)}
										</FeildData>
									</Feilds>
									<Feilds>
										<FeildName>Motion:</FeildName>
										<FeildData>{data.exitMotion}</FeildData>
									</Feilds>
								</EntryDetails>
							</Exit>
							<CorrectThePlateSection>
								Correct the plate{" "}
								<input
									type="text"
									value={exitPlate}
									style={{
										fontFamily: "Roboto Mono",
										fontWeight: 600,
									}}
									onChange={(e) =>
										setExitPlate(
											convertToUpperCaseAndRemoveSpaces(
												e.target.value
											)
										)
									}
								/>
							</CorrectThePlateSection>
						</Cover>
					</CarDetails>
					<ButtonSection>
						<RejectButton onClick={() => setModal(false)}>
							Cancel
						</RejectButton>
						<AcceptButton
							onClick={
								entryPlate !== data.entryPlate ||
								exitPlate !== data.entryPlate
									? submitPlate
									: null
							}
							className={
								entryPlate === data.entryPlate &&
								exitPlate === data.entryPlate &&
								"inactive"
							}
						>
							Update Plate
						</AcceptButton>
					</ButtonSection>
				</CarCover>
				<SimilarSection>
					<TopSection>
						<Title>All Plates</Title>
						<RightSection>
							<Search>
								<Icon>
									<img src={search__icon} alt="Search" />
								</Icon>
								<input
									type="text"
									placeholder="Plate Number"
									onChange={(e) =>
										handleSearch("plate", e.target.value)
									}
								/>
							</Search>
							<DCover>
								<DatePicker
									placeholder="Start date"
									format="DD-MM-YYYY"
									onChange={(e) =>
										handleSearch("startDate", e)
									}
								/>
							</DCover>
							<DCover>
								<DatePicker
									placeholder="End date"
									format="DD-MM-YYYY"
									onChange={(e) => handleSearch("endDate", e)}
								/>
							</DCover>
						</RightSection>
					</TopSection>
					<SimilarCars>
						{isSilmLoading ? (
							<Loader>
								<DataFetchIngLoader />
							</Loader>
						) : similarPairs.length > 0 ? (
							similarPairs.map((data, i) => (
								<CleanPairsSilmilarPairs data={data} />
							))
						) : (
							<Loader>
								<NoData />
							</Loader>
						)}
					</SimilarCars>
					<BottomSection>
						<div>
							<span>Rows per page: </span>
							<Select
								defaultValue={pageSize}
								onChange={handlePageSizeChange}
								style={{ width: 80 }}
							>
								<Select.Option value={10}>10</Select.Option>
								<Select.Option value={20}>20</Select.Option>
								<Select.Option value={50}>50</Select.Option>
							</Select>
						</div>
						<PaginationCover>
							<Pagination
								total={paginationData?.count} // Total number of items in the data source
								currentPage={currentPage}
								pageSize={pageSize}
								onChange={handlePageChange}
								current={currentPage}
							/>
						</PaginationCover>
					</BottomSection>
				</SimilarSection>
			</ModalContent>
		</Container>
	);
}

export default CorrectThePlate;
const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	/* backdrop-filter: blur(8px); */
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 999;
`;
const ModalContent = styled.div`
	background-color: white;
	padding: 20px 40px;
	border-radius: 4px;
	width: 1200px;
	position: relative;
`;
const Close = styled.span`
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
	width: 25px;
	height: 25px;
	background-color: #fff;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const Title = styled.h2`
	/* color: ${COLORS.THEME_COLOR}; */
	font-size: 18px;
	font-weight: 500;
`;
const CarCover = styled.div`
	padding: 20px;
	border: 1px solid #d9d9d9;
	margin-top: 10px;
`;
const CarDetails = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 50px;
`;
const Entry = styled.div`
	display: flex;
	gap: 20px;
`;
const Exit = styled.div`
	display: flex;
	gap: 20px;
`;
const CarImage = styled.div`
	width: 100%;
	max-width: 300px;
	/* width: 100px; */
	/* min-width: 100px; */
	height: 150px;
	border-radius: 8px;
	overflow: hidden;
	img {
		display: block;
		/* object-fit: cover; */
		width: 100%;
		height: 150px;
		object-fit: cover;
	}
`;
const EntryDetails = styled.div``;
const NumberPlate = styled.span`
	height: 30px;
	display: block;
	img {
		display: block;
		height: 100%;
	}
	margin-bottom: 5px;
`;
const Feilds = styled.div`
	margin-bottom: 5px;
	display: flex;
	align-items: center;
`;
const FeildName = styled.p`
	font-size: 13px;
	color: #6a6a6a;
	width: 90px;
	&.veh_details {
		width: 60px !important;
	}
	&.voi_details {
		width: 130px;
	}
`;
const FeildData = styled.p`
	font-size: 12px;
	color: ${COLORS.THEME_COLOR};
	white-space: nowrap;
	font-weight: 500;
	text-transform: capitalize;
`;
const Cover = styled.div``;
const CorrectThePlateSection = styled.div`
	display: flex;
	align-items: center;
	white-space: nowrap;
	margin-top: 10px;
	font-size: 14px;
	font-weight: 500;
	input {
		border: 1px solid #d9d9d9;
		width: 200px;
		margin-left: 20px;
	}
`;
const ButtonSection = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
	margin-top: 10px;
`;
const RejectButton = styled.span`
	height: 30px;
	border: 1px solid #3d619b;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #3d619b;
	font-size: 13px;
	border-radius: 5px;
	width: 100px;
	cursor: pointer;
	transition: 0.3s;
	&:hover {
		background-color: #3d619b;
		color: #fff;
	}
`;
const AcceptButton = styled.span`
	height: 30px;
	border: 1px solid #3d619b;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #3d619b;
	color: #fff;
	font-size: 13px;
	border-radius: 5px;
	width: 100px;
	cursor: pointer;
	transition: 0.3s;

	&.inactive {
		filter: grayscale(100%);
		cursor: not-allowed;
		opacity: 0.8;
		:hover {
			background-color: #fff;
			color: #3d619b;
		}
	}
`;
const SimilarSection = styled.div`
	margin-top: 20px;
`;

const SectionTitle = styled.p`
	font-size: 16px;
	margin-bottom: 10px;
`;
const Loader = styled.div`
	min-height: calc(100vh - 500px);
	display: flex;
	justify-content: center;
	align-items: center;
`;
const TopSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-content: center;
`;

const RightSection = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 10px;
`;
const DCover = styled.div`
	border-radius: 5px;
	.ant-picker {
	}
	/* border: 1px solid #d0d1d2; */
`;
const Search = styled.div`
	border-radius: 5px;
	border: 1px solid #d9d9d9;
	display: flex;
	align-items: center;
	width: 150px;
	padding: 0 10px;
	input {
		width: 100%;
		padding: 3px 10px;
	}
`;
const Icon = styled.span`
	display: block;
	width: 20px;
	img {
		display: block;
		width: 100%;
	}
`;
const SimilarCars = styled.div`
	border: 1px solid #d9d9d9;
	padding: 10px 10px;
	margin-top: 10px;
	max-height: calc(100vh - 500px);
	overflow: scroll;
`;
const OptionButton = styled.span`
	display: block;
	width: 20px;
	margin-left: 10px;
	img {
		display: block;
		width: 100%;
	}
	cursor: pointer;
	transition: 0.3s;
	&:hover {
		opacity: 0.6;
	}
`;
const PaginationCover = styled.div`
	display: flex;
	justify-content: flex-end;
	flex: 1;
`;
const BottomSection = styled.div`
	margin-top: 10px;
	display: flex;
	justify-content: flex-end;
`;
