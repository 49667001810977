import { DatePicker, Pagination, Select, notification, TimePicker } from "antd";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { COLORS } from "../../../Constants";
import search__icon from "../../../assets/images/search.svg";
import CarDetailsCard from "../../includes/unclean-pairs/CarDetailsCard";
// import filter from "../../../assets/images/filter.svg";
import CorrectThePlate from "../../includes/modals/CorrectThePlate";
import PotentialDoubleEntryModal from "../../includes/modals/PotentialDoubleEntryModal";
import PotentialIncorectSequenceModal from "../../includes/modals/PotentialIncorrectSequenceModal";
import SingleCarDetails from "./SingleCarDetails";
import SimilarPlatesCard from "../../includes/modals/general/SimilarPlatesCard";
import FuzzyPlatesCard from "../../includes/modals/general/FuzzyPlatesCard";
import axiosConfig from "../../../axiosConfig";
import DataFetchIngLoader from "../../general/DataFetchIngLoader";
import NoData from "../../general/NoData";
import { formatDatev2 } from "../../../utils/HelperFunctions";
import { useSelector } from "react-redux";
import { ReloadOutlined } from "@ant-design/icons";
import UseDebounce from "../../general/UseDebounce";

function InventedPairs() {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModal, setModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isSilmLoading, setSimLoading] = useState(true);
  const [modalType, setModalType] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [activeMenu, setActiveMenu] = useState("similar");
  const [currentInvaildPair, setCurrentInvaildPair] = useState([]);
  const [fuzzyPairs, setFuzzyPairs] = useState([]);
  const [similarPairs, setSimilarPairs] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndtime] = useState(null);
  const [isTopChanged, setTopChanged] = useState(false);
  const [search, setSearch] = useState({
    siteCode: null,
    siteName: null,
    startDate: null,
    endDate: null,
    plate: null,
    // startTime: "00:00",
    // endTime: "23:59",
  });

  const debouncingSearch = UseDebounce(search, 500);

  const [isSingleViewDisabled, setSingleViewDisabled] = useState(false);

  const { userId } = useSelector((state) => state?.user?.userProfile);

  function formatDate(inputDate) {
    const dateObject = new Date(inputDate);

    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
    const year = dateObject.getFullYear();

    // Pad day and month with leading zeros if necessary
    const formattedDay = String(day).padStart(2, "0");
    const formattedMonth = String(month).padStart(2, "0");
    if (formattedDay && formattedMonth && year) {
      return `${year}-${formattedMonth}-${formattedDay}`;
    } else {
      return null;
    }
  }
  function formatTime(inputDateTime) {
    const date = new Date(inputDateTime);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    if (inputDateTime) {
      return `${hours}:${minutes}:${seconds}`;
    } else {
      return null;
    }
  }
  useEffect(() => {
    setTopChanged(true);
    setSearch({
      ...search,
      startDate: formatDate(currentInvaildPair?.entryTime),
      endDate: formatDate(currentInvaildPair?.exitTime),
      // startTime: formatTime(currentInvaildPair?.entryTime),
      // endTime: formatTime(currentInvaildPair?.exitTime),
      plate: "",
      siteCode: null,
      siteName: null,
    });
    setCurrentPage(1);
    setStartTime(formatTime(currentInvaildPair?.entryTime));
    setEndtime(formatTime(currentInvaildPair?.exitTime));
    setTimeout(() => {
      setTopChanged(false);
    }, 300);
  }, [currentInvaildPair]);
  // const [catagory, setCatogory] = useState("");

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  function formatAntdDatePickerDate(selectedDate) {
    return selectedDate.format("YYYY-MM-DD");
  }

  const handleStartTime = (e) => {
    setStartTime(e);
  };

  const handleSearch = (column, value) => {
    setCurrentPage(1);
    // console.log("searhing");
    const data = { ...search };

    if (column === "startDate" || column === "endDate") {
      if (value) {
        const selectedDate = value;
        const formattedDate = formatAntdDatePickerDate(selectedDate);
        data[column] = formattedDate;
      } else {
        data[column] =
          column === "startDate"
            ? formatDate(currentInvaildPair?.entryTime)
            : formatDate(currentInvaildPair?.exitTime);
      }
    } else if (column === "startTime") {
      if (value) {
        setStartTime(value);
      } else {
        setStartTime(formatTime(currentInvaildPair?.entryTime));
      }
    } else if (column === "endTime") {
      if (value) {
        setEndtime(value);
      } else {
        setEndtime(formatTime(currentInvaildPair?.exitTime));
      }
    } else {
      data[column] = value;
    }

    setSearch(data);
  };

  // const handleSearch = (column, value) => {
  // 	const data = { ...search };

  // 	if (column === "startDate" || column === "endDate") {
  // 		if (value) {
  // 			const selectedDate = value;
  // 			const formattedDate = formatAntdDatePickerDate(selectedDate);
  // 			data[column] = formattedDate;
  // 		} else {
  // 			data[column] = null;
  // 		}
  // 	} else {
  // 		data[column] = value;
  // 	}

  // 	setSearch(data);
  // };

  const [voilationData, setVoilationData] = useState([]);

  const onChange = function (date, dateString) {};
  const handlePageSizeChange = (size) => {
    setPageSize(size);
    // Fetch data based on the new pageSize and reset the current page to 1
    setCurrentPage(1);
  };

  const handleSelectChange = (event, id) => {
    const selectedValue = event;

    // Update the state to add the "reason" property to the selected object
    setVoilationData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, reason: selectedValue } : item
      )
    );
  };

  useEffect(() => {
    if (!currentInvaildPair) {
      setSimLoading(false);
      setSimilarPairs([]);
    }
  }, [currentInvaildPair, isSilmLoading]);

  const fetchSilmilarPairs = () => {
    setSimLoading(true);
    const queryParams = {
      uncleanPairFuzzy: JSON.stringify({
        entryPlateid: currentInvaildPair?.entryPlateId,
        exitPlateid: currentInvaildPair?.exitPlateId,
        // category: currentInvaildPair?.category,
        ArrivalTime: search.startDate,
        plate: search.plate,
        pagesize: pageSize,
        pagenumber: currentPage,
        departureTime: search.endDate,
        startTime: startTime,
        endTime: endTime,
        userid: userId,
        category: "Fuzzy",

        pairSite: currentInvaildPair?.site,
        pairEntryDate:
          formatDate(currentInvaildPair?.entryTime) +
          " " +
          formatTime(currentInvaildPair?.entryTime),
        pairExitDate:
          formatDate(currentInvaildPair?.exitTime) +
          " " +
          formatTime(currentInvaildPair?.exitTime),
        pairViolation: currentInvaildPair?.violationType,
      }),
    };

    axiosConfig
      .get(`GetUncleanSimilarpairs/`, {
        params: queryParams,
      })
      .then((res) => {
        const { statusCode, data, paginationData } = res.data;
        if (statusCode === 200) {
          setSimilarPairs(data);
          setPaginationData(paginationData);
        } else {
          setSimilarPairs([]);
          setPaginationData({});
          setCurrentPage(1);
        }
        setSimLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSimLoading(false);
        setSimilarPairs([]);
      });
  };

  function disableSingleView(plateNuber) {
    if (
      plateNuber === currentInvaildPair?.entryPlate ||
      currentInvaildPair?.exitPlate
    ) {
      setSingleViewDisabled(true);
    } else {
      setSingleViewDisabled(false);
    }
  }

  const submitPlate = (plateId, plateNumber) => {
    if (plateNumber.length > 0) {
      const queryParams = {
        CorrectdPlate: JSON.stringify({
          plateId: plateId,
          correctedplate: plateNumber,
          userid: userId,
          pairId: currentInvaildPair?.id,
          pairEntryPlate: currentInvaildPair?.entryPlate,
          pairExitPlate: currentInvaildPair?.exitPlate,
          pairsourceScreen:
            currentInvaildPair?.carWebStatus === "Valid"
              ? "CleanPair"
              : "UncleanPair",
          category: "AllPair/FuzzyPair",
          sourceScreen: "Invented Pair",
        }),
      };
      axiosConfig
        .get(`cleanPaircorrectPlate/`, {
          params: queryParams,
        })
        .then((res) => {
          const { statusCode } = res.data;
          if (statusCode === 200) {
            notification.success({
              message: "Successfully Updated",
              duration: 2,
            });
            disableSingleView(plateNumber);

            fetchSilmilarPairs();
          } else {
            notification.error({
              message: "Something went Wrong!",
              duration: 2,
            });
          }
        })
        .catch((err) => {
          notification.error({
            message: "Something went Wrong!",
            duration: 2,
          });
          console.log(err);
        });
    } else {
      notification.error({
        message: "Plate Number is not added",
        duration: 2,
      });
    }
  };

  const fetchFuzzyPairs = (enterId, exitId) => {
    const queryParams = {
      uncleanPairFuzzy: JSON.stringify({
        entryPlateid: currentInvaildPair?.entryPlateId,
        exitPlateid: currentInvaildPair?.exitPlateId,
        category: "Fuzzy",
        ArrivalTime: search.startDate,
        departureTime: search.endDate,
        plate: search.plate,
      }),
    };

    axiosConfig
      .get(`GetFuzzyPairsUncleanPairs/`, {
        params: queryParams,
      })
      .then((res) => {
        const { statusCode, data } = res.data;
        if (statusCode === 200) {
          setFuzzyPairs(data);
        } else {
          setFuzzyPairs([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setFuzzyPairs([]);
      });
  };
  useEffect(() => {
    if (activeMenu === "similar") {
      currentInvaildPair?.entryPlateId &&
        search.startDate !== null &&
        fetchSilmilarPairs();
    } else {
      currentInvaildPair?.entryPlateId && fetchFuzzyPairs();
    }
  }, [debouncingSearch, activeMenu, currentPage, pageSize]);

  console.log(
    currentInvaildPair,
    search,
    activeMenu,
    currentPage,
    pageSize,
    "currentInvaildPair, search, activeMenu, currentPage, pageSize"
  );

  const defaultDate = currentInvaildPair?.entryTime;
  const myDate = new Date(defaultDate);
  // const arrivalDate = formatAntdDatePickerDate(defaultDate)
  const formattedDate = myDate.toLocaleDateString().replace(/\//g, "-");

  const formattedTime = myDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  const myDate1 = new Date(currentInvaildPair?.exitTime);
  const formattedEndDate = myDate1.toLocaleDateString().replace(/\//g, "-");
  const formattedEndTime = myDate1.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  function convertToUpperCaseAndRemoveSpaces(inputString) {
    // Convert the string to uppercase
    let uppercasedString = inputString.toUpperCase();

    // Remove spaces from the string
    let stringWithoutSpaces = uppercasedString.replace(/\s/g, "");

    return stringWithoutSpaces;
  }

  return (
    <Container>
      {isModal && modalType === "correct_plate" && (
        <CorrectThePlate setModal={setModal} />
      )}
      {isModal && modalType === "double_entry" && (
        <PotentialDoubleEntryModal setModal={setModal} />
      )}
      {isModal && modalType === "incorrect_sequence" && (
        <PotentialIncorectSequenceModal setModal={setModal} />
      )}

      <TopSection>
        <SingleCarDetails
          handleSelectChange={handleSelectChange}
          data={voilationData[currentIndex]}
          setModal={setModal}
          setModalType={setModalType}
          setCurrentInvaildPair={setCurrentInvaildPair}
          fetchFuzzyPairs={fetchFuzzyPairs}
          setSimLoading={setSimLoading}
          isSingleViewDisabled={isSingleViewDisabled}
          setSingleViewDisabled={setSingleViewDisabled}
        />
      </TopSection>
      <ListTop>
        <LeftSection>
          <Tab>
            {currentInvaildPair?.violationType === "FUZZY" && (
              <Menu
                className={activeMenu === "fuzzy" && "active"}
                onClick={() => setActiveMenu("fuzzy")}
              >
                Fuzzy Pairs
              </Menu>
            )}
            <Menu
              onClick={() => setActiveMenu("similar")}
              className={activeMenu === "similar" && "active"}
            >
              All Records
            </Menu>
          </Tab>
        </LeftSection>
        {!isTopChanged && (
          <RightSection>
            {" "}
            <Reload onClick={fetchSilmilarPairs}>
              <ReloadOutlined style={{ color: "#fff" }} /> Reload
            </Reload>
            <Search>
              <Icon>
                <img src={search__icon} alt="Search" />
              </Icon>
              <SearchPlateNumber
                type="text"
                placeholder="Plate Number"
                value={search.plate}
                onChange={(e) =>
                  handleSearch(
                    "plate",
                    convertToUpperCaseAndRemoveSpaces(e.target.value)
                  )
                }
              />
            </Search>
            <Cover>
              <DatePicker
                format="DD-MM-YYYY"
                placeholder={formatDatev2(currentInvaildPair?.entryTime)}
                // defaultValue={defaultStartDate}
                onChange={(e) => handleSearch("startDate", e)}
              />
            </Cover>
            <TimePicker
              placeholder={formattedTime}
              // defaultValue={defaultStartTime}
              format={"HH:mm:ss"}
              onChange={(e) => handleSearch("startTime", formatTime(e))}
            />
            <Cover>
              <DatePicker
                format="DD-MM-YYYY"
                placeholder={formatDatev2(currentInvaildPair?.exitTime)}
                onChange={(e) => handleSearch("endDate", e)}
              />
            </Cover>
            <Cover>
              <TimePicker
                format={"HH:mm:ss"}
                placeholder={formattedEndTime}
                onChange={(e) => handleSearch("endTime", formatTime(e))}
              />
            </Cover>
          </RightSection>
        )}
      </ListTop>
      {activeMenu === "similar" ? (
        <CarsList className="silmilarPlates">
          {Object.keys(currentInvaildPair).length === 0 ? (
            <NoData />
          ) : isSilmLoading ? (
            <Loader>
              <DataFetchIngLoader />
            </Loader>
          ) : similarPairs.length > 0 ? (
            similarPairs.map((data) => (
              <SimilarPlatesCard
                data={data}
                fetchSilmilarPairs={data}
                submitPlate={submitPlate}
                currentInvaildPair={currentInvaildPair}
              />
            ))
          ) : (
            <NoData />
          )}
        </CarsList>
      ) : (
        <CarsList>
          {isLoading ? (
            <Loader>
              <DataFetchIngLoader />
            </Loader>
          ) : fuzzyPairs.length > 0 ? (
            fuzzyPairs.map((data) => (
              <FuzzyPlatesCard
                fuzzyPairs={data}
                fetchFuzzyPairs={fetchFuzzyPairs}
              />
            ))
          ) : (
            <NoData />
          )}
        </CarsList>
      )}
      {activeMenu === "similar" &&
        Object.keys(currentInvaildPair).length !== 0 && (
          <BottomSection>
            <div>
              <span>Rows per page: </span>
              <Select
                defaultValue={pageSize}
                onChange={handlePageSizeChange}
                style={{ width: 80 }}
              >
                <Select.Option value={10}>10</Select.Option>
                <Select.Option value={20}>20</Select.Option>
                <Select.Option value={50}>50</Select.Option>
              </Select>
            </div>
            <Pagination
              total={paginationData?.count} // Total number of items in the data source
              currentPage={currentPage}
              pageSize={pageSize}
              onChange={handlePageChange}
              current={currentPage}
            />
          </BottomSection>
        )}
    </Container>
  );
}

export default InventedPairs;
const Container = styled.div`
  padding: 20px;
  .ant-pagination-options {
    display: none;
  }
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CarsList = styled.div`
  margin-top: 10px;
  max-height: calc(100vh - 510px);
  min-height: calc(100vh - 510px);
  overflow-y: scroll;
  padding: 20px;
  border: 1px solid #d0d1d2;
  border-radius: 5px;
  &.silmilarPlates {
    max-height: calc(100vh - 560px);
    min-height: calc(100vh - 560px);
  }
  &.silmilarPlates {
    max-height: calc(100vh - 560px);
    min-height: calc(100vh - 560px);
  }
  &::-webkit-scrollbar {
    width: 12px; /* Set the width of the scrollbar */
    display: block !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d8d8d8; /* Set the color of the scrollbar thumb */
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Set the color of the scrollbar track */
  }
`;
const BottomSection = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :where(.css-dev-only-do-not-override-1vr7spz).ant-select-single:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    max-width: 100px;
  }
`;
const ListTop = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const RightSection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  :where(.css-dev-only-do-not-override-3mqfnx).ant-picker {
    padding: 0 11px;
  }
`;
const Cover = styled.div`
  border-radius: 5px;
  .ant-picker {
  }
  /* border: 1px solid #d0d1d2; */
`;
const Search = styled.div`
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  width: 150px;
  padding: 0 10px;
  input {
    width: 100%;
    padding: 3px 10px;
  }
`;
const Icon = styled.span`
  display: block;
  width: 20px;
  img {
    display: block;
    width: 100%;
  }
`;
const LeftSection = styled.div``;
const Tab = styled.div`
  width: 300px;
  height: 30px;
  background: #dce6f6;
  border: 2px solid #dce6f6;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 2px;
`;
const Menu = styled.span`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  font-size: 13px;
  cursor: pointer;
  color: #919599;
  font-weight: 500;
  &.active {
    background-color: #fff;
    height: 100%;
    border-radius: 15px;
    color: #3d619b;
  }
`;
const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: calc(100vh - 580px);
`;
const Reload = styled.div`
  display: block;
  padding: 2px 15px;
  background-color: #3d619b;
  cursor: pointer;
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
`;
const SearchPlateNumber = styled.input`
  font-family: "Roboto Mono";
  &::placeholder {
    font-family: "Inter", sans-serif;
  }
`;
