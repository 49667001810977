import React, { useState } from "react";
import { styled } from "styled-components";
import { Table } from "antd";

function History({ fuzzyPairs, paginationDataHistory, setrowsPerPageHistory }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setrowsPerPageHistory(size);
    setCurrentPage(1);
  };
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  function formatDate(inputDate) {
    const dateObject = new Date(inputDate);

    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
    const year = dateObject.getFullYear();

    // Pad day and month with leading zeros if necessary
    const formattedDay = String(day).padStart(2, "0");
    const formattedMonth = String(month).padStart(2, "0");
    if (formattedDay && formattedMonth && year) {
      return `${year}-${formattedMonth}-${formattedDay}`;
    } else {
      return null;
    }
  }

  function formatTime(inputDateTime) {
    const date = new Date(inputDateTime);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    if (inputDateTime) {
      return `${hours}:${minutes}:${seconds}`;
    } else {
      return null;
    }
  }
  function capitalizeFirstLetter(string) {
    console.log("History page string", string);
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }
  const columns = [
    {
      title: "Site",
      dataIndex: "siteCode",
      key: "siteCode",
      width: 200,
    },
    {
      title: "Plate Number",
      dataIndex: "plate",
      key: "plate",
      width: 200,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => formatDate(text) + " " + formatTime(text),
      width: 200,
    },
    {
      title: "Motion",
      dataIndex: "motion",
      key: "motion",
      render: (text) => (
        <p style={{ color: "black" }}>{capitalizeFirstLetter(text)}</p>
      ),
      //   render: (text) => formatDate(text) + " " + formatTime(text),
      width: 200,
    },
    {
      title: "Picture",
      dataIndex: "fullImage",
      key: "fullImage",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          <p style={{ color: "black" }}>{"Click here to Show the picture"}</p>
        </a>
      ),
    },
  ];
  console.log(fuzzyPairs, "fuzzy");
  return (
    <Container>
      <Table
        dataSource={fuzzyPairs}
        columns={columns}
        pagination={false}
        style={{ height: "50px" }}
        scroll={{ y: "calc(100vh - 200px)" }}
      />
    </Container>
  );
}

export default History;
const Container = styled.div`
  .ant-table {
    overflow: scroll;
    border-radius: 20px !important;
  }
  :where(.css-dev-only-do-not-override-3mqfnx).ant-table-wrapper
    .ant-table-thead
    > tr
    > th {
    background: #fafafa;
    color: black;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  //   padding: 20px;

  border-bottom: 1px solid #d9d9d9;
  padding: 10px 0;
`;
