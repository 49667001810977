import React, { useEffect, useState } from "react";
import { styled } from "styled-components";

import arrow from "../../../assets/images/clean_pairs/right_arrow.svg";
import left_arrow from "../../../assets/images/left-arrow.svg";
import right_arrow from "../../../assets/images/right-arrow.svg";
import delete_icon from "../../../assets/images/delete 2.svg";
import save from "../../../assets/images/save.svg";

import { Select, Spin, Tooltip, notification } from "antd";
import { COLORS } from "../../../Constants";
import axiosConfig from "../../../axiosConfig";
import { formatDatev2, formatTime } from "../../../utils/HelperFunctions";
import DataFetchIngLoader from "../../general/DataFetchIngLoader";
import NoData from "../../general/NoData";
import ConfirmationModal from "../modals/ConfirmationModal";
import { updateDataProcessed } from "../../redux/reducers/UserReducer";
import { useDispatch, useSelector } from "react-redux";
import Placeholder_image from "../../../assets/images/image_placeholder.gif";
import { bgColorSites } from "../../general/SiteCodesForBgColor";

function SingleCarDetails({
  handleSelectChange,
  data,
  setModal,
  setModalType,
  setCurrentInvaildPair,
  fetchFuzzyPairs,
  setSimLoading,
  isSingleViewDisabled,
  setSingleViewDisabled,
}) {
  const dropdownStyle = {
    overflow: "auto",
    textOverflow: "ellipsis",
  };
  const [isLoading, setLoading] = useState(false);
  const [unCleanData, setUncleanData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const [EntryPlate, setEntryPlate] = useState("");
  const [ExitPlate, setExitPlate] = useState("");
  const [rejectReason, setRejectReason] = useState(null);
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [pageSize, setPageSize] = useState(50); // I
  const [entryImageLoading, setEntryImageLoading] = useState(true);
  const [exitImageLoading, setExitImageLoading] = useState(true);
  const [noMoreData, setNoMoreData] = useState(false);
  const [noMorePreviousData, setNoMorePreviousData] = useState(false);

  const [search, setSearch] = useState({
    siteCode: null,
    siteName: null,
    startDate: null,
    endDate: null,
    plate: null,
  });
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state?.user?.userProfile);

  //function to convert uppercase and remove space
  function convertToUpperCaseAndRemoveSpaces(inputString) {
    // Convert the string to uppercase
    let uppercasedString = inputString.toUpperCase();

    // Remove spaces from the string
    let stringWithoutSpaces = uppercasedString.replace(/\s/g, "");

    return stringWithoutSpaces;
  }

  useEffect(() => {
    setEntryPlate(unCleanData?.entryPlate);
    setExitPlate(unCleanData?.exitPlate);
  }, [unCleanData]);

  // const handleKeyDown = (event) => {
  // 	switch (event.key) {
  // 		case "ArrowRight":
  // 			if (paginationData?.ifNext) {
  // 				setCurrentPage((prev) => prev + 1);
  // 			}
  // 			break;
  // 		case "ArrowLeft":
  // 			// Decrement currentIndex, but ensure it doesn't go below 0
  // 			setCurrentPage((prev) => Math.max(prev - 1, 0));
  // 			break;
  // 		default:
  // 	}
  // };

  // useEffect(() => {
  // Add the event listener when the component mounts
  // document.addEventListener("keydown", handleKeyDown);

  // Remove the event listener when the component unmounts
  // return () => {
  // document.removeEventListener("keydown", handleKeyDown);
  // };
  // }, []);
  const fetchProcessedData = () => {
    const queryParams = {
      ProcessCountInput: JSON.stringify({
        userId: userId,
        sourceScreen: "Clean Pair",
      }),
    };
    axiosConfig
      .get(`GetProcessingProcessedCount/`, {
        params: queryParams,
      })
      .then((res) => {
        const { statusCode, processedCount, processingCount } = res.data;
        if (statusCode === 200) {
          dispatch(
            updateDataProcessed({
              processed: processedCount,
              processing: processingCount,
            })
          );
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    userId && fetchProcessedData();
  }, [userId, currentPage]);

  const reAllocatePairs = () => {
    setLoading(true);
    axiosConfig
      .get(`ReallocateCleanPairsPotentialPairsToAnalysts/`)
      .then((res) => {
        const { statusCode, data } = res.data;
        if (statusCode === 200) {
          fetchUncleanData(true);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchUncleanData = (isPairAllocated) => {
    setNoMorePreviousData(false);
    setSimLoading(true);
    setEntryImageLoading(true);
    setExitImageLoading(true);
    setEntryPlate("");
    setExitPlate("");
    setLoading(true);
    const queryParams = {
      CleanPairInput: JSON.stringify({
        site: search.siteCode,
        startDate: search.startDate,
        endDate: search.endDate,
        plate: search?.plate?.length > 0 ? search.plate : null,
        pageSize: 1,
        pageNumber: currentPage,
        userId: userId,
      }),
    };
    axiosConfig
      .get(`GetCleanPairsforUI/`, {
        params: queryParams,
      })
      .then((res) => {
        const { statusCode, data, paginationData } = res.data;
        if (statusCode === 200) {
          setUncleanData(data ? data[0] : {});
          setCurrentInvaildPair(data ? data[0] : {});
          setRejectReason(null);
          // fetchFuzzyPairs(data[0].entryPlateId, data[0].exitPlateId);
          setPaginationData(paginationData);
          setSingleViewDisabled(false);
          setLoading(false);

          if (data === null) {
            isPairAllocated ? setNoMoreData(true) : reAllocatePairs();
          } else {
            setNoMoreData(false);
          }
        } else {
          setRejectReason(null);
          setLoading(false);
          setUncleanData([]);
          setCurrentInvaildPair([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setUncleanData({});
        setRejectReason(null);
      });
  };
  useEffect(() => {
    setLoading(true);
    userId && fetchUncleanData();
  }, [currentPage, userId]);

  let [isUpdated, setUpdated] = useState(false);

  useEffect(() => {
    if (isUpdated) {
      fetchUncleanData();
      fetchProcessedData();
    }
  }, [isUpdated]);

  const siteCodes = bgColorSites.map((item) => item.siteCode);
  const compareSiteCodes = siteCodes.includes(unCleanData?.siteCode);
  const orangeToBlue =
    compareSiteCodes === true && unCleanData?.isStandard === "No";
  const toBlue = compareSiteCodes === false && unCleanData?.isStandard === "No";

  const handleBgColorChange = () => {
    if (orangeToBlue) {
      return "#1a73e840";
    } else if (toBlue) {
      return "rgba(251, 189, 85, 0.25)";
    } else if (compareSiteCodes) {
      return "#1a73e840";
    } else {
      return "#ffffff";
    }
  };

  const submitPlate = (plateId, motion, plateNumber) => {
    setLoading(true);
    const queryParams = {
      correctdPlate: JSON.stringify({
        id: unCleanData?.id,
        correctedPlate: plateNumber,
        plateId: plateId,
        carEntryExit: motion,
        category: "CleanPair",
        sourceScreen: "Clean Pair",
        userid: userId,
      }),
    };
    axiosConfig
      .get(`cleanPaircorrectPlate/`, {
        params: queryParams,
      })
      .then((res) => {
        const { statusCode } = res.data;
        if (statusCode === 200) {
          notification.success({
            duration: 2,
            message:
              "Plate number successfully updated and all the records related to this plate are moved back to unprocessed state. We will present you with a new pair now.",
          });
          setEntryPlate("");
          setExitPlate("");
          setUpdated(true);
          setTimeout(() => {
            setUpdated(false);
          }, 5000);
        } else {
          notification.error({
            duration: 2,
            message: "Something went Wrong!",
          });
        }
      })
      .catch((err) => {
        notification.error({
          duration: 2,
          message: "Something went Wrong!",
        });
        console.log(err);
      });
  };

  const submitGoodToPrint = (id, category) => {
    const queryParams = {
      notGoodtoPrint: JSON.stringify({
        id: id,
        category: category,
      }),
    };
    axiosConfig
      .get(`UpdategoodToPrintforUI/?NotGoodtoPrintID=${id}`)
      .then((res) => {
        const { statusCode } = res.data;
        const printStatus =
          unCleanData?.goodtoPrint === "True" ? "False" : "True";
        if (statusCode === 200) {
          setUncleanData({
            ...unCleanData,
            goodtoPrint: printStatus,
          });
          fetchProcessedData();
          notification.success({
            duration: 2,
            message: "Successfully updated",
          });
        } else {
          notification.error({
            duration: 2,
            message: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        console.log(err);

        notification.error({
          duration: 2,
          message: "Something went wrong",
        });
      });
  };

  const acceptPairs = () => {
    setLoading(true);
    const queryParams = {
      AcceptAllCleanpairsforUI: JSON.stringify({
        id: unCleanData?.id,
        userid: userId,
        siteType: unCleanData.siteType,
        siteGroupNumber: unCleanData.siteGroupNumber,
      }),
    };
    axiosConfig
      .get(`AcceptAllCleanpairsforUI`, {
        params: queryParams,
      })
      .then((res) => {
        const { statusCode } = res.data;
        setLoading(false);
        if (statusCode === 200) {
          fetchProcessedData();
          notification.success({
            duration: 2,
            message: "Successfully updated",
          });
          fetchUncleanData();
        } else {
          notification.error({
            duration: 2,
            message: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        notification.error({
          duration: 2,
          message: "Something went wrong",
        });
      });
  };

  const rejectPairs = () => {
    setLoading(true);
    const queryParams = {
      rejectedInput: JSON.stringify({
        id: unCleanData?.id,
        category: unCleanData?.category,
        rejectionReason: rejectReason,
        userId: userId,
      }),
    };
    axiosConfig
      .get(`updateRejectedforUI/`, {
        params: queryParams,
      })
      .then((res) => {
        const { statusCode } = res.data;

        if (statusCode === 200) {
          fetchProcessedData();
          notification.success({
            duration: 2,
            message: "Successfully updated",
          });
          setRejectReason("");
          fetchUncleanData();
        } else {
          notification.error({
            duration: 2,
            message: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        notification.error({
          duration: 2,
          message: "Something went wrong",
        });
      });
  };
  const submitDoublePlate = (plateId, motion, plateNumber) => {
    setLoading(true);
    const queryParams = {
      correctdPlate: JSON.stringify({
        id: unCleanData?.id,
        correctedPlate: plateNumber,
        plateId: plateId,
        carEntryExit: motion,
        category: "CleanPair",
        sourceScreen: "Clean Pair",
        userid: userId,
      }),
    };
    axiosConfig
      .get(`cleanPaircorrectPlate/`, {
        params: queryParams,
      })
      .then((res) => {
        const { statusCode } = res.data;
        if (statusCode === 200) {
          submitPlate(
            unCleanData?.exitPlateId,
            "Exit",
            convertToUpperCaseAndRemoveSpaces(ExitPlate)
          );
        } else {
          notification.error({
            duration: 2,
            message: "Something went Wrong!",
          });
        }
      })
      .catch((err) => {
        notification.error({
          duration: 2,
          message: "Something went Wrong!",
        });
        console.log(err);
      });
  };

  const handleSubmitPlate = () => {
    if (
      EntryPlate !== unCleanData?.entryPlate &&
      ExitPlate !== unCleanData?.exitPlate
    ) {
      submitDoublePlate(
        unCleanData?.entryPlateId,
        "Entry",
        convertToUpperCaseAndRemoveSpaces(EntryPlate)
      );
    } else {
      if (EntryPlate !== unCleanData?.entryPlate) {
        submitPlate(
          unCleanData?.entryPlateId,
          "Entry",
          convertToUpperCaseAndRemoveSpaces(EntryPlate)
        );
      }

      if (ExitPlate !== unCleanData?.exitPlate) {
        submitPlate(
          unCleanData?.exitPlateId,
          "Exit",
          convertToUpperCaseAndRemoveSpaces(ExitPlate)
        );
      }
    }
  };

  const handlePlateCancel = () => {
    setEntryPlate(unCleanData?.entryPlate);
    setExitPlate(unCleanData?.exitPlate);
  };

  const checkIsPlateChangeed = () => {
    if (
      unCleanData?.entryPlate !== EntryPlate ||
      unCleanData?.exitPlate !== ExitPlate
    ) {
      return true;
    } else {
      return false;
    }
  };

  const fetchPreviousPair = () => {
    setNoMoreData(false);
    setEntryImageLoading(true);
    setExitImageLoading(true);
    setEntryPlate("");
    setExitPlate("");
    setLoading(true);
    setSimLoading(true);
    setSingleViewDisabled(false);
    setNoMorePreviousData(false);
    const queryParams = {
      CleanPairInput: JSON.stringify({
        userId: userId,
        category: "CleanPair",
        stage2id: unCleanData?.id || null,
      }),
    };
    axiosConfig
      .get(`GetPreviousRecordCleanPair/`, {
        params: queryParams,
      })
      .then((res) => {
        const { statusCode, data, paginationData } = res.data;
        if (statusCode === 200) {
          setUncleanData(data ? data[0] : {});
          setCurrentInvaildPair(data ? data[0] : {});
          setRejectReason(null);

          setPaginationData(paginationData);
          setLoading(false);
          if (data === null) {
            setNoMorePreviousData(true);
          }
        } else {
          setRejectReason(null);
          setLoading(false);
          setUncleanData([]);
          setCurrentInvaildPair([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setUncleanData({});
        setRejectReason(null);
      });
  };
  return (
    <Container>
      {isConfirmModal && (
        <ConfirmationModal
          handleSubmitPlate={handleSubmitPlate}
          setModal={setConfirmModal}
        />
      )}
      <TopSection>
        <NavigationButton
          className={!isLoading && unCleanData && "active"}
          onClick={() => !isLoading && unCleanData && fetchPreviousPair()}
        >
          <img src={left_arrow} alt="" />
          Prev
        </NavigationButton>
        <Title>Clean Pairs</Title>
        <NavigationButton
          disabled
          className={!isLoading && "active"}
          onClick={() => !isLoading && setCurrentPage(currentPage + 1)}
        >
          Next
          <img src={right_arrow} alt="" />
        </NavigationButton>
      </TopSection>
      {isLoading ? (
        <Loader>
          <DataFetchIngLoader />
        </Loader>
      ) : noMoreData ? (
        <Loader>
          <NoData
            message={"There are no more Clean Pairs available for processing."}
          />
        </Loader>
      ) : noMorePreviousData ? (
        <Loader>
          <NoData
            message={"There are no more Prevoius Clean Pairs available"}
          />
        </Loader>
      ) : unCleanData?.id ? (
        <Cover colorChange={handleBgColorChange()}>
          <DetailsContainer>
            <SectionCover>
              <Entry>
                <CarCover>
                  <Tooltip
                    overlayInnerStyle={{ width: "820px" }}
                    title={
                      <img
                        src={unCleanData?.entryfullImage}
                        alt=""
                        style={{
                          maxWidth: "800px",
                          width: "800px",
                        }}
                      />
                    }
                    color={"black"}
                  >
                    <CarImage
                      href={unCleanData?.entryfullImage}
                      target="_blank"
                    >
                      <img
                        src={
                          entryImageLoading
                            ? Placeholder_image
                            : unCleanData?.entryfullImage
                        }
                        alt="Entry Img"
                        onLoad={() => setEntryImageLoading(false)}
                      />
                    </CarImage>
                  </Tooltip>{" "}
                  <Tooltip
                    overlayInnerStyle={{ width: "420px" }}
                    title={
                      <img
                        src={unCleanData?.entryCroppedimage}
                        alt=""
                        style={{
                          maxWidth: "400px",
                          width: 400,
                        }}
                      />
                    }
                    color={"black"}
                  >
                    <NumberPlate
                      style={{ marginTop: 10 }}
                      href={unCleanData?.entryCroppedimage}
                      target="_blank"
                    >
                      <img src={unCleanData?.entryCroppedimage} alt="" />
                    </NumberPlate>
                  </Tooltip>
                </CarCover>
                <EntryDetails>
                  <Feilds>
                    <FeildName>Plate No:</FeildName>
                    <FeildData
                      style={{
                        fontFamily: "Roboto Mono",
                        fontWeight: 600,
                        fontSize: "18px",
                      }}
                    >
                      {unCleanData?.entryPlate}
                    </FeildData>
                  </Feilds>{" "}
                  {/* <Feilds>
                                        <FeildName>AI Correction:</FeildName>
                                        <FeildData>
                                            {unCleanData.entryAICorrection ===
                                            "true"
                                                ? "Yes"
                                                : "No"}
                                        </FeildData>
                                    </Feilds>
                                    <Feilds>
                                        <FeildName>Before AI:</FeildName>
                                        <FeildData
                                            style={{
                                                fontFamily: "Roboto Mono",
                                                fontWeight: 600,
                                                fontSize: "16px",
                                            }}
                                        >
                                            {unCleanData?.entryPlateBeforeAI}
                                        </FeildData>
                                    </Feilds> */}
                  <Feilds>
                    <FeildName>Entry Date:</FeildName>
                    <FeildData
                      style={{
                        // fontFamily: "Roboto Mono",
                        fontWeight: 600,
                        fontSize: "16px",
                      }}
                    >
                      {formatDatev2(unCleanData?.arrivalTime)}
                    </FeildData>
                  </Feilds>
                  <Feilds>
                    <FeildName>Entry Time:</FeildName>
                    <FeildData
                      style={{
                        // fontFamily: "Roboto Mono",
                        fontWeight: 600,
                        fontSize: "16px",
                      }}
                    >
                      {formatTime(unCleanData?.arrivalTime)}
                    </FeildData>
                  </Feilds>
                  <Feilds>
                    <FeildName>Motion:</FeildName>
                    <FeildData>{unCleanData?.entryMotion}</FeildData>
                  </Feilds>
                  <Feilds>
                    <FeildName>Direction:</FeildName>
                    <FeildData>{unCleanData?.entryDirection}</FeildData>
                  </Feilds>
                </EntryDetails>
              </Entry>
              <CorrectThePlateSection>
                Correct the plate{" "}
                <input
                  type="text"
                  tabIndex="1"
                  value={EntryPlate}
                  style={{
                    fontFamily: "Roboto Mono",
                    fontWeight: 500,
                  }}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const filteredValue = inputValue.replace(
                      /[^a-zA-Z0-9]/g,
                      ""
                    );
                    setEntryPlate(filteredValue);
                  }}
                />
              </CorrectThePlateSection>
            </SectionCover>
            <SectionCover>
              <Exit>
                <CarCover>
                  <Tooltip
                    overlayInnerStyle={{ width: "820px" }}
                    title={
                      <img
                        src={unCleanData?.exitfullImage}
                        alt=""
                        style={{
                          maxWidth: "800px",
                          width: "800px",
                        }}
                      />
                    }
                    color={"black"}
                  >
                    <CarImage href={unCleanData?.exitfullImage} target="_blank">
                      <img
                        src={
                          exitImageLoading
                            ? Placeholder_image
                            : unCleanData?.exitfullImage
                        }
                        alt="Entry Img"
                        onLoad={() => setExitImageLoading(false)}
                      />
                    </CarImage>
                  </Tooltip>
                  <Tooltip
                    overlayInnerStyle={{ width: "420px" }}
                    title={
                      <img
                        src={unCleanData?.exitCroppedImage}
                        alt=""
                        style={{
                          maxWidth: "400px",
                          width: "400px",
                        }}
                      />
                    }
                    color={"black"}
                  >
                    <NumberPlate
                      style={{ marginTop: 10 }}
                      href={unCleanData?.exitCroppedImage}
                      target="_blank"
                    >
                      <img src={unCleanData?.exitCroppedImage} alt="" />
                    </NumberPlate>
                  </Tooltip>
                </CarCover>
                <EntryDetails>
                  <Feilds>
                    <FeildName>Plate No:</FeildName>
                    <FeildData
                      style={{
                        fontFamily: "Roboto Mono",
                        fontWeight: 600,
                        fontSize: "18px",
                      }}
                    >
                      {unCleanData?.exitPlate}
                    </FeildData>
                  </Feilds>
                  {/* <Feilds>
                                        <FeildName>AI Correction:</FeildName>
                                        <FeildData>
                                            {unCleanData?.exitAICorrection ===
                                            "true"
                                                ? "Yes"
                                                : "No"}
                                        </FeildData>
                                    </Feilds>
                                    <Feilds>
                                        <FeildName>Before AI:</FeildName>
                                        <FeildData
                                            style={{
                                                fontFamily: "Roboto Mono",
                                                fontWeight: 600,
                                                fontSize: "16px",
                                            }}
                                        >
                                            {unCleanData?.exitPlateBeforeAI}
                                        </FeildData>
                                    </Feilds> */}
                  <Feilds>
                    <FeildName>Exit Date:</FeildName>
                    <FeildData
                      style={{
                        // fontFamily: "Roboto Mono",
                        fontWeight: 600,
                        fontSize: "16px",
                      }}
                    >
                      {formatDatev2(unCleanData?.departureTime)}
                    </FeildData>
                  </Feilds>
                  <Feilds>
                    <FeildName>Exit Time:</FeildName>
                    <FeildData
                      style={{
                        // fontFamily: "Roboto Mono",
                        fontWeight: 600,
                        fontSize: "16px",
                      }}
                    >
                      {formatTime(unCleanData?.departureTime)}
                    </FeildData>
                  </Feilds>
                  <Feilds>
                    <FeildName>Motion:</FeildName>
                    <FeildData>{unCleanData?.exitMotion}</FeildData>
                  </Feilds>
                  <Feilds>
                    <FeildName>Direction:</FeildName>
                    <FeildData>{unCleanData?.exitDirection}</FeildData>
                  </Feilds>{" "}
                </EntryDetails>
              </Exit>
              <CorrectThePlateSection style={{ position: "relative" }}>
                Correct the plate{" "}
                <input
                  type="text"
                  tabIndex="1"
                  value={ExitPlate}
                  style={{
                    fontFamily: "Roboto Mono",
                    fontWeight: 500,
                  }}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const filteredValue = inputValue.replace(
                      /[^a-zA-Z0-9]/g,
                      ""
                    );
                    setExitPlate(filteredValue);
                  }}
                />
                <ButtonSection>
                  <SaveButton
                    onClick={() => {
                      checkIsPlateChangeed() && setConfirmModal(true);
                      // : notification.error({
                      //      message:
                      //          "There is no changes in the plates",
                      //   });
                    }}
                    className={
                      EntryPlate === unCleanData?.entryPlate &&
                      ExitPlate === unCleanData?.entryPlate &&
                      "inactive"
                    }
                  >
                    Update Plate
                  </SaveButton>
                  <PlateRejectButton onClick={handlePlateCancel}>
                    Cancel
                  </PlateRejectButton>
                </ButtonSection>
              </CorrectThePlateSection>
            </SectionCover>
            <SectionCover style={{ maxWidth: 300 }} className="lower_section">
              <VehicleDetails>
                <SectionTitle>Vehicle Details</SectionTitle>
                <Feilds>
                  <FeildName className="veh_details">Make:</FeildName>
                  <FeildData>{unCleanData?.make}</FeildData>
                </Feilds>
                <Feilds>
                  <FeildName className="veh_details">Model:</FeildName>
                  <Tooltip title={unCleanData?.model}>
                    <FeildData className="veh_details">
                      {unCleanData?.model}
                    </FeildData>
                  </Tooltip>
                </Feilds>
                <Feilds>
                  <FeildName className="veh_details">Colour:</FeildName>
                  <FeildData>{unCleanData?.color}</FeildData>
                </Feilds>
                <Feilds>
                  <FeildName className="veh_details">Carweb:</FeildName>
                  <FeildData
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                    className={
                      unCleanData?.carWebStatus === "Valid"
                        ? "valid"
                        : "invalid"
                    }
                  >
                    {unCleanData?.carWebStatus}
                  </FeildData>
                </Feilds>
              </VehicleDetails>
            </SectionCover>
            <SectionCover style={{ maxWidth: 300 }} className="lower_section">
              <ViolationDetails>
                <SectionTitle>Violation Details</SectionTitle>
                <Feilds>
                  <FeildName className="voi_details">Site code:</FeildName>
                  <FeildData>{unCleanData?.siteCode}</FeildData>
                </Feilds>
                <Feilds>
                  <FeildName className="voi_details">
                    Site Violation Time:
                  </FeildName>
                  <FeildData>{unCleanData?.violationTime} mins</FeildData>
                </Feilds>
                <Feilds>
                  <FeildName className="voi_details">Stay Duration:</FeildName>
                  <FeildData>{unCleanData?.duration} mins</FeildData>
                </Feilds>
                <Feilds>
                  <FeildName className="voi_details">Violation Type:</FeildName>
                  <FeildData style={{ fontSize: "16px" }}>
                    {unCleanData?.violationType}
                  </FeildData>
                </Feilds>
                <Feilds>
                  <FeildName className="voi_details">
                    Match Percentage:
                  </FeildName>
                  <FeildData style={{ fontSize: "16px" }}>
                    {unCleanData?.fuzzyMatchPercentage
                      ? unCleanData?.fuzzyMatchPercentage.toFixed(2) + "%"
                      : "-"}
                  </FeildData>
                </Feilds>
              </ViolationDetails>
            </SectionCover>
          </DetailsContainer>
          <BottomSection>
            <RejectSection>
              <Tooltip>
                <ReasonSelect
                  style={{
                    width: "100%",
                    filter: isSingleViewDisabled && "greyScale(1)",
                  }}
                  placeholder="Select a reason"
                  disabled={isSingleViewDisabled && true}
                  defaultValue={""}
                  onChange={(e) => setRejectReason(e.target.value)}
                  dropdownStyle={dropdownStyle}
                >
                  <option disabled value="">
                    Select a reason
                  </option>
                  <option value="Different Vehicles">
                    {" "}
                    Different Vehicles{" "}
                  </option>
                  <option value="Double Entry"> Double Entry </option>
                  <option value="Emergency Services">
                    {" "}
                    Emergency Services{" "}
                  </option>
                  <option value="Foreign Plate - NonViolator">
                    {" "}
                    Foreign Plate - NonViolator{" "}
                  </option>
                  <option value="Foreign Plate - Violator">
                    {" "}
                    Foreign Plate - Violator{" "}
                  </option>
                  <option value="Misread"> Misread </option>
                  <option value="Quality Check Failure">
                    {" "}
                    Quality Check Failure{" "}
                  </option>
                  <option value="Same Directions"> Same Directions </option>
                  <option value="Double Entry by History">
                    Double Entry by History
                  </option>
                  <option value="Motorbike">Motorbike</option>
                </ReasonSelect>
              </Tooltip>
              <RejectButton
                className={rejectReason && !isSingleViewDisabled && "active"}
                onClick={
                  rejectReason && !isSingleViewDisabled ? rejectPairs : null
                }
              >
                Reject
              </RejectButton>
            </RejectSection>

            <ButtonCover>
              <GoodToPrint>
                <FeildName>
                  <FeildName>Good to print:</FeildName>
                </FeildName>
                {/* {isSingleViewDisabled ? (
									<DisabledRadio
										className={
											unCleanData?.goodtoPrint ===
												"True" && "active"
										}
									>
										{" "}
										<span></span>
									</DisabledRadio>
								) : ( */}
                <Radio
                  className={unCleanData?.goodtoPrint === "True" && "active"}
                  onClick={
                    () =>
                      // !isSingleViewDisabled
                      // ?
                      submitGoodToPrint(
                        JSON.stringify(unCleanData?.id),
                        unCleanData?.category
                      )
                    // : null
                  }
                >
                  <span></span>
                </Radio>
                {/* )} */}
              </GoodToPrint>
              <AcceptButton
                onClick={
                  !checkIsPlateChangeed() && !isSingleViewDisabled
                    ? acceptPairs
                    : null
                }
                className={
                  !checkIsPlateChangeed() && !isSingleViewDisabled && "active"
                }
              >
                Accept
              </AcceptButton>
            </ButtonCover>
          </BottomSection>
        </Cover>
      ) : (
        <Loader>
          <NoData />
        </Loader>
      )}
    </Container>
  );
}

export default SingleCarDetails;
const Container = styled.div`
  width: 100%;
  .ant-pagination-options {
    display: none;
  }
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const DetailsContainer = styled.div`
  display: flex;

  width: 100%;
`;
const Entry = styled.div`
  display: flex;
  gap: 10px;
  padding: 0 10px;
  position: relative;
  border-right: 1px solid #d0d1d2;
  flex: 1;
  @media all and (min-width: 1550px) {
    gap: 20px;
  }
  /* @media all and (max-width: 1550px) {
        max-width: 400px;
    } */
`;
const Exit = styled.div`
  display: flex;
  gap: 10px;
  border-right: 1px solid #d0d1d2;
  padding: 0 10px;
  flex: 1;
  @media all and (min-width: 1550px) {
    gap: 20px;
  }
  /* @media all and (max-width: 1550px) {
        max-width: 400px;
    } */
`;
const VehicleDetails = styled.div`
  border-right: 1px solid #d0d1d2;
  padding: 0 10px;
  min-height: 175px;
`;
const ViolationDetails = styled.div`
  /* border-right: 1px solid #d0d1d2; */
  padding: 0 10px;
  min-height: 175px;
`;
const ActionSection = styled.div`
  padding: 0 10px;
  flex: 1;
  padding-right: 5px;
  max-width: 180px;
`;
const CarImage = styled.a`
  display: block;
  /* width: 150px; */
  flex: 1;
  min-width: 150px;
  height: 120px;
  border-radius: 8px;
  overflow: hidden;
  @media all and (max-width: 1550px) {
    max-width: 150px;
  }
  img {
    display: block;
    /* object-fit: cover; */
    width: 100%;
    height: 120px;
    object-fit: cover;
  }
`;
const EntryDetails = styled.div`
  min-height: 175px;
`;
const NumberPlate = styled.a`
  height: 40px;
  width: 150px;
  display: block;
  img {
    display: block;
    height: 100%;
    width: 100%;
  }
  margin-bottom: 5px;
`;
const Feilds = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;
const FeildName = styled.p`
  font-size: 13px;
  color: #6a6a6a;
  width: 90px;
  &.veh_details {
    width: 60px !important;
  }
  &.voi_details {
    width: 130px;
  }
`;
const FeildData = styled.p`
  font-size: 12px;
  color: #6a6a6a;
  white-space: nowrap;
  color: #000;
  font-weight: 500;
  text-transform: capitalize;

  &.veh_details {
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  @media all and (min-width: 1500px) {
    &.veh_details {
      max-width: 300px;
    }
  }
  &.valid {
    color: green;
  }
  &.invalid {
    color: red;
  }
`;

const SectionTitle = styled.p`
  font-size: 14px;
  color: #3d619b;
  margin-bottom: 15px;
`;

const NavigationButton = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  border: 1px solid #828282;
  width: 80px;
  height: 30px;
  justify-content: center;
  cursor: not-allowed;
  color: #828282;
  opacity: 0.6;
  &.active {
    cursor: pointer;
    opacity: 1;
  }
  &:hover {
    opacity: 0.5;
  }
`;
const Title = styled.h2`
  color: ${COLORS.THEME_COLOR};
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;

const Cover = styled.div`
  border: 1px solid #d0d1d2;
  border-radius: 5px;
  padding: 10px;
  background: ${(props) => props.colorChange};
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
const RejectSection = styled.div`
  display: flex;
  align-items: center;
  max-width: 400px;
  min-width: 400px;
  gap: 10px;
  padding-left: 5px;
  .ant-select-selector {
    border: 1px solid #1a73e8 !important;
    ::placeholder {
      color: #000 !important;
    }
  }

  :where(.css-dev-only-do-not-override-1vr7spz).ant-select-single:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    flex: 1;
  }
`;
const RejectButton = styled.span`
  min-width: 150px;
  height: 30px;
  border: 1px solid #909090;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #909090;
  font-size: 13px;
  border-radius: 5px;
  flex: 1;
  cursor: not-allowed;
  transition: 0.3s;
  font-weight: 500;
  &.active {
    color: #3d619b;
    border: 1px solid #3d619b;
    cursor: pointer;
    &:hover {
      background-color: #3d619b;
      color: #fff;
    }
  }
`;
const SectionCover = styled.div`
  flex: 1;
  /* max-width: 400px; */
  @media all and (max-width: 1550px) {
    &.lower_section {
      max-width: 250px !important;
    }
  }
`;
const CorrectThePlateSection = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 500;
  padding: 0 10px;
  gap: 10px;
  margin-top: 10px;
  input {
    border: 1px solid #d9d9d9;
    width: 200px;
    margin-left: 10px;
    padding: 5px 10px;
    text-transform: uppercase;
  }
`;
const OptionButton = styled.span`
  display: block;
  width: 20px;
  img {
    display: block;
    width: 100%;
  }
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    opacity: 0.6;
  }
`;
const AcceptButton = styled.span`
  height: 30px;
  border: 1px solid #3d619b;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3d619b;
  color: #fff;
  font-size: 13px;
  border-radius: 5px;
  flex: 1;
  max-width: 150px;
  min-width: 150px;
  cursor: pointer;
  transition: 0.3s;
  filter: grayscale(1);
  cursor: not-allowed;
  opacity: 0.5;
  &:hover {
    background-color: #fff;
    color: #3d619b;
  }
  &.active {
    filter: grayscale(0);
    cursor: pointer;
    opacity: 1;
  }
`;
const Radio = styled.span`
  display: block;
  width: 30px;
  height: 16px;
  background-color: #dfdfdf;
  border-radius: 10px;
  border: 2px solid #dfdfdf;
  position: relative;
  cursor: pointer;
  span {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    left: 0;
  }
  &.active {
    background-color: #3d619b;
    border-radius: 10px;
    border: 2px solid #3d619b;
    span {
      left: 15px;
    }
  }
  &.disabled {
    cursor: not-allowed;
    filter: grayscale(1);
  }
`;

const DisabledRadio = styled.span`
  display: block;
  width: 30px;
  height: 16px;
  background-color: #dfdfdf;
  border-radius: 10px;
  border: 2px solid #dfdfdf;
  position: relative;
  cursor: not-allowed;
  filter: grayscale(1);
  span {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    left: 0;
  }
  &.active {
    background-color: #3d619b;
    border-radius: 10px;
    border: 2px solid #3d619b;
    span {
      left: 15px;
    }
  }
`;
const GoodToPrint = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 5px;
`;
const ButtonCover = styled.div``;
const Loader = styled.div`
  min-height: 290px;
  display: flex;
  justify-content: center;
  align-content: center;
`;
const CarCover = styled.div``;
const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  /* margin-top: 10px; */
  position: absolute;
  left: 350px;
  /* top: -10px; */
`;
const SaveButton = styled.div`
  height: 30px;
  border: 1px solid #3d619b;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3d619b;
  color: #fff;
  font-size: 13px;
  border-radius: 5px;
  width: 100px;
  cursor: pointer;
  transition: 0.3s;
  &.inactive {
    filter: grayscale(100%);
    cursor: not-allowed;
    opacity: 0.8;
    :hover {
      background-color: #fff;
      color: #3d619b;
    }
  }
`;
const PlateRejectButton = styled.div`
  height: 30px;
  border: 1px solid #3d619b;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3d619b;
  font-size: 13px;
  border-radius: 5px;
  width: 100px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: #fff;
    color: #3d619b;
  }
`;
const ReasonSelect = styled.select`
  height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #1a73e8 !important;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
`;
