import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import arrow from "../../../assets/images/clean_pairs/right_arrow.svg";
import Placeholder_image from "../../../assets/images/image_placeholder.gif";
import { Select, Tooltip, notification } from "antd";
import {
	Truncate,
	formatDatev2,
	formatTime,
	getTimeFromTimeString,
} from "../../../utils/HelperFunctions";
import { formatTimeStr } from "antd/es/statistic/utils";
import CorrectThePlate from "../modals/CorrectThePlate";
import axiosConfig from "../../../axiosConfig";

function CarDetailsCard({
	handleSelectChange,
	data,
	RejectCleanPair,
	fetchCleanPairs,
	id,
}) {
	const [entryImageLoading, setEntryImageLoading] = useState(true);
	const [exitImageLoading, setExitImageLoading] = useState(true);
	const [isModal, setModal] = useState(false);
	const [isGoodToPrint, setGoodToPrint] = useState(false);
	const [value, setValue] = useState(null);

	const dropdownStyle = {
		overflow: "auto",
		textOverflow: "ellipsis",
		color: "#000",
		placeholder: {
			color: "#000 !important",
		},
	};
	useEffect(() => {
		setGoodToPrint(data.goodtoPrint === "True" ? true : false);
	}, [data]);

	const submitGoodToPrint = (id) => {
		axiosConfig
			.get(`UpdategoodToPrintforUI/?NotGoodtoPrintID=${id}`)
			.then((res) => {
				const { statusCode, data, paginationData } = res.data;
				if (statusCode === 200) {
					notification.success({
						message: "successfully updated",
						duration: 2,
					});
					setGoodToPrint((prev) => !prev);
				} else {
					notification.error({
						message: "Something went wrong",
						duration: 2,
					});
				}
			})
			.catch((err) => {
				console.log(err);

				notification.error({
					message: "Something went wrong",
					duration: 2,
				});
			});
	};

	return (
		<Container className={data.isStandard === "No" && "active"}>
			{isModal && (
				<CorrectThePlate
					setModal={setModal}
					data={data}
					fetchCleanPairs={fetchCleanPairs}
					id={id}
				/>
			)}
			<Entry>
				<Tooltip
					overlayInnerStyle={{ width: "800px" }}
					title={
						<img
							src={data.entryfullImage}
							alt=""
							style={{ maxWidth: "800px", width: "100%" }}
						/>
					}
					color={"black"}
				>
					<CarImage href={data.entryfullImage} target="_blank">
						<img
							src={
								entryImageLoading
									? Placeholder_image
									: data.entryfullImage
							}
							alt="Entry Img"
							onLoad={() => setEntryImageLoading(false)}
						/>
					</CarImage>
				</Tooltip>
				<EntryDetails>
					<Tooltip
						overlayInnerStyle={{ width: "320px" }}
						title={
							<img
								src={data.entryCroppedimage}
								alt=""
								style={{ maxWidth: "300px", width: "100%" }}
							/>
						}
						color={"black"}
					>
						<NumberPlate
							href={data.entryCroppedimage}
							target="_blank"
						>
							<img src={data.entryCroppedimage} alt="" />
						</NumberPlate>
					</Tooltip>
					<Feilds>
						<FeildName>Plate No:</FeildName>
						<FeildData
							style={{
								fontFamily: "Roboto Mono",
								fontWeight: 600,
								fontSize: "16px",
							}}
						>
							{data.entryPlate}
						</FeildData>
					</Feilds>
					<Feilds>
						<FeildName>Entry Date:</FeildName>
						<FeildData>{formatDatev2(data.arrivalTime)}</FeildData>
					</Feilds>
					<Feilds>
						<FeildName>Entry Time:</FeildName>
						<FeildData>{formatTime(data.arrivalTime)}</FeildData>
					</Feilds>
					<Feilds>
						<FeildName>Motion:</FeildName>
						<FeildData>{data.entryMotion}</FeildData>
					</Feilds>
				</EntryDetails>
			</Entry>
			<Exit>
				<Tooltip
					overlayInnerStyle={{ width: "820px" }}
					title={
						<img
							src={data.exitfullImage}
							alt=""
							style={{ maxWidth: "800px", width: "800px" }}
						/>
					}
					color={"black"}
				>
					<CarImage href={data.exitfullImage} target="_blank">
						<img
							src={
								exitImageLoading
									? Placeholder_image
									: data.exitfullImage
							}
							onLoad={() => setExitImageLoading(false)}
							alt="Entry Img"
						/>
					</CarImage>
				</Tooltip>
				<EntryDetails>
					<Tooltip
						overlayInnerStyle={{ width: "320px" }}
						title={
							<img
								src={data.exitCroppedImage}
								alt=""
								style={{ maxWidth: "300px", width: "100%" }}
							/>
						}
						color={"black"}
					>
						<NumberPlate
							href={data.exitCroppedImage}
							target="_blank"
						>
							<img src={data.exitCroppedImage} alt="" />
						</NumberPlate>
					</Tooltip>
					<Feilds>
						<FeildName>Plate No:</FeildName>
						<FeildData
							style={{
								fontFamily: "Roboto Mono",
								fontWeight: 600,
								fontSize: "16px",
							}}
						>
							{data.exitPlate}
						</FeildData>
					</Feilds>
					<Feilds>
						<FeildName>Exit Date:</FeildName>
						<FeildData>
							{formatDatev2(data.departureTime)}
						</FeildData>
					</Feilds>
					<Feilds>
						<FeildName>Exit Time:</FeildName>
						<FeildData>{formatTime(data.departureTime)}</FeildData>
					</Feilds>
					<Feilds>
						<FeildName>Motion:</FeildName>
						<FeildData>{data.exitMotion}</FeildData>
					</Feilds>
				</EntryDetails>
			</Exit>
			<VehicleDetails>
				<SectionTitle>Vehicle Details</SectionTitle>
				<Feilds>
					<FeildName className="veh_details">Make:</FeildName>
					<FeildData>{data.make}</FeildData>
				</Feilds>
				<Feilds>
					<FeildName className="veh_details">Model:</FeildName>
					<Tooltip title={data.model}>
						<FeildData>{Truncate(data.model, 10)}</FeildData>
					</Tooltip>
				</Feilds>
				<Feilds>
					<FeildName className="veh_details">Colour:</FeildName>
					<FeildData>{data.color}</FeildData>
				</Feilds>
				<Feilds>
					<FeildName className="veh_details">Car Web:</FeildName>
					<FeildData
						style={{
							fontSize: 16,
							fontWeight: 600,
						}}
						className={
							data.carWebStatus === "Valid" ? "valid" : "invalid"
						}
					>
						{data.carWebStatus}
					</FeildData>
				</Feilds>
			</VehicleDetails>
			<ViolationDetails>
				<SectionTitle>Violation Details</SectionTitle>
				<Feilds>
					<FeildName className="voi_details">Site Code:</FeildName>
					<FeildData>{data.siteCode} </FeildData>
				</Feilds>
				<Feilds>
					<FeildName className="voi_details">
						Site Violation Time:
					</FeildName>
					<FeildData>{data.violationTime} mins</FeildData>
				</Feilds>
				<Feilds>
					<FeildName className="voi_details">
						Stay Duration:
					</FeildName>
					<FeildData>{data.duration} mins</FeildData>
				</Feilds>
				<Feilds>
					<FeildName className="voi_details">
						Violation Type:
					</FeildName>
					<FeildData style={{ fontSize: "15px", minWidth: "110px" }}>
						{data.violationType}
					</FeildData>
				</Feilds>
			</ViolationDetails>
			<ActionSection>
				<Cover>
					<Tooltip title={data.reason}>
						<ReasonSelect
							style={{ width: "100%" }}
							defaultValue={""}
							onChange={(e) => {
								setValue(e.target.value);
								handleSelectChange(e, data.id);
							}}
							dropdownStyle={dropdownStyle}
							value={value}
						>
							<option disabled value="">
								Reason
							</option>
							<option value="Emergency Vehicle">
								Emergency Vehicle
							</option>
							<option value="Direction">Direction</option>
							<option value="Different Cars">
								Different Cars
							</option>
						</ReasonSelect>
					</Tooltip>
				</Cover>
				<RejectButton
					className={data.reason && "active"}
					onClick={() =>
						data.reason && RejectCleanPair(data.id, data.reason)
					}
				>
					Reject
				</RejectButton>
				<CorrectPlateBTN onClick={() => setModal(true)}>
					Correct the plate <Icon src={arrow} />
				</CorrectPlateBTN>
				<GoodToPrint>
					<FeildName>
						<FeildName>Good to print:</FeildName>
					</FeildName>
					<Radio
						className={isGoodToPrint && "active"}
						onClick={() => submitGoodToPrint(data.id)}
					>
						<span></span>
					</Radio>
				</GoodToPrint>
			</ActionSection>
		</Container>
	);
}

export default CarDetailsCard;
const Container = styled.div`
	display: flex;
	border: 1px solid #d0d1d2;
	border-radius: 5px;
	padding: 8px 5px;
	margin-bottom: 8px;
	min-width: 1140px;
	.ant-tooltip-inner {
		color: yellow;
		background-color: green;
		width: 800px;
	}
	/* gap: 20px; */
	&.active {
		background: rgba(251, 189, 85, 0.25);
	}
`;
const Entry = styled.div`
	display: flex;
	gap: 10px;
	padding: 0 10px 0 5px;
	position: relative;
	border-right: 1px solid #d0d1d2;
	flex: 1;
	/* max-width: 340px; */
`;
const Exit = styled.div`
	display: flex;
	gap: 10px;
	border-right: 1px solid #d0d1d2;
	padding: 0 10px;
	flex: 1;
	/* max-width: 340px; */
`;
const VehicleDetails = styled.div`
	border-right: 1px solid #d0d1d2;
	padding: 0 10px;
	min-width: 170px;
	max-width: 170px;
`;
const ViolationDetails = styled.div`
	border-right: 1px solid #d0d1d2;
	padding: 0 10px;
`;
const ActionSection = styled.div`
	padding: 0 10px;
	flex: 1;
	padding-right: 5px;
	max-width: 160px;
`;
const CarImage = styled.a`
	width: 100%;
	/* width: 100px; */
	/* min-width: 100px; */
	max-width: 200px;
	display: block;
	height: 120px;
	border-radius: 8px;
	overflow: hidden;
	img {
		display: block;
		/* object-fit: cover; */
		width: 100%;
		height: 120px;
		object-fit: cover;
	}
`;
const EntryDetails = styled.div``;
const NumberPlate = styled.a`
	height: 35px;
	display: block;
	img {
		display: block;
		height: 100%;
	}
	margin-bottom: 5px;
`;
const Feilds = styled.div`
	margin-bottom: 5px;
	display: flex;
	align-items: center;
`;
const FeildName = styled.p`
	font-size: 13px;
	color: #6a6a6a;
	width: 90px;
	&.veh_details {
		width: 60px !important;
	}
	&.voi_details {
		width: 130px;
	}
`;
const FeildData = styled.p`
	font-size: 12px;
	color: #6a6a6a;
	white-space: nowrap;
	text-transform: capitalize;
	color: #000;
	font-weight: 500;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	&.valid {
		color: green;
	}
	&.invalid {
		color: red;
	}
`;
const Radio = styled.span`
	display: block;
	width: 30px;
	height: 16px;
	background-color: #dfdfdf;
	border-radius: 10px;
	border: 2px solid #dfdfdf;
	position: relative;
	cursor: pointer;
	span {
		display: block;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background-color: #fff;
		position: absolute;
		left: 0;
	}
	&.active {
		background-color: #3d619b;
		border-radius: 10px;
		border: 2px solid #3d619b;
		span {
			left: 15px;
		}
	}
`;
const SectionTitle = styled.p`
	font-size: 14px;
	color: #3d619b;
	margin-bottom: 15px;
`;
const RejectButton = styled.span`
	height: 30px;
	border: 1px solid #3d619b;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #3d619b;
	font-size: 13px;
	border-radius: 5px;
	cursor: not-allowed;
	transition: 0.3s;
	margin-top: 5px;
	filter: grayscale(1);
	opacity: 0.5;

	&.active {
		cursor: pointer;
		filter: grayscale(0);
		opacity: 1;
		&:hover {
			background-color: #3d619b;
			color: #fff;
		}
	}
`;
const Cover = styled.div`
	border-radius: 5px;
	flex: 1;
	margin-top: 10px;
	.ant-select-selector {
		border: 1px solid #1a73e8 !important;
		::placeholder {
			color: #000 !important;
		}
	}
	/* border: 1px solid #d0d1d2; */
`;
const CorrectPlateBTN = styled.div`
	display: flex;
	align-items: center;
	font-size: 12px;
	gap: 10px;
	color: #1a73e8;
	margin-top: 5px;
	cursor: pointer;
`;
const Icon = styled.img`
	display: block;
	width: 10px;
`;
const GoodToPrint = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 5px;
`;
const ReasonSelect = styled.select`
	height: 30px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	border: 1px solid #1a73e8 !important;
	border-radius: 5px;
	outline: none;
	box-shadow: none;
`;
