export const bgColorSites = [
  {
    id: 1,
    siteCode: "013",
  },
  {
    id: 2,
    siteCode: "019",
  },
  {
    id: 3,
    siteCode: "037",
  },
  {
    id: 4,
    siteCode: "158",
  },
  {
    id: 5,
    siteCode: "200",
  },
  {
    id: 6,
    siteCode: "219",
  },
  {
    id: 7,
    siteCode: "223",
  },
  {
    id: 8,
    siteCode: "253",
  },
  {
    id: 9,
    siteCode: "270",
  },
  {
    id: 10,
    siteCode: "312",
  },
  {
    id: 11,
    siteCode: "347",
  },
  {
    id: 12,
    siteCode: "443",
  },
  {
    id: 13,
    siteCode: "496",
  },
  {
    id: 14,
    siteCode: "503",
  },
  {
    id: 15,
    siteCode: "542",
  },
  {
    id: 16,
    siteCode: "573",
  },
  {
    id: 17,
    siteCode: "675",
  },
  {
    id: 18,
    siteCode: "682",
  },
  {
    id: 19,
    siteCode: "713",
  },
  {
    id: 20,
    siteCode: "737",
  },
  {
    id: 21,
    siteCode: "783",
  },
  {
    id: 22,
    siteCode: "841",
  },
  {
    id: 23,
    siteCode: "851",
  },
  {
    id: 24,
    siteCode: "897",
  },
  {
    id: 25,
    siteCode: "901",
  },
  {
    id: 26,
    siteCode: "964",
  },
  {
    id: 27,
    siteCode: "2080",
  },
  {
    id: 28,
    siteCode: "2091",
  },
  {
    id: 29,
    siteCode: "2303",
  },
  {
    id: 30,
    siteCode: "2316",
  },
  {
    id: 31,
    siteCode: "2350",
  },
  {
    id: 32,
    siteCode: "2505",
  },
  {
    id: 33,
    siteCode: "2542",
  },
  {
    id: 34,
    siteCode: "2570",
  },
  {
    id: 35,
    siteCode: "2579",
  },
  {
    id: 36,
    siteCode: "2595",
  },
  {
    id: 37,
    siteCode: "2632",
  },
  {
    id: 38,
    siteCode: "2928",
  },
  {
    id: 39,
    siteCode: "3009",
  },
  {
    id: 40,
    siteCode: "3142",
  },
  {
    id: 41,
    siteCode: "9037",
  },
  {
    id: 42,
    siteCode: "9043",
  },
  {
    id: 43,
    siteCode: "9132",
  },
  {
    id: 44,
    siteCode: "9295",
  },
  {
    id: 45,
    siteCode: "9348",
  },
  {
    id: 46,
    siteCode: "9546",
  },
  {
    id: 47,
    siteCode: "9583",
  },
  {
    id: 48,
    siteCode: "9862",
  },
];
