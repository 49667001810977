import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../Constants";

function ConfirmationModal({ handleSubmitPlate, setModal }) {
	return (
		<Container>
			<ModalContent>
				<Title>
					Are you sure you want to proceed with this change?
				</Title>
				<Description>
					Please make sure to correct the plates in All Pairs section
					below, before you make changes to the plate.
				</Description>
				<ButtonSection>
					<SaveButton
						onClick={() => {
							handleSubmitPlate();
							setModal(false);
						}}
					>
						Confirm
					</SaveButton>
					<PlateRejectButton onClick={() => setModal(false)}>
						Cancel
					</PlateRejectButton>
				</ButtonSection>
			</ModalContent>
		</Container>
	);
}

export default ConfirmationModal;
const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	/* backdrop-filter: blur(8px); */
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 999;
`;
const ModalContent = styled.div`
	background-color: white;
	padding: 30px;
	border-radius: 4px;
	width: 500px;
	position: relative;
`;
const Title = styled.h3`
	font-weight: 500;
	font-size: 18px;
	color: ${COLORS.THEME_COLOR};
`;
const Description = styled.p`
	font-size: 16px;
	margin-top: 20px;
	color: ${COLORS.PRIMARY};
`;
const ButtonSection = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 10px;
	margin-top: 30px;
`;
const SaveButton = styled.div`
	height: 30px;
	border: 1px solid #3d619b;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #3d619b;
	color: #fff;
	font-size: 13px;
	border-radius: 5px;
	width: 100px;
	cursor: pointer;
	transition: 0.3s;
	&:hover {
		background-color: #fff;
		color: #3d619b;
	}
`;
const PlateRejectButton = styled.div`
	height: 30px;
	border: 1px solid #3d619b;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #3d619b;
	font-size: 13px;
	border-radius: 5px;
	width: 100px;
	cursor: pointer;
	transition: 0.3s;
	&:hover {
		background-color: #fff;
		color: #3d619b;
		opacity: 0.8;
	}
`;
