import { Spin } from "antd";
import React from "react";
import { styled } from "styled-components";

function DataFetchIngLoader() {
	return (
		<Container>
			<Spin />
		</Container>
	);
}

export default DataFetchIngLoader;
const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	/* height: calc(100vh - 230px); */
`;
