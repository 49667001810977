import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { authProvider } from "./authProvider";
import { Router } from "react-router-dom";
import { Store } from "../src/components/redux/Store";

import { createHashRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import Login from "./components/screens/auth/Login";
import { styled } from "styled-components";
import Lottie from "react-lottie";
import animationData from "../src/assets/lotties/authenticating.json";
// import "antd/dist/antd.css";

const router = createHashRouter([
	{
		path: "/*",
		element: <App />,
	},
]);
const defaultOptions = {
	loop: false,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	// <React.StrictMode>
	<AzureAD provider={authProvider}>
		{({ login, logout, authenticationState, error, accountInfo }) => {
			sessionStorage.setItem("user", JSON.stringify(accountInfo));
			error && console.log(error);
			// eslint-disable-next-line default-case
			switch (authenticationState) {
				case AuthenticationState.Authenticated:
					return (
						<Provider store={Store}>
							<RouterProvider router={router} />
						</Provider>
					);
				case AuthenticationState.Unauthenticated:
					return <Login handleLogin={login} />;
				case AuthenticationState.InProgress:
					return (
						<AuthLoading>
							<Lottie
								options={defaultOptions}
								width={300}
								height={300}
							/>
							Authenticating
						</AuthLoading>
					);
			}
		}}
	</AzureAD>
	// </React.StrictMode>
);

reportWebVitals();
const AuthLoading = styled.div`
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;
