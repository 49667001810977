import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { COLORS } from "../../../../Constants";
import { formatDatev2, formatTime } from "../../../../utils/HelperFunctions";
import Placeholder_image from "../../../../assets/images/image_placeholder.gif";

function SimilarPlatesCard({ data, submitPlate, currentInvaildPair }) {
  const [PlateNumber, setPlateNumber] = useState("");
  useEffect(() => {
    setPlateNumber(data.plate);
  }, [data]);

  //   function convertToUpperCaseAndRemoveSpaces(inputString) {
  //     // Convert the string to uppercase
  //     let uppercasedString = inputString.toUpperCase();

  //     // Remove spaces from the string
  //     let stringWithoutSpaces = uppercasedString.replace(/\s/g, "");

  //     return stringWithoutSpaces;
  //   }

  function convertToUpperCaseAndRemoveSpaces(inputString) {
    // Replace any characters other than numbers and alphabets with an empty string
    const filteredString = inputString.replace(/[^a-zA-Z0-9]/g, "");

    // Convert the filtered string to uppercase
    const uppercasedString = filteredString.toUpperCase();

    return uppercasedString;
  }

  const [entryImageLoading, setEntryImageLoading] = useState(true);
  function capitalizeFirstLetter(string) {
    console.log("SimilarPlatesCard string", string);
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }

  return (
    <Container currentInvaildPair={currentInvaildPair} data={data}>
      <LeftSection>
        <Tooltip
          overlayInnerStyle={{ width: "820px" }}
          title={
            <img
              src={data?.fullImage}
              alt=""
              style={{
                maxWidth: "800px",
                width: "800px",
              }}
            />
          }
          color={"black"}
        >
          <CarImage href={data?.fullImage} target="_blank">
            <img
              src={entryImageLoading ? Placeholder_image : data?.fullImage}
              alt="Entry Img"
              onLoad={() => setEntryImageLoading(false)}
            />
          </CarImage>
        </Tooltip>
        <EntryDetails>
          <Tooltip
            overlayInnerStyle={{ width: "420px" }}
            title={
              <img
                src={data?.croppedImage}
                alt=""
                style={{
                  maxWidth: "400px",
                  width: 400,
                }}
              />
            }
            color={"black"}
          >
            <NumberPlate href={data?.croppedImage} target="_blank">
              <img src={data?.croppedImage} alt="" />
            </NumberPlate>
          </Tooltip>
          <Feilds>
            <FeildName>Plate No:</FeildName>
            <FeildData
              style={{
                fontFamily: "Roboto Mono",
                fontWeight: 600,
                fontSize: "18px",
              }}
            >
              {data?.plate}
            </FeildData>
          </Feilds>
        </EntryDetails>
      </LeftSection>
      <MiddleSection>
        <Feilds>
          <FeildName>Site:</FeildName>
          <FeildData>{data?.siteCode}</FeildData>
        </Feilds>
        <Feilds>
          <FeildName> Date:</FeildName>
          <FeildData
            style={{
              // fontFamily: "Roboto Mono",
              fontWeight: 600,
              fontSize: "16px",
            }}
          >
            {formatDatev2(data?.date)}
          </FeildData>
        </Feilds>
        <Feilds>
          <FeildName> Time:</FeildName>
          <FeildData
            style={{
              // fontFamily: "Roboto Mono",
              fontWeight: 600,
              fontSize: "16px",
            }}
          >
            {formatTime(data?.date)}
          </FeildData>
        </Feilds>
        <Feilds>
          <FeildName>Motion:</FeildName>
          <FeildData>{capitalizeFirstLetter(data?.motion)}</FeildData>
        </Feilds>
        <Feilds>
          <FeildName>Direction</FeildName>
          <FeildData>{data.direction}</FeildData>
        </Feilds>
      </MiddleSection>
      <RightSection>
        <CorrectThePlateSection>
          Correct the plate{" "}
          <input
            type="text"
            value={PlateNumber}
            style={{
              fontFamily: "Roboto Mono",
              fontWeight: 500,
            }}
            onChange={(e) => {
              currentInvaildPair.entryPlateId !== data.plateid &&
                setPlateNumber(
                  convertToUpperCaseAndRemoveSpaces(e.target.value)
                );
            }}
          />
        </CorrectThePlateSection>
        <ButtonSection>
          {currentInvaildPair.entryPlateId !== data.plateid &&
          currentInvaildPair.exitPlateId !== data.plateid ? (
            <RejectButton onClick={() => setPlateNumber(data.plate)}>
              Cancel
            </RejectButton>
          ) : null}
          {currentInvaildPair.entryPlateId !== data.plateid &&
          currentInvaildPair.exitPlateId !== data.plateid ? (
            <AcceptButton
              onClick={() => {
                if (PlateNumber !== data.plate) {
                  submitPlate(data.plateid, PlateNumber);
                }
              }}
              className={PlateNumber === data.plate && "inactive"}
            >
              Update Plate
            </AcceptButton>
          ) : null}
        </ButtonSection>
      </RightSection>
    </Container>
  );
}

export default SimilarPlatesCard;
const Container = styled.div`
  opacity: ${(props) =>
    props.currentInvaildPair.entryPlateId === props.data.plateid ||
    props.currentInvaildPair.exitPlateId === props.data.plateid
      ? "0.5"
      : ""};
  cursor: ${(props) =>
    props.currentInvaildPair.entryPlateId === props.data.plateid ||
    props.currentInvaildPair.exitPlateId === props.data.plateid
      ? "not-allowed"
      : ""};
  border-bottom: 1px solid #d9d9d9;
  padding: 10px 0;
  display: flex;
`;
const LeftSection = styled.div`
  display: flex;
  gap: 10px;
  flex: 1;
  border-right: 1px solid #d9d9d9;
  padding: 0 10px;
  max-width: 600px;
  @media all and (min-width: 1550px) {
    gap: 20px;
  }
`;
const MiddleSection = styled.div`
  padding: 0 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-right: 1px solid #d9d9d9;
  max-width: 2500px;
  min-width: 250px;
`;
const RightSection = styled.div`
  flex: 1;
  padding: 0 10px;
  @media all and (min-width: 1550px) {
    padding: 0 30px;
  }
`;
const CarImage = styled.a`
  display: block;
  width: 100%;
  /* width: 100px; */
  max-width: 150px;
  height: 100px;
  border-radius: 8px;
  overflow: hidden;
  img {
    display: block;
    /* object-fit: cover; */
    width: 100%;
    height: 100px;
    object-fit: cover;
  }
  @media all and (min-width: 1550px) {
    max-width: 250px;
    height: 120px;
    img {
      height: 120px;
    }
  }
`;
const EntryDetails = styled.div`
  flex: 1;
`;
const NumberPlate = styled.a`
  height: 40px;
  width: 200px;
  display: block;
  img {
    display: block;
    height: 100%;
    width: 100%;
  }
  margin-bottom: 5px;
`;
const Feilds = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;
const FeildName = styled.p`
  font-size: 13px;
  color: #6a6a6a;
  width: 90px;
  &.veh_details {
    width: 60px !important;
  }
  &.voi_details {
    width: 130px;
  }
`;
const FeildData = styled.p`
  font-size: 12px;
  /* color: ${COLORS.THEME_COLOR}; */
  white-space: nowrap;
  font-weight: 500;
`;
const CorrectThePlateSection = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  input {
    border: 1px solid #d9d9d9;
    width: 200px;
    margin-left: 20px;
  }
`;
const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
`;
const RejectButton = styled.span`
  height: 25px;
  border: 1px solid #3d619b;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3d619b;
  font-size: 13px;
  border-radius: 5px;
  width: 80px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: #3d619b;
    color: #fff;
  }
`;
const AcceptButton = styled.span`
  height: 25px;
  border: 1px solid #3d619b;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3d619b;
  color: #fff;
  font-size: 13px;
  border-radius: 5px;
  width: 120px;
  cursor: pointer;
  transition: 0.3s;
  &.inactive {
    filter: grayscale(100%);
    cursor: not-allowed;
    opacity: 0.8;
    :hover {
      background-color: #fff;
      color: #3d619b;
    }
  }
`;
