import React, { useState, useEffect } from "react";
import { Modal, Input, Button } from "antd";
import axiosConfig from "../../../axiosConfig";
import { Select } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";

const AddUserModal = ({
  visible,
  fetchData,
  setOpen,
  isEditing,
  editUserEmail,
  editUserRole,
  editUserId,
  setSuccessModal,
  setMessage,
}) => {
  const [userRole, setUserRole] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [showError, setshowError] = useState(false);
  const { userId } = useSelector((state) => state?.user?.userProfile);

  const handleNameChange = (e) => {
    setUserRole(e);
  };

  const handleEmailChange = (e) => {
    setUserEmail(e.target.value);
  };
  const AddUserData = () => {
    setLoading(true);
    if (userEmail.length !== 0 && userRole.length !== 0) {
      setIsEmpty(false);
      const queryParams = {
        UserDetailsInput: JSON.stringify({
          emailId: userEmail,
          userRole: userRole,
          createdBy: userId,
        }),
      };
      axiosConfig
        .get(
          `AddNewUser?UserDetailsInput=`,

          {
            params: queryParams,
          }
        )
        .then((res) => {
          setshowError(false);
          setLoading(false);
          fetchData();
          setOpen(false);
          setSuccessModal(true);
          setMessage("User created Successfully");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setIsEmpty(true);
      setshowError(true);
    }
  };

  const editUser = () => {
    setLoading(true);
    if (userEmail.length !== 0 && userRole.length !== 0) {
      const queryParams = {
        EditUserDetailsInput: JSON.stringify({
          userId: editUserId,
          emailId: userEmail,
          userRole: userRole,
          modifiedBy: userId,
        }),
      };
      axiosConfig
        .get(
          `EditUser?EditUserDetailsInput=`,

          {
            params: queryParams,
          }
        )
        .then((res) => {
          setLoading(false);
          fetchData();
          setOpen(false);
          setSuccessModal(true);
          setMessage("User updated successfully");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setIsEmpty(true);
      setshowError(true);
    }
  };

  const onCreate = () => {
    isEditing ? editUser() : AddUserData();
    setUserEmail("");
    setUserRole("");
  };

  const { Option } = Select;

  useEffect(() => {
    setUserEmail(editUserEmail);
    setUserRole(editUserRole);
  }, [editUserEmail, editUserRole]);

  const handleCancel = () => {
    setOpen(false);
    setshowError(false);
  };

  return (
    <Modal
      width={310}
      style={{ marginTop: "100px", height: "200px" }}
      visible={visible}
      //   title={isEditing ? "Edit User" : "Add User"}
      onCancel={handleCancel}
      footer={[
        <Button
          key="cancel"
          style={{ color: "#3D619B" }}
          onClick={handleCancel}
        >
          Cancel
        </Button>,
        <Button
          style={{ marginRight: "60px", backgroundColor: "#3D619B" }}
          key="add"
          type="primary"
          onClick={() => onCreate({ userRole, userEmail })}
        >
          {isEditing ? "Edit" : "Add"}
        </Button>,
      ]}
    >
      {" "}
      <h3 style={{ color: "#3D619B" }}>
        {isEditing ? "Edit User" : "Add User"}
      </h3>
      <div
        style={{
          borderTop: " 1px solid #D0D1D2",
          marginBottom: "18px",
        }}
      ></div>
      <div style={{ position: "relative" }}>
        {" "}
        <Input
          placeholder="User Email"
          // defaultValue={editUserEmail}
          value={userEmail}
          onChange={handleEmailChange}
          style={{
            marginBottom: 16,
            marginTop: "10px",
            borderColor: "#D0D1D2",
          }}
        />
        {showError && (
          <p
            style={{
              fontSize: "12px",
              color: "red",
              position: "absolute",
              bottom: "-2px",
            }}
          >
            Enter an Email
          </p>
        )}
      </div>
      <div>
        <Select
          bordered={"#D0D1D2"}
          style={{
            width: "100%",
            marginTop: "10px",
            marginBottom: "10px",
          }}
          placeholder="user Role"
          // defaultValue="analyst"
          value={userRole ? userRole : null}
          onChange={(e) => handleNameChange(e)}
        >
          <Option value="analyst">Analyst</Option>
          <Option value="manager">Manager</Option>
        </Select>
        {showError && (
          <p
            style={{
              fontSize: "12px",
              color: "red",
              position: "absolute",
              bottom: "55px",
            }}
          >
            Select a user role
          </p>
        )}
      </div>
    </Modal>
  );
};

export default AddUserModal;
