import React, { useState } from 'react';
import { Modal, Button } from 'antd';

const SuccessModal = ({ visible,message, onOk, onCancel }) => {
  return (
    <Modal
      title="User Created Successfully"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button key="submit" type="primary" onClick={onOk}>
          Okay
        </Button>,
      ]}
    >
      <p>{message}</p>
    </Modal>
  );
};

export default SuccessModal;