import React, { useState } from "react";
import { styled } from "styled-components";
import { useSelector } from "react-redux";

// Antd
import { Button, Divider, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { formatDatev2 } from "../../../utils/HelperFunctions";
import { Form, Input } from "antd";

const SiteHoldUnHoldModal = ({
  openModal,
  closeModal,
  latestHistoryData,
  handleCommentFromModal,
  fetchHoldUnholdApi,
}) => {
  const [commentTyped, setCommentTyped] = useState("");
  const rowData = useSelector((state) => state?.user?.sitePerfomanceRowData);

  const handleCommentBox = (event) => {
    const typedComment = event.target.value;
    setCommentTyped(typedComment);

    handleCommentFromModal(typedComment);
  };

  const handleConfirmBtn = () => {
    const siteCode = latestHistoryData?.site;
    fetchHoldUnholdApi(siteCode);
    closeModal();
  };

  const modalTittleContent = () => {
    return (
      <>
        <ModalTittle>
          <TittleText>Site Performance</TittleText>
        </ModalTittle>
        <Divider style={dividerStyle} />
      </>
    );
  };
  return (
    <>
      <Modal
        title={modalTittleContent()}
        centered
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        open={openModal}
        onCancel={closeModal}
      >
        <ModalContent>
          <ItemBox>
            <Items>
              <ItemKey>Site Code</ItemKey>
              <ItemValue>{rowData?.site}</ItemValue>
            </Items>

            <Items>
              <ItemKey>Records to be processed</ItemKey>
              <ItemValue>{rowData?.recordsToBeProcessed}</ItemValue>
            </Items>
          </ItemBox>

          {rowData?.hasHistory === "true" && (
            <>
              <ItemBox>
                <Items>
                  <ItemKey>Previous action by</ItemKey>
                  <EmailID>{latestHistoryData?.emailId}</EmailID>
                </Items>

                <Items>
                  <ItemKey>Previous date of action</ItemKey>
                  <ItemValue>
                    {formatDatev2(latestHistoryData?.timeUpdated)}
                  </ItemValue>
                </Items>
              </ItemBox>

              <ItemBox>
                <Items>
                  <ItemKey>Previous Comments</ItemKey>
                  <ReasonText>{latestHistoryData?.reason || "--"}</ReasonText>
                </Items>
              </ItemBox>
            </>
          )}

          <CommentBox>
            <ItemKey>*Comment</ItemKey>
            <Form name="commentForm" initialValues={{ comment: commentTyped }}>
              <Form.Item
                name="comment"
                rules={[
                  {
                    required: true,
                    message: "Please input your comment!",
                  },
                ]}
              >
                <TextArea
                  placeholder="Comment..."
                  rows={4}
                  type="text"
                  value={commentTyped}
                  onChange={handleCommentBox}
                />
              </Form.Item>
            </Form>
          </CommentBox>
        </ModalContent>

        <BtnBox>
          <CancelBtn onClick={closeModal}>Cancel</CancelBtn>
          <ConfirmBtn
            disabled={commentTyped.length === 0}
            onClick={handleConfirmBtn}
          >
            Confirm
          </ConfirmBtn>
        </BtnBox>
      </Modal>
    </>
  );
};

export default SiteHoldUnHoldModal;

const ModalTittle = styled.div`
  margin-bottom: 14px;
`;

const dividerStyle = {
  padding: "0",
  margin: "0",
  borderTop: "1px solid #D0D1D2",
};

const TittleText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #3d619b;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 23px;
`;

const ItemBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Items = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ItemKey = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #060606;
`;

const ItemValue = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #6a6a6a;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const EmailID = styled.span`
  width: 225px;
  font-size: 16px;
  font-weight: 400;
  color: #6a6a6a;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ReasonText = styled.span`
  max-height: 100px;
  font-size: 16px;
  font-weight: 400;
  color: #6a6a6a;
  overflow: auto;
`;

const CommentBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const BtnBox = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: flex-end;
  padding-top: 2rem;
`;

const CancelBtn = styled.button`
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 500;
  color: #3d619b;
  background: transparent;
  border: 1px solid #3d619b;
  border-radius: 4px;
  cursor: pointer;
`;

const ConfirmBtn = styled.button`
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  background: #3d619b;
  border: 1px solid #3d619b;
  border-radius: 4px;
  cursor: pointer;

  /*-------| Disable button style |-------*/
  ${({ disabled }) => disabled && `opacity: 0.6; cursor: not-allowed;`}
`;
