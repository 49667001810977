import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { COLORS } from "../../../Constants";
import edit from "../../../assets/images/editIconUser-Mapping.svg";
import delete_btn from "../../../assets/images/delete.svg";
import { Button, Flex } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { DatePicker, Input, Pagination, Select, Table } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axiosConfig from "../../../axiosConfig";
import { formatDatev2 } from "../../../utils/HelperFunctions";
import AddUserModal from "./AddUserModal";
import {
  CaretUpOutlined,
  CaretDownOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import SuccessModal from "./SuccessModal";
import DeleteUserModal from "./DeleteUserModal";

function UserMapping() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [pageCount, setPageCount] = useState(15);
  const [sortFeild, setSortFeild] = useState("");
  const [sortOrder, setSortOrder] = useState("0");
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState("");
  const [editUserEmail, setEditUserEmail] = useState("");
  const [editUserRole, setEditUserRole] = useState("");
  const [editUserId, setEditUserId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [message, setMessage] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [search, setSearch] = useState({
    siteCode: null,
    siteName: null,
    startDate: null,
    endDate: null,
  });

  function formatAntdDatePickerDate(selectedDate) {
    // Use the `format` function from moment.js to format the date
    return selectedDate.format("YYYY-MM-DD");
  }

  const handleSearch = (column, value) => {
    const data = { ...search };

    if (column === "startDate" || column === "endDate") {
      if (value) {
        const selectedDate = value;
        const formattedDate = formatAntdDatePickerDate(selectedDate);
        data[column] = formattedDate;
      } else {
        data[column] = null;
      }
    } else {
      data[column] = value;
    }

    setSearch(data);
  };

  const handleCustomSort = (column, currentSort) => {
    if (currentSort === "0") {
      setSortFeild(column);
      setSortOrder("1");
    } else if (currentSort === "1") {
      setSortFeild(null);
      setSortOrder(null);
    } else {
      setSortFeild(column);
      setSortOrder("0");
    }
  };

  const columns = [
    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("siteCode", sortOrder)}>
            <HeaderName>User ID</HeaderName>
          </Top>

          <Input
            placeholder="Search..."
            prefix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            onChange={(e) => handleSearch("siteCode", e.target.value)}
            style={{ width: 150, padding: "5px 10px" }}
          />
        </TableHeader>
      ),
      dataIndex: "userId",
      key: "userId",
      // Enable sorting for this column
      className: "custom-sort-header", // Add the custom class
    },
    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("emailId", sortOrder)}>
            <HeaderName>User Email</HeaderName>
          </Top>
          <Input
            placeholder="Search..."
            prefix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            onChange={(e) => handleSearch("emailId", e.target.value)}
            style={{ width: 150, padding: "5px 10px" }}
          />
        </TableHeader>
      ),
      dataIndex: "emailId",
      key: "emailId",
    },

    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("userRole", sortOrder)}>
            <HeaderName>User Role</HeaderName>
          </Top>
          <Input
            placeholder="Search..."
            prefix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            onChange={(e) => {
              handleSearch("userRole", e.target.value);
            }}
            style={{ width: 150, padding: "5px 10px" }}
          />
        </TableHeader>
      ),
      dataIndex: "userRole",
      key: "userRole",
    },

    {
      align: "right",
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("noOfCameras", sortOrder)}>
            <HeaderName>Actions</HeaderName>
          </Top>
          <span style={{ height: 30, display: "block" }}></span>
        </TableHeader>
      ),
      key: "action",
      render: (e) => (
        <ButtonContainer>
          <Edit>
            <img
              color="#3B7FFC"
              src={edit}
              alt="Edit"
              onClick={() => editHandler(e)}
            />
          </Edit>
          <img
            onClick={() => deleteHandler(e)}
            style={{ marginBottom: "8px", cursor: "pointer" }}
            src={delete_btn}
            alt="delete"
          />
        </ButtonContainer>
      ),
    },
  ];

  const handlePageSizeChange = (size) => {
    setPageCount(size);
    setCurrentPage(1);
  };

  const fetchData = () => {
    setLoading(true);
    const queryParams = {
      UserDetailsInput: JSON.stringify({
        userId: search.siteCode,
        emailId: search.emailId,
        userRole: search.userRole,
        pageNumber: currentPage,
        pageSize: pageCount,
        // sortBy: sortFeild,
        // sortOrder: sortOrder,
      }),
    };
    axiosConfig
      .get(
        `GetAllUserDetails?UserDetailsInput=`,

        {
          params: queryParams,
        }
      )
      .then((res) => {
        const { statusCode, data, paginationData } = res.data;
        if (statusCode === 200) {
          setTableData(data);

          setPaginationData(paginationData);
        } else {
          setTableData([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const deleteUser = () => {
    setLoading(true);
    const queryParams = {
      DeleteUserInput: JSON.stringify({
        userId: deleteUserId,
        modifiedBy: deleteUserId,
      }),
    };
    axiosConfig
      .get(
        `DeleteUser?DeleteUserInput=`,

        {
          params: queryParams,
        }
      )
      .then((res) => {
        setLoading(false);
        fetchData();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getSpecificUser = (email) => {
    setLoading(true);
    const queryParams = {
      UserDetailsInput: JSON.stringify({
        emailId: email,
        accessToken: "",
      }),
    };
    axiosConfig
      .get(
        `GetUserDetails?UserDetailsInput=`,

        {
          params: queryParams,
        }
      )
      .then((res) => {
        setLoading(false);

        setEditUserRole(res.data.data[0].userRole);
        setEditUserEmail(res.data.data[0].emailId);
        setEditUserId(res.data.data[0].userId);
        fetchData();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [pageCount, currentPage, search, sortFeild, sortOrder]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter?.column?.key) {
      const { field, order } = sorter;
      setSortFeild(order === "ascend" || "descend" ? field : null);
      setSortOrder(order === "ascend" ? "0" : order === "descend" ? "1" : "0");
    }
  };

  const handleAdd = () => {
    setIsEditing(false);
    setEditUserEmail("");
    setEditUserRole("");
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const deleteHandler = (e) => {
    setOpenDeleteModal(true);
    setDeleteUserId(e.userId);
  };

  const editHandler = (e) => {
    setOpen(true);
    setIsEditing(true);
    getSpecificUser(e.emailId);
  };
  const handleOk = () => {
    setSuccessModal(false);

    navigate("/home");
    window.location.reload();
  };

  const handleCancelSuccessModal = () => {
    setSuccessModal(false);

    navigate("/home");
    window.location.reload();
  };

  const handleNo = () => {
    setOpenDeleteModal(false);
  };

  const handleDeleteCancel = () => {
    setOpenDeleteModal(false);
  };

  const handleYes = () => {
    deleteUser();
    setOpenDeleteModal(false);
  };

  return (
    <Container>
      <TopSection>
        <Title>User Mapping</Title>
        <RightSection>
          <Button
            style={{ color: "#3D619B" }}
            icon={<PlusOutlined color="#3D619B" />}
            onClick={handleAdd}
          >
            Add
          </Button>
        </RightSection>
      </TopSection>
      <SiteListContainer>
        {" "}
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          onChange={handleTableChange}
          loading={isLoading}
        />
        <BottomSection>
          <div>
            <span>Rows per page: </span>
            <Select
              defaultValue={pageCount}
              onChange={handlePageSizeChange}
              style={{ width: 80 }}
            >
              <Select.Option value={10}>10</Select.Option>
              <Select.Option value={15}>15</Select.Option>
              <Select.Option value={30}>30</Select.Option>
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={100}>100</Select.Option>
            </Select>
          </div>
          <PaginationCover>
            <Pagination
              total={paginationData?.count}
              currentPage={currentPage}
              pageSize={pageCount}
              onChange={handlePageChange}
              current={currentPage}
            />
          </PaginationCover>
        </BottomSection>
      </SiteListContainer>
      <AddUserModal
        visible={open}
        fetchData={fetchData}
        setOpen={setOpen}
        isEditing={isEditing}
        editUserEmail={editUserEmail}
        editUserRole={editUserRole}
        editUserId={editUserId}
        setSuccessModal={setSuccessModal}
        setMessage={setMessage}
      />
      <SuccessModal
        visible={successModal}
        message={message}
        onOk={handleOk}
        onCancel={handleCancelSuccessModal}
      />
      <DeleteUserModal
        visible={openDeleteModal}
        onYes={handleYes}
        onNo={handleNo}
        onCancel={handleDeleteCancel}
      />
    </Container>
  );
}

export default UserMapping;
const Container = styled.div`
  padding: 20px;
  .ant-pagination-options {
    display: none;
  }
  :where(.css-dev-only-do-not-override-1vr7spz).ant-picker {
    padding: 0 11px;
  }
  :where(.css-dev-only-do-not-override-1vr7spz).ant-table-wrapper
    .ant-table-column-title,
  :where(.css-dev-only-do-not-override-1vr7spz).ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    font-size: 14px;
    font-weight: 500;
  }
  :where(.css-dev-only-do-not-override-3mqfnx).ant-table-wrapper
    .ant-table-thead
    > tr
    > th {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .custom-sort-header {
    cursor: pointer;
  }
`;
const TopSection = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
`;
const Title = styled.h2`
  color: ${COLORS.THEME_COLOR};
  font-size: 18px;
  font-weight: 500;
`;
const RightSection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
const SiteListContainer = styled.div`
  margin-top: 10px;
  max-height: calc(100vh - 180px);
  min-height: calc(100vh - 180px);
  overflow: scroll;
  border: 0.5px solid #d0d1d2;
  border-radius: 10px;
  .ant-table {
    min-height: calc(100vh - 240px) !important;
    max-height: calc(100vh - 240px) !important;
    overflow: scroll;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: 0.5px solid #d0d1d2;
    border-collapse: collapse;
    /* background-color: #fff; */
    padding: 10px 15px;
    font-size: 12px;
  }
  .ant-table-thead > tr > th {
    font-weight: 600;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
  }
  .ant-pagination-item a {
    color: #000 !important;
  }
`;
const ButtonContainer = styled.div`
  display: block;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
`;
const Edit = styled(Link)``;
const Delete = styled.span``;
const TableHeader = styled.div`
  input {
    padding: 0 10px;
  }
`;
const HeaderName = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
  white-space: nowrap;
`;
const BottomSection = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
`;
const PaginationCover = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;
const Top = styled.div`
  display: flex;
  align-items: center;
`;
const Sort = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translate(0, -5px);
  margin-left: 10px;
`;
const Icon = styled.span`
  display: block;
  height: 10px;
`;
