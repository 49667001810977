import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SingleCarDetails from "../includes/clean-pairs/SingleCarDetails";
import CorrectThePlate from "../includes/modals/CorrectThePlate";
import axiosConfig from "../../axiosConfig";
import { DatePicker, Pagination, Select, notification, TimePicker } from "antd";
import search__icon from "../../assets/images/search.svg";
import DataFetchIngLoader from "../general/DataFetchIngLoader";
import SimilarPlatesCard from "../includes/modals/general/SimilarPlatesCard";
import NoData from "../general/NoData";
import { formatDatev2 } from "../../utils/HelperFunctions";
import { useSelector } from "react-redux";
import { ReloadOutlined } from "@ant-design/icons";
import UseDebounce from "../general/UseDebounce";

function CleanPairsSingle() {
  const [isModal, setModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentInvaildPair, setCurrentInvaildPair] = useState([]);
  const [voilationData, setVoilationData] = useState([]);
  const [similarPairs, setSimilarPairs] = useState([]);
  const [isSilmLoading, setSimLoading] = useState(true);
  const { userId } = useSelector((state) => state?.user?.userProfile);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndtime] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const [isTopChanged, setTopChanged] = useState(false);

  const [isSingleViewDisabled, setSingleViewDisabled] = useState(false);

  const [search, setSearch] = useState({
    siteCode: null,
    siteName: null,
    startDate: null,
    endDate: null,
    plate: null,
    // startTime: null,
    // endTime: null,
  });

  const debouncingSearch = UseDebounce(search, 500);

  function formatDate(inputDate) {
    const dateObject = new Date(inputDate);

    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
    const year = dateObject.getFullYear();

    // Pad day and month with leading zeros if necessary
    const formattedDay = String(day).padStart(2, "0");
    const formattedMonth = String(month).padStart(2, "0");
    if (inputDate) {
      return `${year}-${formattedMonth}-${formattedDay}`;
    } else {
      return null;
    }
  }

  function formatTime(inputDateTime) {
    const date = new Date(inputDateTime);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    if (inputDateTime) {
      return `${hours}:${minutes}:${seconds}`;
    } else {
      return null;
    }
  }
  useEffect(() => {
    setTopChanged(true);
    setCurrentPage(1);
    setSearch({
      ...search,
      startDate: formatDate(currentInvaildPair?.arrivalTime),
      endDate: formatDate(currentInvaildPair?.departureTime),
      // startTime: formatTime(currentInvaildPair?.arrivalTime),
      // endTime: formatTime(currentInvaildPair?.departureTime),

      plate: "",
      siteCode: null,
      siteName: null,
    });
    setStartTime(formatTime(currentInvaildPair?.arrivalTime));
    setEndtime(formatTime(currentInvaildPair?.departureTime));
    setTimeout(() => {
      setTopChanged(false);
    }, 300);
  }, [currentInvaildPair]);

  function formatAntdDatePickerDate(selectedDate) {
    return selectedDate.format("YYYY-MM-DD");
  }

  const handleSearch = (column, value) => {
    setCurrentPage(1);
    const data = { ...search };

    if (column === "startDate" || column === "endDate") {
      if (value) {
        const selectedDate = value;
        const formattedDate = formatAntdDatePickerDate(selectedDate);
        data[column] = formattedDate;
      } else {
        data[column] =
          column === "startDate"
            ? formatDate(currentInvaildPair?.arrivalTime)
            : formatDate(currentInvaildPair?.departureTime);
      }
    } else if (column === "startTime") {
      if (value) {
        setStartTime(value);
        // data[column] = value;
      } else {
        setStartTime(formatTime(currentInvaildPair?.arrivalTime));
      }
    } else if (column === "endTime") {
      if (value) {
        setEndtime(value);
      } else {
        setEndtime(formatTime(currentInvaildPair?.departureTime));
      }
    } else {
      data[column] = value;
    }

    setSearch(data);
  };

  function disableSingleView(plateNuber) {
    if (
      plateNuber === currentInvaildPair?.entryPlate ||
      currentInvaildPair?.exitPlate
    ) {
      setSingleViewDisabled(true);
    } else {
      setSingleViewDisabled(false);
    }
  }

  const submitPlate = (plateId, plateNumber) => {
    if (plateNumber.length > 0) {
      const queryParams = {
        CorrectdPlate: JSON.stringify({
          plateId: plateId,
          correctedplate: plateNumber,
          userid: userId,
          pairId: currentInvaildPair?.id,
          pairEntryPlate: currentInvaildPair?.entryPlate,
          pairExitPlate: currentInvaildPair?.exitPlate,
          pairsourceScreen: "CleanPair",
          category: "AllPair/FuzzyPair",
          sourceScreen: "Clean Pair",
        }),
      };
      axiosConfig
        .get(`cleanPaircorrectPlate/`, {
          params: queryParams,
        })
        .then((res) => {
          const { statusCode } = res.data;
          if (statusCode === 200) {
            notification.success({
              message: "Successfully Updated",
              duration: 2,
            });
            disableSingleView(plateNumber);
            fetchSilmilarPairs();
          } else {
            notification.error({
              message: "Something went Wrong!",
              duration: 2,
            });
          }
        })
        .catch((err) => {
          notification.error({
            message: "Something went Wrong!",
            duration: 2,
          });
          console.log(err);
        });
    } else {
      notification.error({
        message: "Plate Number is not added",
        duration: 2,
      });
    }
  };
  const handlePageSizeChange = (size) => {
    setPageSize(size);
    // Fetch data based on the new pageSize and reset the current page to 1
    setCurrentPage(1);
  };
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const fetchSilmilarPairs = () => {
    setSimLoading(true);
    const queryParams = {
      uncleanPairFuzzy: JSON.stringify({
        entryPlateid: currentInvaildPair?.entryPlateId,
        exitPlateid: currentInvaildPair?.exitPlateId,
        category: "Fuzzy",
        ArrivalTime: search?.startDate,
        departureTime: search.endDate,
        plate: search.plate === "" ? null : search?.plate,
        pagesize: pageSize,
        pagenumber: currentPage,
        startTime: startTime,
        endTime: endTime,

        userid: userId,
        pairSite: currentInvaildPair?.siteCode,
        pairEntryDate:
          formatDate(currentInvaildPair?.arrivalTime) +
          " " +
          formatTime(currentInvaildPair?.arrivalTime),
        pairExitDate:
          formatDate(currentInvaildPair?.departureTime) +
          " " +
          formatTime(currentInvaildPair?.departureTime),
        pairViolation: currentInvaildPair?.violationType,
      }),
    };
    axiosConfig
      .get(`GetUncleanSimilarpairs/`, {
        params: queryParams,
      })
      .then((res) => {
        const { statusCode, data, paginationData } = res.data;
        if (statusCode === 200) {
          setSimilarPairs(data);
          setPaginationData(paginationData);
        } else {
          setSimilarPairs([]);
          setPaginationData({});
          setCurrentPage(1);
        }
        setSimLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSimLoading(false);
        setSimilarPairs([]);
      });
  };
  useEffect(() => {
    currentInvaildPair?.entryPlateId &&
      search.startDate !== null &&
      fetchSilmilarPairs();
  }, [debouncingSearch, currentPage, pageSize]);

  useEffect(() => {
    if (!currentInvaildPair) {
      setSimLoading(false);
      setSimilarPairs([]);
    }
  }, [currentInvaildPair, isSilmLoading]);
  // console.log(currentInvaildPair,"invalid pair")
  const handleSelectChange = (event, id) => {
    const selectedValue = event;

    // Update the state to add the "reason" property to the selected object
    setVoilationData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, reason: selectedValue } : item
      )
    );
  };
  function convertToUpperCaseAndRemoveSpaces(inputString) {
    // Convert the string to uppercase
    let uppercasedString = inputString.toUpperCase();

    // Remove spaces from the string
    let stringWithoutSpaces = uppercasedString.replace(/\s/g, "");

    return stringWithoutSpaces;
  }

  const defaultDate = currentInvaildPair?.arrivalTime;
  const myDate = new Date(defaultDate);
  // const arrivalDate = formatAntdDatePickerDate(defaultDate)
  const formattedDate = myDate.toLocaleDateString().replace(/\//g, "-");

  const formattedTime = myDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  const myDate1 = new Date(currentInvaildPair?.departureTime);
  const formattedEndDate = myDate1.toLocaleDateString().replace(/\//g, "-");
  const formattedEndTime = myDate1.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return (
    <Container>
      {isModal && modalType === "correct_plate" && (
        <CorrectThePlate setModal={setModal} />
      )}
      <TopSection>
        <SingleCarDetails
          setSimLoading={setSimLoading}
          handleSelectChange={handleSelectChange}
          data={voilationData[currentIndex]}
          setModal={setModal}
          setModalType={setModalType}
          setCurrentInvaildPair={setCurrentInvaildPair}
          isSingleViewDisabled={isSingleViewDisabled}
          setSingleViewDisabled={setSingleViewDisabled}
        />
      </TopSection>
      <ListTop>
        <LeftSection>
          <Tab>
            <Menu className={"active"}>All Records</Menu>
          </Tab>
        </LeftSection>
        {!isTopChanged && (
          <RightSection>
            <Reload onClick={fetchSilmilarPairs}>
              <ReloadOutlined style={{ color: "#fff" }} /> Reload
            </Reload>
            <Search>
              <Icon>
                <img src={search__icon} alt="Search" />
              </Icon>
              <SearchPlateNumber
                style={{ fontFamily: "Roboto Mono" }}
                type="text"
                placeholder="Plate Number"
                value={search.plate}
                onChange={(e) =>
                  handleSearch(
                    "plate",
                    convertToUpperCaseAndRemoveSpaces(e.target.value)
                  )
                }
              />
            </Search>
            <Cover>
              <DatePicker
                placeholder={formatDatev2(currentInvaildPair?.arrivalTime)}
                format="DD-MM-YYYY"
                // value={moment(search.startDate)}
                // defaultValue={defaultStartDate}
                onChange={(e) => handleSearch("startDate", e)}
              />
            </Cover>
            <TimePicker
              placeholder={formattedTime}
              format={"HH:mm:ss"}
              onChange={(e) => handleSearch("startTime", formatTime(e))}
            />
            <Cover>
              <DatePicker
                placeholder={formatDatev2(currentInvaildPair?.departureTime)}
                format="DD-MM-YYYY"
                onChange={(e) => handleSearch("endDate", e)}
              />
            </Cover>
            <Cover>
              <TimePicker
                format={"HH:mm:ss"}
                placeholder={formattedEndTime}
                // defaultValue={defaultEndTime}
                onChange={(e) => handleSearch("endTime", formatTime(e))}
              />
            </Cover>
          </RightSection>
        )}
      </ListTop>
      <CarsList className="silmilarPlates">
        {Object.keys(currentInvaildPair).length === 0 ? (
          <NoData />
        ) : isSilmLoading ? (
          <Loader>
            <DataFetchIngLoader />
          </Loader>
        ) : similarPairs.length > 0 ? (
          similarPairs.map((data) => (
            <SimilarPlatesCard
              data={data}
              fetchSilmilarPairs={data}
              submitPlate={submitPlate}
              currentInvaildPair={currentInvaildPair}
            />
          ))
        ) : (
          <NoData />
        )}
      </CarsList>
      {Object.keys(currentInvaildPair).length !== 0 && (
        <BottomSection>
          <div>
            <span>Rows per page: </span>
            <Select
              defaultValue={pageSize}
              onChange={handlePageSizeChange}
              style={{ width: 80 }}
            >
              <Select.Option value={10}>10</Select.Option>
              <Select.Option value={20}>20</Select.Option>
              <Select.Option value={50}>50</Select.Option>
            </Select>
          </div>

          <Pagination
            total={paginationData?.count} // Total number of items in the data source
            currentPage={currentPage}
            pageSize={pageSize}
            onChange={handlePageChange}
            current={currentPage}
          />
        </BottomSection>
      )}
    </Container>
  );
}

export default CleanPairsSingle;
const Container = styled.div`
  padding: 20px;
  .ant-pagination-options {
    display: none;
  }
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
`;
const CarsList = styled.div`
  margin-top: 10px;
  max-height: calc(100vh - 510px);
  min-height: calc(100vh - 510px);
  overflow-y: scroll;
  padding: 20px;
  border: 1px solid #d0d1d2;
  border-radius: 5px;
  &.silmilarPlates {
    max-height: calc(100vh - 560px);
    min-height: calc(100vh - 560px);
  }
  &::-webkit-scrollbar {
    width: 12px; /* Set the width of the scrollbar */
    display: block !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d8d8d8; /* Set the color of the scrollbar thumb */
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Set the color of the scrollbar track */
  }
`;
const BottomSection = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :where(.css-dev-only-do-not-override-1vr7spz).ant-select-single:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    max-width: 100px;
  }
`;
const ListTop = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const RightSection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  :where(.css-dev-only-do-not-override-3mqfnx).ant-picker {
    padding: 0 11px;
  }
`;
const Cover = styled.div`
  border-radius: 5px;
  .ant-picker {
  }
  /* border: 1px solid #d0d1d2; */
`;
const Search = styled.div`
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  width: 150px;
  padding: 0 10px;
  input {
    width: 100%;
    padding: 3px 10px;
  }
`;
const Icon = styled.span`
  display: block;
  width: 20px;
  img {
    display: block;
    width: 100%;
  }
`;
const LeftSection = styled.div``;
const Tab = styled.div`
  width: 300px;
  height: 30px;
  background: #dce6f6;
  border: 2px solid #dce6f6;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 2px;
`;
const Menu = styled.span`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  font-size: 13px;
  cursor: pointer;
  color: #919599;
  font-weight: 500;
  &.active {
    background-color: #fff;
    height: 100%;
    border-radius: 15px;
    color: #3d619b;
  }
`;
const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: calc(100vh - 580px);
`;
const Reload = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 15px;
  background-color: #3d619b;
  cursor: pointer;
  gap: 5px;
  color: #fff !important;
  border-radius: 5px;
`;
const SearchPlateNumber = styled.input`
  font-family: "Roboto Mono";
  &::placeholder {
    font-family: "Inter", sans-serif;
  }
`;
