export function formatDate(inputDate) {
  // Check if inputDate is a valid Date object
  if (!(inputDate instanceof Date) || isNaN(inputDate)) {
    return "Invalid Date";
  }

  // Define arrays for month names and day names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Extract day, month, and year from the inputDate
  const day = inputDate.getDate();
  const monthIndex = inputDate.getMonth();
  const year = inputDate.getFullYear();

  // Format the date string
  const formattedDate = `${day} ${monthNames[monthIndex]} ${year}`;

  return formattedDate;
}
export function formatDatev2(inputDate) {
  const dateObject = new Date(inputDate);

  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
  const year = dateObject.getFullYear();

  // Pad day and month with leading zeros if necessary
  const formattedDay = String(day).padStart(2, "0");
  const formattedMonth = String(month).padStart(2, "0");
  if (inputDate) {
    return `${formattedDay}-${formattedMonth}-${year}`;
  } else {
    return null;
  }
}
export function formatTime(inputDateTime) {
  const date = new Date(inputDateTime);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
}

//-------| Time Format only HH:SS |-------//
export function formatTimeHhSs(inputDateTime) {
  const date = new Date(inputDateTime);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  if (inputDateTime) {
    return `${hours}:${minutes}`;
  } else {
    return null;
  }
}

export function convertISODateFormat(inputDate) {
  const dateParts = inputDate.split("-");

  // Ensure there are three parts (day, month, year)
  if (dateParts.length !== 3) {
    return "Invalid date format";
  }

  // Reorder the date parts in the desired format
  const newDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
  return newDate + "T00:00:00";
}
export const Truncate = (text, maxLength) => {
  if (text?.length <= maxLength) {
    return <span>{text}</span>;
  }

  const truncatedText = `${text?.slice(0, maxLength)}...`;

  return <span title={text}>{truncatedText}</span>;
};
