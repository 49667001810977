import { styled } from "styled-components";
import { authProvider } from "./authProvider";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import "./assets/css/app.css";
import AppRouter from "./components/routers/AppRouter";
import { useEffect, useState } from "react";
import axiosConfig from "./axiosConfig";
import { useDispatch } from "react-redux";
import { updateUserProfile } from "./components/redux/reducers/UserReducer";
import InvalidUser from "./components/general/InvalidUser";

function App() {
	const [isLoading, setLoading] = useState(true);
	const user = JSON.parse(sessionStorage.getItem("user"));
	const dispatch = useDispatch();
	const [isInvalidUser, setInvalidUser] = useState(false);
	const fetchUser = () => {
		const queryParams = {
			UserDetailsInput: JSON.stringify({
				emailId: user?.account?.userName,
				accessToken: "",
			}),
		};
		axiosConfig
			.get(`GetUserDetails/`, {
				params: queryParams,
			})
			.then((res) => {
				const { statusCode, data } = res.data;
				if (statusCode === 200) {
					dispatch(
						updateUserProfile({
							userRole: data[0].userRole,
							userId: data[0].userId,
						})
					);
					setInvalidUser(false);
				} else {
					setInvalidUser(true);
				}
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				setInvalidUser(true);
			});
	};

	useEffect(() => {
		fetchUser();
	}, []);
	return (
		<Container className="App">
			{isInvalidUser ? <InvalidUser /> : <AppRouter />}
		</Container>
	);
}

export default App;
const Container = styled.div`
	background: #dce6f6;
	height: 100vh;
	padding: 15px;
`;
