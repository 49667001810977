import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import axiosConfig from "../../../../axiosConfig";
import { formatDatev2, formatTime } from "../../../../utils/HelperFunctions";
import { Tooltip, notification } from "antd";
import { COLORS } from "../../../../Constants";
import delete_icon from "../../../../assets/images/delete 2.svg";
import save from "../../../../assets/images/save.svg";
import { useSelector } from "react-redux";

function FuzzyPlatesCard({ currentInvaildPair, fuzzyPairs, fetchFuzzyPairs }) {
  const [EntryPlate, setEntryPlate] = useState("");
  const [ExitPlate, setExitPlate] = useState("");
  const { userId } = useSelector((state) => state?.user?.userProfile);

  useEffect(() => {
    setEntryPlate(fuzzyPairs?.entryPlate);
    setExitPlate(fuzzyPairs?.exitPlate);
  }, [fuzzyPairs]);

  const submitPlate = (plateId, motion, plateNumber) => {
    const queryParams = {
      CorrectdPlate: JSON.stringify({
        id: "1144446",
        correctedPlate: plateNumber,
        plateId: plateId,
        carEntryExit: motion,
        category: fuzzyPairs.violation_type,
        userid: userId,
      }),
    };
    axiosConfig
      .get(`UncleanPairCorrectPlate`, {
        params: queryParams,
      })
      .then((res) => {
        const { statusCode } = res.data;
        if (statusCode === 200) {
          notification.success({
            message: "Successfully Updated",
            duration: 2,
          });
          setEntryPlate("");
          setExitPlate("");
          fetchFuzzyPairs();
        } else {
          notification.error({
            message: "Something went Wrong!",
            duration: 2,
          });
        }
      })
      .catch((err) => {
        notification.error({
          message: "Something went Wrong!",
          duration: 2,
        });
        console.log(err);
      });
  };
  const handleSubmitPlate = () => {
    if (EntryPlate !== fuzzyPairs?.entryPlate) {
      submitPlate(fuzzyPairs?.entryPlateid, "Entry", EntryPlate);
    }

    if (ExitPlate !== fuzzyPairs?.entryPlate) {
      submitPlate(fuzzyPairs?.exitPlateid, "Exit", ExitPlate);
    }
  };
  return (
    <Container>
      <Cover>
        <DetailsContainer>
          <SectionCover>
            <Entry>
              <Tooltip
                placement="right"
                overlayInnerStyle={{ width: "820px" }}
                title={
                  <img
                    src={fuzzyPairs.entryFullImage}
                    alt=""
                    style={{
                      maxWidth: "800px",
                      width: "800px",
                    }}
                  />
                }
                color={"black"}
              >
                <CarImage href={fuzzyPairs.entryFullImage} target="_blank">
                  <img src={fuzzyPairs.entryFullImage} alt="Entry Img" />
                </CarImage>
              </Tooltip>
              <EntryDetails>
                <Tooltip
                  overlayInnerStyle={{ width: "320px" }}
                  title={
                    <img
                      src={fuzzyPairs.entrycroppedImage}
                      alt=""
                      style={{
                        maxWidth: "300px",
                        width: 300,
                      }}
                    />
                  }
                  color={"black"}
                >
                  <NumberPlate
                    href={fuzzyPairs.entrycroppedImage}
                    target="_blank"
                  >
                    <img src={fuzzyPairs.entrycroppedImage} alt="" />
                  </NumberPlate>
                </Tooltip>
                <Feilds>
                  <FeildName>Plate No:</FeildName>
                  <FeildData
                    style={{
                      fontFamily: "Roboto Mono",
                      fontWeight: 600,
                      fontSize: "16px",
                    }}
                  >
                    {fuzzyPairs?.entryPlate}
                  </FeildData>
                </Feilds>{" "}
                <Feilds>
                  <FeildName>AI Correction:</FeildName>
                  <FeildData>
                    {fuzzyPairs.entryAIcorrected === "True" ? "Yes" : "No"}
                  </FeildData>
                </Feilds>
                <Feilds>
                  <FeildName>Before AI:</FeildName>
                  <FeildData
                    style={{
                      fontFamily: "Roboto Mono",
                      fontWeight: 600,
                      fontSize: "16px",
                    }}
                  >
                    {fuzzyPairs.entry_pi_plate_before_ai}
                  </FeildData>
                </Feilds>
                <Feilds>
                  <FeildName>Entry Date:</FeildName>
                  <FeildData>{formatDatev2(fuzzyPairs.entryDate)}</FeildData>
                </Feilds>
                <Feilds>
                  <FeildName>Entry Time:</FeildName>
                  <FeildData>{formatTime(fuzzyPairs.entryDate)}</FeildData>
                </Feilds>
                <Feilds>
                  <FeildName>Motion:</FeildName>
                  <FeildData>{fuzzyPairs.entryMotion}</FeildData>
                </Feilds>
              </EntryDetails>
            </Entry>
            <CorrectThePlateSection>
              Correct the plate{" "}
              <input
                type="text"
                tabIndex="1"
                value={EntryPlate}
                onChange={(e) => setEntryPlate(e.target.value)}
              />
            </CorrectThePlateSection>
          </SectionCover>

          <SectionCover>
            <Exit>
              <Tooltip
                overlayInnerStyle={{ width: "820px" }}
                placement="right"
                title={
                  <img
                    src={fuzzyPairs.exitFullImage}
                    alt=""
                    style={{
                      maxWidth: "800px",
                      width: "800px",
                    }}
                  />
                }
                color={"black"}
              >
                <CarImage href={fuzzyPairs.exitFullImage} target="_blank">
                  <img src={fuzzyPairs.exitFullImage} alt="Entry Img" />
                </CarImage>
              </Tooltip>
              <EntryDetails>
                <Tooltip
                  title={
                    <img
                      src={fuzzyPairs.exitcroppedImage}
                      alt=""
                      style={{ maxWidth: "300px" }}
                    />
                  }
                  color={"black"}
                >
                  <NumberPlate
                    href={fuzzyPairs.exitcroppedImage}
                    target="_blank"
                  >
                    <img src={fuzzyPairs.exitcroppedImage} alt="" />
                  </NumberPlate>
                </Tooltip>
                <Feilds>
                  <FeildName>Plate No:</FeildName>
                  <FeildData
                    style={{
                      fontFamily: "Roboto Mono",
                      fontWeight: 600,
                      fontSize: "18px",
                    }}
                  >
                    {fuzzyPairs?.exitPlate}
                  </FeildData>
                </Feilds>
                <Feilds>
                  <FeildName>AI Correction:</FeildName>
                  <FeildData>
                    {fuzzyPairs.exitAIcorrected === "True" ? "Yes" : "No"}
                  </FeildData>
                </Feilds>
                <Feilds>
                  <FeildName>Before AI:</FeildName>
                  <FeildData
                    style={{
                      fontFamily: "Roboto Mono",
                      fontWeight: 600,
                      fontSize: "16px",
                    }}
                  >
                    {fuzzyPairs.exit_pi_plate_before_ai}
                  </FeildData>
                </Feilds>
                <Feilds>
                  <FeildName>Exit Date:</FeildName>
                  <FeildData>{formatDatev2(fuzzyPairs.exitDate)}</FeildData>
                </Feilds>
                <Feilds>
                  <FeildName>Exit Time:</FeildName>
                  <FeildData>{formatTime(fuzzyPairs.exitDate)}</FeildData>
                </Feilds>
                <Feilds>
                  <FeildName>Motion:</FeildName>
                  <FeildData>{fuzzyPairs.exitMotion}</FeildData>
                </Feilds>
              </EntryDetails>
            </Exit>
            <CorrectThePlateSection>
              Correct the plate{" "}
              <input
                type="text"
                tabIndex="1"
                value={ExitPlate}
                onChange={(e) => setExitPlate(e.target.value)}
              />
              {/* <OptionButton>
								<img src={delete_icon} alt="" />
							</OptionButton>
							<OptionButton
								onClick={() =>
									submitPlate(
										fuzzyPairs.exitPlateid,
										ExitPlate
									)
								}
							>
								<img src={save} alt="" />
							</OptionButton> */}
            </CorrectThePlateSection>
          </SectionCover>

          <VehicleDetails>
            <SectionTitle>Vehicle Details</SectionTitle>
            <Feilds>
              <FeildName className="veh_details">Make:</FeildName>
              <FeildData>{fuzzyPairs.entry_make}</FeildData>
            </Feilds>
            <Feilds>
              <FeildName className="veh_details">Model:</FeildName>
              <FeildData>{fuzzyPairs.entry_model}</FeildData>
            </Feilds>
            <Feilds>
              <FeildName className="veh_details">Colour:</FeildName>
              <FeildData>{fuzzyPairs.entry_color}</FeildData>
            </Feilds>
            <Feilds>
              <FeildName className="veh_details">Carweb:</FeildName>
              <FeildData>
                {fuzzyPairs.category === "AI Corrected/Fuzzy"
                  ? "Vaild"
                  : "Invaild"}
              </FeildData>
            </Feilds>

            <ButtonSection>
              <SaveButton onClick={handleSubmitPlate}>Update Plate</SaveButton>
            </ButtonSection>
          </VehicleDetails>

          <ViolationDetails>
            <SectionTitle>Violation Details</SectionTitle>
            <Feilds>
              <FeildName className="voi_details">
                Site Violation Time:
              </FeildName>
              <FeildData>{fuzzyPairs.violationTime} mins</FeildData>
            </Feilds>
            <Feilds>
              <FeildName className="voi_details">Stay Duration:</FeildName>
              <FeildData>{fuzzyPairs.duration} mins</FeildData>
            </Feilds>
            <Feilds>
              <FeildName className="voi_details">Violation Type:</FeildName>
              <FeildData style={{ fontSize: "16px" }}>
                {fuzzyPairs.violation_type}
              </FeildData>
            </Feilds>
            <Feilds>
              <FeildName className="voi_details">Match Percentage:</FeildName>
              <FeildData style={{ fontSize: "16px" }}>
                {fuzzyPairs.fuzzymatch_percentage.toFixed(2)}%
              </FeildData>
            </Feilds>
          </ViolationDetails>
        </DetailsContainer>
      </Cover>
    </Container>
  );
}

export default FuzzyPlatesCard;
const Container = styled.div`
  width: 100%;
`;

const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  width: 100%;
`;

const Entry = styled.div`
  display: flex;
  gap: 10px;
  padding: 0 10px;
  position: relative;
  border-right: 1px solid #d0d1d2;
  flex: 1;
`;

const Exit = styled.div`
  display: flex;
  gap: 10px;
  border-right: 1px solid #d0d1d2;
  padding: 0 10px;
  flex: 1;
`;

const VehicleDetails = styled.div`
  border-right: 1px solid #d0d1d2;
  padding: 0 10px;
  min-height: 170px;
  flex: 1;
  position: relative;
`;

const ViolationDetails = styled.div`
  padding: 0 10px;
  min-height: 170px;
  flex: 1;
`;

const CarImage = styled.a`
  display: block;
  width: 150px;
  min-width: 150px;
  height: 120px;
  border-radius: 8px;
  overflow: hidden;
  img {
    display: block;
    /* object-fit: cover; */
    width: 100%;
    height: 120px;
    object-fit: cover;
  }
`;

const EntryDetails = styled.div``;

const NumberPlate = styled.a`
  height: 40px;
  width: 200px;
  display: block;
  img {
    display: block;
    height: 100%;
    width: 100%;
  }
  margin-bottom: 5px;
`;

const Feilds = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;

const FeildName = styled.p`
  font-size: 13px;
  color: #6a6a6a;
  width: 90px;
  &.veh_details {
    width: 60px !important;
  }
  &.voi_details {
    width: 130px;
  }
`;

const FeildData = styled.p`
  font-size: 12px;
  color: #6a6a6a;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #000;
  font-weight: 500;
  max-width: 150px;
  text-transform: capitalize;
`;

const SectionTitle = styled.p`
  font-size: 14px;
  color: #3d619b;
  margin-bottom: 15px;
`;

const Cover = styled.div`
  border: 1px solid #d0d1d2;
  border-radius: 5px;
  padding: 10px;
`;

const SectionCover = styled.div`
  flex: 1;
`;

const CorrectThePlateSection = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 500;
  padding: 0 10px;
  gap: 10px;
  margin-top: 10px;
  input {
    border: 1px solid #d9d9d9;
    /* width: 150px; */
    margin-left: 10px;
    padding: 5px 10px;
    flex: 1;
  }
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  position: absolute;
  bottom: 0;
`;

const SaveButton = styled.div`
  height: 30px;
  border: 1px solid #3d619b;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3d619b;
  color: #fff;
  font-size: 13px;
  border-radius: 5px;
  width: 120px;
  cursor: pointer;
  transition: 0.3s;
  margin-top: 4px;
  &:hover {
    background-color: #fff;
    color: #3d619b;
  }
`;
