import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import animationData from "../../assets/lotties/user_not_found.json";
import AzureAD from "react-aad-msal";
import { authProvider } from "../../authProvider";

function InvalidUser() {
	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};
	return (
		<Container>
			{" "}
			<Lottie options={defaultOptions} width={200} height={200} />
			<Title>Sorry you do not seem to have access to this app.</Title>
			<p>Please contact your manager.</p>
			<AzureAD provider={authProvider}>
				{({ logout }) => {
					return <Button onClick={logout}>Logout</Button>;
				}}
			</AzureAD>
		</Container>
	);
}

export default InvalidUser;
const Container = styled.div`
	background-color: #fff;
	margin: -15px;
	height: 100vh;
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const Title = styled.p`
	font-size: 26px;
	font-weight: 500;
`;
const Button = styled.span`
	display: flex;
	width: 150px;
	height: 40px;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	background: #3d619b;
	color: #fff;
	margin-top: 20px;
	cursor: pointer;
`;
