import React, { useState } from 'react';
import { Modal, Button } from 'antd';

const DeleteUserModal = ({ visible, onYes, onNo, onCancel }) => {
  return (
    <Modal
      title="Delete User"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onNo}>
          No
        </Button>,
        <Button key="submit" type="primary" onClick={onYes}>
          Yes
        </Button>,
      ]}
    >
      <p>Are you sure you want to delete this user?</p>
    </Modal>
  );
};

export default DeleteUserModal;