import { createSlice } from "@reduxjs/toolkit";

const INITAIL_STATE = {
  userData: localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : {
        name: "",
        phone: "",
        email: "",
      },

  userProfile: {},
  proccessData: {
    processed: 0,
    processing: 0,
  },
  starTraqDetails: {},
  siteConfigRowData: {},
  sitePerfomanceRowData: {},
};

const userSlice = createSlice({
  name: "user",
  initialState: INITAIL_STATE,
  reducers: {
    updateUserData: (state, action) => {
      state.userData = { ...state.userData, ...action.payload };
      localStorage.setItem("userData", JSON.stringify(state.userData));
    },
    updateUserProfile: (state, action) => {
      state.userProfile = { ...state.userProfile, ...action.payload };
    },
    updateDataProcessed: (state, action) => {
      state.proccessData = { ...state.proccessData, ...action.payload };
    },

    setStarTraqData: (state, action) => {
      state.starTraqDetails = action.payload;
    },

    setSiteConfigRowData: (state, action) => {
      state.siteConfigRowData = action.payload;
    },

    setSitePerfomanceRowData: (state, action) => {
      state.sitePerfomanceRowData = action.payload;
    },
  },
});

export const {
  updateUserData,
  updateUserProfile,
  updateDataProcessed,
  setStarTraqData,
  setSiteConfigRowData,
  setSitePerfomanceRowData,
} = userSlice.actions;
export default userSlice.reducer;
