import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import animationData from "../../assets/lotties/404.json";

function PageNotFound() {
	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};
	return (
		<Container>
			<Lottie options={defaultOptions} width={500} height={500} />
		</Container>
	);
}

export default PageNotFound;
const Container = styled.div`
	height: calc(100vh - 100px);
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
`;
