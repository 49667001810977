import React, { lazy, Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { styled } from "styled-components";
import CleanPairs from "../screens/CleanPairs";

import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";

import UserMapping from "../screens/user-mapping/UserMapping";
import DVLAPairs from "../screens/DVLAPairs";
import PageNotFound from "../general/PageNotFound";
import CleanPairsSingle from "../screens/CleanPairsSingle";
import SitePerformance from "../screens/SitePerformance";
import OvernightPairs from "../screens/OvernightPairs/OvernightPairs";
import InventedPairs from "../screens/InventedPairs/InvientedPairs";
import AnalysisReporting from "../screens/AnalysisReporting";
import SitePerformance2 from "../screens/SitePerformance2";
import CctvTableDetails from "../screens/Cctv/CctvTableDetails";
import StarTraqMain from "../screens/StarTraq/StarTraqMain";
import ProcessStatusTable from "../screens/azureDataTable/ProcessStatusTable";
import PendingProcess from "../screens/azureDataTable/PendingProcess";

const Home = lazy(() => import("../screens/Home"));
const SideBar = lazy(() => import("../general/SideBar"));
const Header = lazy(() => import("../general/Header"));
const SiteConfigration = lazy(() =>
  import("../screens/site-configration/SiteConfigration")
);
const SiteSingleView = lazy(() =>
  import("../screens/site-configration/SiteSingleView")
);
const UnCleanPairs = lazy(() => import("../screens/UnCleanPairs"));
const ApprovedPairs = lazy(() => import("../screens/ApprovedPairs"));
const RejectedPairs = lazy(() => import("../screens/RejectedPairs"));
const ReviewPage = lazy(() => import("../screens/ReviewPage/ReviewPage"));

// const userGroup = getUserGroups();

function AppRouter() {
  const location = useLocation();
  const currentPage = location.pathname;
  const [isLoading, setLoading] = useState(false);

  const { userRole } = useSelector((state) => state?.user.userProfile);

  const pathOne = "/polus-process-status";
  const pathTwo = "/pending-to-be-processed";

  return (
    <Container>
      {/* {currentPage !== (pathOne || pathTwo) && <SideBar />} */}
      {currentPage !== pathOne && currentPage !== pathTwo && <SideBar />}

      <Cover>
        {currentPage !== pathOne && currentPage !== pathTwo && <Header />}

        {isLoading ? (
          <LoaderCover>
            <Spin />
          </LoaderCover>
        ) : (
          <Suspense
            fallback={
              <LoaderCover>
                <Spin />
              </LoaderCover>
            }
          >
            {userRole === "Manager" ? (
              <Routes>
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/home" element={<Home />} />
                <Route path="/user-mapping" element={<UserMapping />} />
                <Route
                  path="/site-configuration"
                  element={<SiteConfigration />}
                />
                <Route
                  path="/site-configuration/edit/:siteCode"
                  element={<SiteSingleView />}
                />
                <Route path="/clean-pairs" element={<CleanPairsSingle />} />
                <Route path="/potential-pairs" element={<UnCleanPairs />} />
                <Route path="/invented-pairs" element={<InventedPairs />} />
                <Route path="/overnight-pairs" element={<OvernightPairs />} />
                {/* <Route
									path="/approved-pairs"
									element={<ApprovedPairs />}
								/> */}
                <Route path="/rejected-pairs" element={<RejectedPairs />} />
                <Route path="/reviewer" element={<ReviewPage />} />
                {/* <Route
									path="/site-performance"
									element={<SitePerformance />}
								/> */}
                <Route
                  path="/analysis-reporting"
                  element={<AnalysisReporting />}
                />
                <Route
                  path="/site-performance2"
                  element={<SitePerformance2 />}
                />
                <Route path="/dvla" element={<DVLAPairs />} />
                <Route path="/cctv" element={<CctvTableDetails />} />
                <Route path="/starTraq" element={<StarTraqMain />} />
                <Route
                  path="/polus-process-status"
                  element={<ProcessStatusTable />}
                />
                <Route
                  path="/pending-to-be-processed"
                  element={<PendingProcess />}
                />

                <Route path="*" element={<PageNotFound />} />
              </Routes>
            ) : (
              <Routes>
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/home" element={<Home />} />

                <Route
                  path="/site-configuration/edit/:siteCode"
                  element={<SiteSingleView />}
                />
                <Route path="/clean-pairs" element={<CleanPairsSingle />} />
                <Route path="/potential-pairs" element={<UnCleanPairs />} />
                <Route path="/invented-pairs" element={<InventedPairs />} />
                <Route path="/overnight-pairs" element={<OvernightPairs />} />
                {/* <Route
									path="/approved-pairs"
									element={<ApprovedPairs />}
								/> */}
                <Route path="/rejected-pairs" element={<RejectedPairs />} />
                <Route path="/reviewer" element={<ReviewPage />} />
                <Route
                  path="/analysis-reporting"
                  element={<AnalysisReporting />}
                />
                {/* <Route path="*" element={<PageNotFound />} /> */}
                <Route path="/cctv" element={<CctvTableDetails />} />
                <Route path="/starTraq" element={<StarTraqMain />} />
                <Route
                  path="/polus-process-status"
                  element={<ProcessStatusTable />}
                />
                <Route
                  path="/pending-to-be-processed"
                  element={<PendingProcess />}
                />
              </Routes>
            )}
          </Suspense>
        )}
      </Cover>
    </Container>
  );
}

export default AppRouter;
const Container = styled.div`
  display: flex;
  gap: 10px;
`;
const Cover = styled.div`
  width: 100%;
  height: calc(100vh - 30px);
  background-color: #fff;
  border-radius: 10px;
  flex: 1;
  overflow: scroll;
`;
const LoaderCover = styled.div`
  height: calc(100vh - 30px);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
