import React, { useState, useEffect, useRef } from "react";
import { css, styled } from "styled-components";
import Select from "react-select";
import moment from "moment";
import Lottie from "react-lottie";

//-------| Antd |-------//
import { DatePicker, TimePicker } from "antd";
import { Table } from "antd";
import { Tooltip, Pagination, Select as ANTDSelect } from "antd";

//-------| Images/Icons |-------//
import Placeholder_image from "../../../assets/images/image_placeholder.gif";
import animationData from "../../../assets/lotties/animation_lnmzdp1c.json";

//-------| Components Used |-------//
import { COLORS } from "../../../../src/Constants";
import axiosConfig from "../../../axiosConfig";
import { useSelector } from "react-redux";
import NoData from "../../general/NoData";
import DataFetchIngLoader from "../../general/DataFetchIngLoader";
import ImageMagnifier from "../../general/ImageMagnifier";

function CctvTableDetails() {
  const inputRef = useRef(null);
  const [paginationData, setPaginationData] = useState({});
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [imageLoading, setImageLoading] = useState(true);
  const [tableInputClicked, setTableInputClicked] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState("");
  const [tableInputData, setTableInputData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [siteCodes, setSiteCodes] = useState([]);
  const [showValue, setShowValue] = useState("New");
  const [startDatee, setStartDatee] = useState("");
  const [endDatee, setEndDatee] = useState("");
  const [cctvTableData, setCctvTableData] = useState([]);
  const [cctvDataFromUi, setCctvDataFromUi] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [plateNo, setPlateNo] = useState(null);
  const [search, setSearch] = useState({
    siteCode: null,
    startDate: null,
    endDate: null,
    startTime: "00:00:00",
    endTime: "23:59:59",
  });
  const [flag, setFlag] = useState("");
  const [disabledSite, setDisabledSite] = useState("");

  const { userId } = useSelector((state) => state?.user.userProfile);

  //-------| "Show" Dropdown Data |-------//
  const showValueData = [
    {
      value: "All",
      label: "All",
    },

    {
      value: "New",
      label: "New",
    },

    {
      value: "Ready",
      label: "Ready",
    },
  ];

  //-------| Site Code Value Get Api |-------//
  const fetchSitecodes = () => {
    const queryParams = {
      siteInput: JSON.stringify({
        sourceScreen: "CCTV",
      }),
    };

    axiosConfig
      .get(`cleanPairsGetSiteConfigforUI`, {
        params: queryParams,
      })

      .then((res) => {
        const { statusCode, data } = res.data;

        if (statusCode === 200) {
          const formattedOptions = data.map((item) => ({
            value: item.site,
            label: item.site,
          }));
          setSiteCodes(formattedOptions);
        } else {
        }
      })

      .catch((err) => {
        console.error("Error in Site Code Api", err);
      });
  };

  //-------| CCTV Table Details Get Api |-------//
  const fetchCctvData = () => {
    setLoading(true);
    const mySiteCode = search?.siteCode?.value
      ? search?.siteCode?.value
      : search?.siteCode;

    const queryParams = {
      CCTVDataInput: JSON.stringify({
        Site: mySiteCode,
        StartDate: search.startDate,
        EndDate: search.endDate,
        StartTime: search.startTime || "00:00:00",
        EndTime: search.endTime || "23:59:59",
        Status: showValue,
        plate: plateNo?.toLowerCase(),
        Pagenumber: currentPage,
        Pagesize: pageCount,
      }),
    };

    axiosConfig
      .get(`GetCCTVDataForUI`, {
        params: queryParams,
      })

      .then((res) => {
        const { statusCode, data, paginationData, isEnabled, site } = res?.data;
        setFlag(isEnabled);
        setDisabledSite(site);

        if (statusCode === 200) {
          setCctvTableData(data);
          setPaginationData(paginationData);
        } else {
          setCctvTableData([]);
          setPaginationData({});
        }

        setLoading(false);
      })

      .catch((err) => {
        console.error("Error in CCTV Table Details Api", err);
        setLoading(false);
      });
  };

  //-------| Remove the object Manually before Api call |-------//
  const removeTheData = (id) => {
    setCctvTableData(cctvTableData.filter((itm) => itm.id !== id));
  };

  //-------| CCTV Table Submit Btn Get Api |-------//
  const fetchSubmitBtnApi = (record, string) => {
    setLoading(true);

    const { id, image1, image2, image3 } = record;

    let numPlate = "";

    for (let value in tableInputData) {
      if (id === parseInt(value)) {
        numPlate = tableInputData[value].textValue.toUpperCase();
      }
    }

    const queryParams = {
      CCTVDataInput: JSON.stringify({
        Id: id,
        ApproverId: userId,
        TranscribedPlate: numPlate,
        Status: string, // Approved or Rejected
        Image1: image1,
        Image2: image2,
        Image3: image3,
      }),
    };

    axiosConfig
      .get(`InsertCCTVDataFromUI`, {
        params: queryParams,
      })

      .then((res) => {
        const { statusCode, data, paginationData } = res.data;

        if (statusCode === 200) {
          setCctvDataFromUi(data);
          removeTheData(id);
          fetchCctvData();
        } else {
          setCctvDataFromUi([]);
        }

        setLoading(false);
      })

      .catch((err) => {
        console.error("Error in CCTV Submit Btn Api", err);
      });
  };

  //-------| Format the Time in "HH:mm:ss" |-------//
  function formatTime(inputDateTime) {
    if (!inputDateTime) {
      return null;
    }
    return inputDateTime.format("HH:mm:ss");
  }

  function formatAntdDatePickerDate(selectedDate) {
    return selectedDate.format("YYYY-MM-DD");
  }

  const handleSearch = (column, value) => {
    const data = { ...search };

    if (column === "startDate" || column === "endDate") {
      if (value) {
        const selectedDate = value;
        const formattedDate = formatAntdDatePickerDate(selectedDate);
        data[column] = formattedDate;
      } else {
        data[column] = null;
      }
    } else {
      data[column] = value;
    }

    setSearch(data);
  };

  //-------| Start Date Filter |-------//
  const handleStartDate = (s, e) => {
    if (e) {
      const formattedMonth = String(e.$M + 1).padStart(2, "0");
      const formattedDay = String(e.$D).padStart(2, "0");

      const xx = `${e.$y}-${formattedMonth}-${formattedDay}`;
      setSearch({
        ...search,
        startDate: xx,
      });

      setStartDatee(e);
    } else {
      setSearch({
        ...search,
        startDate: null,
      });

      setStartDatee(null);
    }
  };

  //-------| End Date Filter |-------//
  const handleEndDate = (s, e) => {
    if (e) {
      const formattedMonth = String(e.$M + 1).padStart(2, "0");
      const formattedDay = String(e.$D).padStart(2, "0");

      const xx = `${e.$y}-${formattedMonth}-${formattedDay}`;

      setSearch({
        ...search,
        endDate: xx,
      });

      setEndDatee(e);
    } else {
      setSearch({
        ...search,
        endDate: null,
      });

      setEndDatee(null);
    }
  };

  useEffect(() => {
    fetchSitecodes();
  }, []);

  useEffect(() => {
    fetchCctvData();
  }, [pageCount, currentPage]);

  //-------| "Show" Dropdown Functionality |-------//
  const handleShowChange = (selectedOption) => {
    const selectedValue =
      selectedOption && selectedOption.value !== "All"
        ? selectedOption.value
        : null;
    setShowValue(selectedValue);
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageCount(size);
    setCurrentPage(1);
  };

  //-------| Formated Date & Time for (Arrival Time &	Departure Time) |-------//
  const renderDateTime = (text) => {
    const dateTime = new Date(text);

    const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

    const formattedDate = `${addLeadingZero(
      dateTime.getDate()
    )}-${addLeadingZero(dateTime.getMonth() + 1)}-${dateTime.getFullYear()}`;

    const formattedTime = dateTime.toLocaleTimeString("en-US", {
      hour12: false,
    });

    return (
      <>
        <div>{formattedDate}</div>
        <div>{formattedTime}</div>
      </>
    );
  };

  const columns = [
    {
      title: "Site",
      dataIndex: "site",
      key: "site",
      width: "15px",
      render: (data) => (
        <h3
          style={{
            fontFamily: "Roboto Mono",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          {data}
        </h3>
      ),
    },

    {
      title: "Image 1",
      dataIndex: "image1",
      key: "image1",
      width: "10px",
      render: (data) => (
        <Tooltip
          color={"black"}
          overlayInnerStyle={{ width: "820px" }}
          placement="right"
          title={
            <a href={data} target="_blank">
              {/* <img
                src={data}
                alt=""
                style={{ maxWidth: "800px", width: "800px" }}
              /> */}
              <ImageMagnifier src={data} width={"800px"} />
            </a>
          }
        >
          <a
            href={data}
            // style={{ width: "200px", display: "block" }}
            target="_blank"
          >
            <TableImg
              src={imageLoading ? Placeholder_image : data}
              alt="Image 1"
              onLoad={() => setImageLoading(false)}
            />
          </a>
        </Tooltip>
      ),
    },

    {
      title: "Image 2",
      dataIndex: "image2",
      key: "image2",
      width: "10px",
      render: (data) => (
        <Tooltip
          color={"black"}
          overlayInnerStyle={{ width: "820px" }}
          placement="right"
          title={
            <a href={data} target="_blank">
              {/* <img
                src={data}
                alt=""
                style={{ maxWidth: "800px", width: "800px" }}
              /> */}
              <ImageMagnifier src={data} width={"800px"} />
            </a>
          }
        >
          <a
            href={data}
            // style={{ width: "200px", display: "block" }}
            target="_blank"
          >
            <TableImg
              src={imageLoading ? Placeholder_image : data}
              alt="Image 1"
              onLoad={() => setImageLoading(false)}
            />
          </a>
        </Tooltip>
      ),
    },

    {
      title: "Image 3",
      dataIndex: "image3",
      key: "image3",
      width: "10px",
      render: (data) => (
        <Tooltip
          color={"black"}
          overlayInnerStyle={{ width: "820px" }}
          placement="right"
          title={
            <a href={data} target="_blank">
              {/* <img
                src={data}
                alt=""
                style={{ maxWidth: "800px", width: "800px" }}
              /> */}
              <ImageMagnifier src={data} width={"800px"} />
            </a>
          }
        >
          <a
            href={data}
            // style={{ width: "200px", display: "block" }}
            target="_blank"
          >
            <TableImg
              src={imageLoading ? Placeholder_image : data}
              alt="Image 1"
              onLoad={() => setImageLoading(false)}
            />
          </a>
        </Tooltip>
      ),
    },

    {
      title: "Arrival Time",
      dataIndex: "arrivalTime",
      key: "arrivalTime",
      width: "10%",
      render: renderDateTime,
    },

    {
      title: "Departure Time",
      dataIndex: "departureTime",
      key: "departureTime",
      width: "10%",
      render: renderDateTime,
    },

    {
      title: "Duration (HH:mm:ss)",
      dataIndex: "duration",
      key: "duration",
      width: "7%",
    },

    {
      title: "TranscribedPlate",
      dataIndex: "transcribedPlate",
      key: "transcribedPlate",
      width: "15%",
      render: (index, record) => (
        <TableInput
          ref={inputRef}
          value={userInputValue(record)}
          onChange={(e) => handleChangeInput(record, e.target.value)}
          onClick={(e) => handleOnclickTheTableInput(e, record)}
          disabled={record.status !== "NEW"}
        />
      ),
    },

    {
      title: cctvTableData.some((itm) => itm.status === "NEW") ? (
        <div style={{ textAlign: "center" }}>Actions</div>
      ) : null,
      dataIndex: "submit",
      key: "submit",
      showHeader: false,
      width: "15px",
      render: (_, record) => (
        <>
          {record.status !== "NEW" ? (
            ""
          ) : (
            <ActionBtns>
              {isAcceptBtnDisabled ? (
                <DisabledAcceptBtn disabled={tableInputClicked === false}>
                  Accept
                </DisabledAcceptBtn>
              ) : (
                <AcceptBtn
                  onClick={() => fetchSubmitBtnApi(record, "Approved")}
                >
                  Accept
                </AcceptBtn>
              )}

              <RejectBtn onClick={() => fetchSubmitBtnApi(record, "Rejected")}>
                Reject
              </RejectBtn>
            </ActionBtns>
          )}
        </>
      ),
    },
  ];

  const userInputValue = (record) => {
    return (
      (tableInputData[record.id] && tableInputData[record.id].textValue) ||
      record.transcribedPlate ||
      ""
    );
  };

  //-------| Close the "ImageViewBox" when we click outside |-------//
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setTableInputClicked(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOnclickTheTableInput = (event, record) => {
    event.stopPropagation();
    setTableInputClicked(true);
    setSelectedRecord(record.id);
    setInputValue(
      tableInputData[record.id] ? tableInputData[record.id].textValue : ""
    );
  };

  //-------| TranscribedPlate Input |-------//
  const handleChangeInput = (record, value) => {
    setTableInputData((prevData) => ({
      ...prevData,
      [record.id]: {
        ...prevData[record.id],
        textValue: value,
      },
    }));

    setInputValue(value);
  };

  //-------| Check The length To enable Accept Btn |-------//
  const isAcceptBtnDisabled = inputValue.length < 3;

  //-------| Plate Search Field |-------//
  const handlePlate = (plate, e) => {
    if (e) {
      setPlateNo(e);
    } else {
      setPlateNo(null);
    }
  };

  //-------| PlateNo to Uppercase |-------//
  function convertToUpperCaseAndRemoveSpaces(inputString) {
    let uppercasedString = inputString.toUpperCase();

    let stringWithoutSpaces = uppercasedString.replace(/\s/g, "");

    return stringWithoutSpaces;
  }

  //-------| Search Btn Clicked |-------//
  const handleSearchClick = () => {
    fetchCctvData();
    setCurrentPage(1);
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Container>
      <TopSection>
        <Title>CCTV Pairs</Title>
        <RightSection>
          <Cover>
            <Select
              placeholder="Site Code"
              styles={customStyles}
              isClearable
              options={siteCodes}
              onChange={(e) => {
                handleSearch("siteCode", e);

                if (e === null) {
                  setFlag("");
                }
              }}
              menuPortalTarget={document.body}
            />
          </Cover>

          <Cover>
            <DatePicker
              format="DD-MM-YYYY"
              value={startDatee}
              placeholder={"Start Date"}
              onChange={(e) => handleStartDate("startDate", e)}
            />
          </Cover>

          <Cover>
            <TimePicker
              placeholder="Start Time"
              format={"HH:mm:ss"}
              onChange={(e) => handleSearch("startTime", formatTime(e))}
            />
          </Cover>

          <Cover>
            <DatePicker
              format="DD-MM-YYYY"
              value={endDatee}
              placeholder={"End Date"}
              onChange={(e) => handleEndDate("endDate", e)}
            />
          </Cover>

          <Cover>
            <TimePicker
              placeholder="End Time"
              format={"HH:mm:ss"}
              onChange={(e) => handleSearch("endTime", formatTime(e))}
            />
          </Cover>

          <Cover>
            <Select
              placeholder="Show"
              options={showValueData}
              defaultValue={{ value: "New", label: "New" }}
              onChange={(e) => handleShowChange(e)}
              styles={customStyles}
              menuPortalTarget={document.body}
            />
          </Cover>

          <Search>
            <SearchPlateNumber
              type="text"
              placeholder="Plate Number"
              value={plateNo}
              onChange={(e) =>
                handlePlate(
                  "plate",
                  convertToUpperCaseAndRemoveSpaces(e.target.value)
                )
              }
            />
            <Icon onClick={handleSearchClick}>Search</Icon>
          </Search>
        </RightSection>
      </TopSection>
      <MyDiv>
        {isLoading ? (
          <Loader>
            <DataFetchIngLoader />
          </Loader>
        ) : cctvTableData?.length > 0 ? (
          <Table
            style={{ overflow: "scroll" }}
            rowClassName="rowClassName1"
            bordered
            columns={columns}
            dataSource={cctvTableData}
            loading={isLoading}
            pagination={false}
          />
        ) : (
          <NodataCover>
            {flag === "Site Disabled" && search?.siteCode?.value ? (
              <InfoBox>
                <Lottie options={defaultOptions} width={200} height={200} />
                <InfoText>Site {disabledSite} is disabled</InfoText>
              </InfoBox>
            ) : (
              <NoData />
            )}
          </NodataCover>
        )}

        {/*-------| Image View Box When we click "TranscribedPlate" |-------*/}
        {tableInputClicked && selectedRecord && (
          <ImageViewBox>
            <div>
              {cctvTableData
                .filter((item) => item.id === selectedRecord)
                .map((itm) => {
                  return (
                    <div key={itm.id}>
                      <TranscribeImg src={itm.image1} alt="File not found" />
                      <TranscribeImg src={itm.image2} alt="File not found" />
                      <TranscribeImg src={itm.image3} alt="File not found" />
                    </div>
                  );
                })}
            </div>
          </ImageViewBox>
        )}

        <BottomSection>
          <div>
            <span>Rows per page: </span>
            <ANTDSelect
              defaultValue={pageCount}
              onChange={handlePageSizeChange}
              style={{ width: 80 }}
            >
              <ANTDSelect.Option value={10}>10</ANTDSelect.Option>
              <ANTDSelect.Option value={20}>20</ANTDSelect.Option>
              <ANTDSelect.Option value={50}>50</ANTDSelect.Option>
            </ANTDSelect>
          </div>

          <PaginationCover>
            <Pagination
              total={paginationData?.count}
              currentPage={currentPage}
              pageSize={pageCount}
              onChange={handlePageChange}
              current={currentPage}
            />
          </PaginationCover>
        </BottomSection>
      </MyDiv>
    </Container>
  );
}

export default CctvTableDetails;

const Container = styled.div`
  padding: 20px;
  width: 100%;
  overflow: scroll;
`;

const MyDiv = styled.div`
  margin-top: 12px;
  width: 100%;
  /* min-height: auto; */
  /* max-height: calc(100vh - 180px); */
  overflow: scroll;
  border: 0.5px solid #d0d1d2;
  border-radius: 10px;

  .ant-table {
    min-height: auto !important;
    max-height: calc(100vh - 240px) !important;
    min-width: 100% !important;
    max-width: calc(100vw - 150px) !important;
    overflow: scroll;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: 0.5px solid #d0d1d2;
    border-collapse: collapse;
    padding: 8px 13px;
    font-size: 12px;
  }

  .ant-table-thead > tr > th {
    font-weight: 600;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
  }

  .ant-pagination-item a {
    color: #000 !important;
  }

  .ant-pagination-options {
    display: none;
  }
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 270px);
`;

const NodataCover = styled.div`
  height: calc(100vh - 230px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;

  /*-------| Medium screens (768px and up) |-------*/
  /* @media all and (min-width: 768px) and (max-width: 991px) {
    justify-content: center;
    gap: 10px;
  } */
`;

const PaginationCover = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

const Title = styled.h2`
  color: ${COLORS.THEME_COLOR};
  font-size: 16px;
  font-weight: 500;
`;

const RightSection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  /* Medium screens (768px and up) */
  /* @media all and (min-width: 768px) and (max-width: 991px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  } */
`;

const Cover = styled.div`
  border-radius: 5px;
  .ant-picker {
  }
`;

const TableImg = styled.img`
  max-width: 170px;
  max-height: 160px;
  object-fit: contain;
  background-color: "#f0f0f0";
  border-radius: 5px;

  /* @media all and (min-width: 768px) and (max-width: 991px) {
    max-width: 100px;
    max-height: 90px;
  } */
`;

const TableInput = styled.input`
  width: 100%;
  height: 35px;
  border: 0.1px solid #000000;
  font-family: "Roboto Mono";
  font-weight: 500;
  text-transform: uppercase;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

const ActionBtns = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const AcceptBtn = styled.button`
  width: 85px;
  height: 30px;
  background-color: #3d619b;
  color: #fff;
  border: 1px solid #3d619b;
  border-radius: 5px;
  transition: 0.3s;

  &:hover {
    color: #3d619b;
    background-color: #fff;
  }
`;

const DisabledAcceptBtn = styled.button`
  width: 85px;
  height: 30px;
  background-color: #3d619b;
  color: #fff;
  border: 1px solid #3d619b;
  border-radius: 5px;
  opacity: 0.7;
`;

const RejectBtn = styled.button`
  width: 85px;
  height: 30px;
  background-color: #3d619b;
  color: #fff;
  border: 1px solid #3d619b;
  border-radius: 5px;
  transition: 0.3s;

  &:hover {
    color: #3d619b;
    background-color: #fff;
  }
`;

const ImageViewBox = styled.div`
  width: 25%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  border-radius: 3px;
  padding: 10px;
  line-height: 0;
`;

const TranscribeImg = styled.img`
  width: 800px;
  height: 295px;
  /* object-fit: contain; */
`;

//-------| Select Component Styles |-------//
const customStyles = {
  minWidth: 120,
  fontSize: "14px !important",

  menuPortal: (base) => ({
    ...base,
    zIndex: "99999 !important",
  }),

  clearIndicator: () => ({
    color: "red",
    marginTop: "3px",
  }),

  control: (provided, state) => ({
    ...provided,
    minHeight: "31px",
    height: "30px",
    borderRadius: "6px",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: "30px",
    padding: "0 6px",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),

  indicatorSeparator: (state) => ({
    display: "none",
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "30px",
  }),

  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "14px",
  }),
};

const Search = styled.div`
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  width: 250px;
  padding: 0 10px;
  padding-right: 0;
  max-height: 33px;
  overflow: hidden;
  input {
    width: 100%;
    padding: 3px 10px;
  }
`;

const SearchPlateNumber = styled.input`
  font-family: "Roboto Mono";
  &::placeholder {
    font-family: "Inter", sans-serif;
  }
`;

const Icon = styled.span`
  display: block;
  width: 100px;
  background-color: ${COLORS.THEME_COLOR};
  color: #fff;
  padding: 6px 5px;
  margin: -5px 0;
  text-align: center;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const InfoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const InfoText = styled.div`
  font-size: 20px;
  font-weight: 500;
`;
