import React from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

// Antd
import { Divider, Drawer, Spin, Tooltip } from "antd";

// Images Used
import closeIcon from "../../../assets/images/close.svg";
import historyIcon from "../../../assets/images/history.svg";

// Components Used
import { formatDatev2 } from "../../../utils/HelperFunctions";

const HistoryDrawer = ({
  open,
  hideHistoryDrawer,
  historyData,
  hasMore,
  loading,
  handleScrollHistory,
  scrollableDivRef,
  historyDataCount,
}) => {
  const rowData = useSelector((state) => state?.user?.sitePerfomanceRowData);

  //-------| Drawer Tittle UI |-------//
  const drawerTittleContent = () => {
    return (
      <>
        <DrawerHeader>
          <CloseBtnBox>
            <CloseBtn src={closeIcon} onClick={hideHistoryDrawer} alt="x" />
          </CloseBtnBox>

          <HeaderContent>
            <Heading>
              <HeadingIcon src={historyIcon} alt="icon" />
              <HeadingText>View History</HeadingText>
            </Heading>

            <SubHeadingText>
              Provides a detailed view of Hold/Unhold actions.
            </SubHeadingText>

            <DataBox>
              <Item>
                <ItemKey>Site Code</ItemKey>
                <ItemValue>{rowData?.site}</ItemValue>
              </Item>

              <Item>
                <ItemKey>Records to be processed</ItemKey>
                <ItemValue>{rowData?.recordsToBeProcessed}</ItemValue>
              </Item>
            </DataBox>
          </HeaderContent>
        </DrawerHeader>
      </>
    );
  };

  return (
    <>
      <Drawer
        width={400}
        placement="right"
        title={drawerTittleContent()}
        onClose={hideHistoryDrawer}
        closable={false}
        open={open}
      >
        <Spin spinning={loading}>
          <Container ref={scrollableDivRef} onScroll={handleScrollHistory}>
            {historyData.map((item) => (
              <div key={item?.serialNo}>
                <ReasonCard>
                  <CardTopContent>
                    <ContentOne>
                      <SlNo>#{item?.serialNo}</SlNo>

                      <Tooltip
                        placement="right"
                        color={"black"}
                        title={<span>{item?.emailId}</span>}
                      >
                        <EmailId>{item?.emailId}</EmailId>
                      </Tooltip>
                    </ContentOne>

                    <StatusText color={item?.action}>{item.action}</StatusText>
                  </CardTopContent>

                  <ReasonSection>
                    <ReasonText>{item?.reason || "--"}</ReasonText>
                  </ReasonSection>

                  <DateSection>
                    <DateText>{formatDatev2(item.timeUpdated)}</DateText>
                  </DateSection>
                </ReasonCard>

                <Divider style={dividerStyle} />
              </div>
            ))}
          </Container>

          {/* {!hasMore && historyData.length === historyDataCount && (
            <NoDataText>-- No more data to load --</NoDataText>
          )} */}
        </Spin>
      </Drawer>
    </>
  );
};

export default HistoryDrawer;

const DrawerHeader = styled.div`
  height: auto;
`;

const CloseBtnBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseBtn = styled.img`
  width: 3%;
  cursor: pointer;
`;

const HeaderContent = styled.div``;

const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const HeadingIcon = styled.img``;

const HeadingText = styled.span`
  font-size: clamp(0.5625rem, 0.3644rem + 0.6338vw, 1.125rem);
  font-weight: 500;
  color: #3b7ffc;
`;

const SubHeadingText = styled.span`
  font-size: clamp(0.5625rem, 0.4525rem + 0.3521vw, 0.875rem);
  font-weight: 400;
  color: #6a6a6a;
`;

const DataBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemKey = styled.span`
  font-size: clamp(0.5625rem, 0.4085rem + 0.493vw, 1rem);
  font-weight: 700;
  color: #060606;
`;

const ItemValue = styled.span`
  font-size: clamp(0.5625rem, 0.4085rem + 0.493vw, 1rem);
  font-weight: 400;
  color: #6a6a6a;
`;

const Container = styled.div`
  max-height: calc(100vh - 24vh);
  overflow: auto;
  position: relative;
  border: 1px solid #ffffff;
`;

const ReasonCard = styled.div``;

const dividerStyle = {
  borderTop: "1px solid #D0D1D2",
};

const CardTopContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContentOne = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SlNo = styled.span`
  font-size: clamp(0.5625rem, 0.3644rem + 0.6338vw, 1.125rem);
  font-weight: 700;
  color: #060606;
`;

const EmailId = styled.span`
  width: 10vw;
  font-size: clamp(0.5625rem, 0.3644rem + 0.6338vw, 1.125rem);
  font-weight: 400;
  color: #060606;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
`;

const StatusText = styled.div`
  padding: 4px 8px;
  font-size: clamp(0.5625rem, 0.4965rem + 0.2113vw, 0.75rem);
  font-weight: 500;
  color: ${(props) => (props.color === "Hold" ? "#db884c" : "#5EB383")};
  text-transform: uppercase;
  border-radius: 32px;
  border: 0.6px solid;
  border-color: ${(props) => (props.color === "Hold" ? "#db884c" : "#5EB383")};
`;

const ReasonSection = styled.div`
  max-height: 100px;
  margin-top: 12px;
  overflow: auto;
`;

const ReasonText = styled.span`
  font-size: clamp(0.5625rem, 0.3644rem + 0.6338vw, 1.125rem);
  font-weight: 400;
  color: #6a6a6a;
`;

const DateSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 12px;
`;

const DateText = styled.span`
  font-size: clamp(0.5625rem, 0.4085rem + 0.493vw, 1rem);
  font-weight: 500;
  color: #6a6a6a;
`;

const NoDataText = styled.p`
  width: 100%;
  height: auto;
  padding: 4px;
  position: absolute;
  bottom: 0;
  font-size: clamp(0.5625rem, 0.4085rem + 0.493vw, 1rem);
  font-weight: 500;
  font-style: italic;
  color: #3d619b;
  text-align: center;
  border: 1px dashed #3d619b;
  border-radius: 8px;
  background: #fff;
`;
