import React, { useEffect, useState } from "react";

// Antd
import { Table } from "antd";

// Component Used
import axiosConfig from "../../../axiosConfig";
import { formatDatev2, formatTimeHhSs } from "../../../utils/HelperFunctions";

const ProcessStatusTable = () => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  //-------| CCTV Table Details Get Api |-------//
  const fetchTableData = () => {
    setLoading(true);

    axiosConfig
      .get(`GetPolusProcessStatus`)

      .then((res) => {
        const { statusCode, polusProcessStatusUIDtos } = res?.data;

        if (statusCode === 200) {
          setTableData(polusProcessStatusUIDtos);
        }

        setLoading(false);
      })

      .catch((err) => {
        console.error("Error Process Status Api", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  const data = tableData.map((item, index) => ({
    ...item,
    slno: index + 1,
  }));

  const columns = [
    {
      title: <span style={textStyleOne}>Sl.No</span>,
      dataIndex: "slno",
      key: "slno",
      width: "80px",
    },
    {
      title: <span style={textStyleOne}>Id</span>,
      dataIndex: "id",
      key: "id",
      width: "120px",
    },
    {
      title: <span style={textStyleOne}>Process Date</span>,
      dataIndex: "processDate",
      key: "processDate",
      render: (data) => <div>{formatDatev2(data)}</div>,
    },
    {
      title: <span style={textStyleOne}>Created Time</span>,
      dataIndex: "createdTime",
      key: "createdTime",
      render: (data) => (
        <div>
          <div>
            <span style={textStyleOne}>Date:&nbsp;</span>
            <span>{formatDatev2(data)} </span>
          </div>

          <div>
            <span style={textStyleOne}>Time:&nbsp;</span>
            <span>{formatTimeHhSs(data)}</span>
          </div>
        </div>
      ),
    },
    {
      title: <span style={textStyleOne}>Modified Time</span>,
      dataIndex: "modifiedTime",
      key: "modifiedTime",
      render: (data) => (
        <div>
          <div>
            <span style={textStyleOne}>Date:&nbsp;</span>
            <span>{formatDatev2(data)} </span>
          </div>

          <div>
            <span style={textStyleOne}>Time:&nbsp;</span>
            <span>{formatTimeHhSs(data)}</span>
          </div>
        </div>
      ),
    },
    {
      title: <span style={textStyleOne}>Status</span>,
      dataIndex: "status",
      key: "status",
    },
    {
      title: <span style={textStyleOne}>Tag Value</span>,
      dataIndex: "tagValue",
      key: "tagValue",
    },
  ];

  return (
    <>
      <Table
        style={{ overflow: "scroll" }}
        scroll={{ y: "calc(100vh - 100px)" }}
        bordered
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={false}
      />
    </>
  );
};

export default ProcessStatusTable;

const textStyleOne = {
  fontSize: "16px",
  fontWeight: "600",
  color: "#242424",
};

const textStyleTwo = {
  fontSize: "14px",
  fontWeight: "600",
  color: "#242424",
};
