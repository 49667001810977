import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import entry_img from "../../../assets/images/clean_pairs/entry_img.png";
import exit_img from "../../../assets/images/clean_pairs/car_back.png";
import Placeholder_image from "../../../assets/images/image_placeholder.gif";
import numberplate from "../../../assets/images/clean_pairs/numberplate.png";
import arrow from "../../../assets/images/clean_pairs/right_arrow.svg";
import { Select, Tooltip, notification } from "antd";
import { formatDatev2, formatTime } from "../../../utils/HelperFunctions";
import axiosConfig from "../../../axiosConfig";
import CorrectThePlate from "../modals/CorrectThePlate";

function CarDetailsCard({ data, showValue }) {
  const [isGoodToPrint, setGoodToPrint] = useState(false);
  const [entryImageLoading, setEntryImageLoading] = useState(true);
  const [exitImageLoading, setExitImageLoading] = useState(true);
  const [isModal, setModal] = useState(false);
  const [modalType, setModalType] = useState("");

  useEffect(() => {
    setGoodToPrint(data.goodToPrint === "True" ? true : false);
  }, [data]);

  const submitGoodToPrint = (id) => {
    axiosConfig
      .get(`UpdategoodToPrintforUI/?NotGoodtoPrintID=${id}`)
      .then((res) => {
        const { statusCode, data, paginationData } = res.data;
        if (statusCode === 200) {
          notification.success({
            message: "successfully updated",
            duration: 2,
          });
          setGoodToPrint((prev) => !prev);
        } else {
          notification.error({
            message: "Something went wrong",
            duration: 2,
          });
        }
      })
      .catch((err) => {
        console.log(err);

        notification.error({
          message: "Something went wrong",
          duration: 2,
        });
      });
  };

  const displayPlate = data.source !== "CCTV" && data.source !== "StarTraq";

  return (
    <Container>
      <Entry>
        <Tooltip
          placement="right"
          overlayInnerStyle={{ width: "820px" }}
          title={
            <img
              src={data.entryImage2}
              alt=""
              // style={{ maxWidth: "800px", width: "800px" }}
              style={{ maxWidth: "800px", width: "800px", maxHeight: "800px" }}
            />
          }
          color={"black"}
        >
          <CarImage href={data.entryImage2} target="_blank">
            <img
              src={entryImageLoading ? Placeholder_image : data.entryImage2}
              alt="Entry Img"
              onLoad={() => setEntryImageLoading(false)}
            />
          </CarImage>
        </Tooltip>
        <EntryDetails>
          <Tooltip
            overlayInnerStyle={{ width: "320px" }}
            title={
              <img
                src={data.entryImage1}
                alt=""
                style={{ maxWidth: "300px", width: "300px" }}
              />
            }
            color={"black"}
          >
            {displayPlate && (
              <NumberPlate href={data.entryImage1} target="_blank">
                <img src={data.entryImage1} alt="" />
              </NumberPlate>
            )}
          </Tooltip>
          <Feilds>
            <FeildName>Plate No:</FeildName>
            <FeildData
              style={{
                fontFamily: "Roboto Mono",
                fontWeight: 600,
                fontSize: "16px",
              }}
            >
              {data.entryPlate}
            </FeildData>
          </Feilds>
          <Feilds>
            <FeildName>Entry Date:</FeildName>
            <FeildData
              style={{
                // fontFamily: "Roboto Mono",
                fontWeight: 600,
                fontSize: "14px",
              }}
            >
              {data.entryTime ? formatDatev2(data.entryTime) : "-"}
            </FeildData>
          </Feilds>
          <Feilds>
            <FeildName>Entry Time:</FeildName>
            <FeildData
              style={{
                // fontFamily: "Roboto Mono",
                fontWeight: 600,
                fontSize: "14px",
              }}
            >
              {data.entryTime !== null && formatTime(data.entryTime)}
            </FeildData>
          </Feilds>
          <Feilds>
            <FeildName>Motion:</FeildName>
            <FeildData>{data.entryMotion}</FeildData>
          </Feilds>
        </EntryDetails>
      </Entry>
      <Exit>
        <Tooltip
          placement="right"
          overlayInnerStyle={{ width: "820px" }}
          title={
            <img
              src={data.exitImage2}
              alt=""
              // style={{ maxWidth: "800px", width: "800px" }}
              style={{ maxWidth: "800px", width: "800px", maxHeight: "800px" }}
            />
          }
          color={"black"}
        >
          <CarImage href={data.exitImage2} target="_blank">
            <img
              src={exitImageLoading ? Placeholder_image : data.exitImage2}
              onLoad={() => setExitImageLoading(false)}
              alt="Entry Img"
            />
          </CarImage>
        </Tooltip>
        <EntryDetails>
          <Tooltip
            overlayInnerStyle={{ width: "320px" }}
            title={
              <img
                src={data.exitImage1}
                alt=""
                style={{ maxWidth: "300px", width: 300 }}
              />
            }
            color={"black"}
          >
            {displayPlate && (
              <NumberPlate href={data.exitImage1} target="_blank">
                <img src={data.exitImage1} alt="" />
              </NumberPlate>
            )}
          </Tooltip>
          <Feilds>
            <FeildName>Plate No:</FeildName>
            <FeildData
              style={{
                fontFamily: "Roboto Mono",
                fontWeight: 600,
                fontSize: "16px",
              }}
            >
              {data.exitPlate}
            </FeildData>
          </Feilds>
          <Feilds>
            <FeildName>Exit Date:</FeildName>
            <FeildData
              style={{
                // fontFamily: "Roboto Mono",
                fontWeight: 600,
                fontSize: "14px",
              }}
            >
              {formatDatev2(data.exitTime)}
            </FeildData>
          </Feilds>
          <Feilds>
            <FeildName>Exit Time:</FeildName>
            <FeildData
              style={{
                // fontFamily: "Roboto Mono",
                fontWeight: 600,
                fontSize: "14px",
              }}
            >
              {formatTime(data.exitTime)}
            </FeildData>
          </Feilds>
          <Feilds>
            <FeildName>Motion:</FeildName>
            <FeildData>{data.exitMotion}</FeildData>
          </Feilds>
        </EntryDetails>
      </Exit>
      <VehicleDetails>
        <SectionTitle>Vehicle Details</SectionTitle>
        <Feilds>
          <FeildName className="veh_details">Make:</FeildName>
          <FeildData>{data.make}</FeildData>
        </Feilds>
        <Feilds>
          <FeildName className="veh_details">Model:</FeildName>
          <Tooltip title={data.model}>
            <FeildData>{data.model}</FeildData>
          </Tooltip>
        </Feilds>
        <Feilds>
          <FeildName className="veh_details">Colour:</FeildName>
          <FeildData>{data.color}</FeildData>
        </Feilds>
        <Feilds>
          <FeildName className="veh_details">Carweb:</FeildName>
          <FeildData
            className={data?.carwebStatus === "Valid" ? "valid" : "invalid"}
          >
            {data?.carwebStatus}
          </FeildData>
        </Feilds>
      </VehicleDetails>

      <ViolationDetails>
        <TittleBox>
          <Tittle>Violation Details</Tittle>
          <Status>{data.acceptance}</Status>
        </TittleBox>
        <Feilds>
          <FeildName style={{ width: 130 }}>Site code:</FeildName>
          <FeildData>{data.site}</FeildData>
        </Feilds>
        <Feilds>
          <FeildName className="voi_details">Site Violation Time:</FeildName>
          <FeildData>{data.violationTime} mins</FeildData>
        </Feilds>
        <Feilds>
          <FeildName className="voi_details">Stay Duration:</FeildName>
          <FeildData>{data.duration} mins</FeildData>
        </Feilds>
        <Feilds>
          <FeildName className="voi_details">Violation Type:</FeildName>
          <FeildData style={{ fontSize: "16px" }}>
            {data.violationType}
          </FeildData>
        </Feilds>
        <Feilds>
          <FeildName className="voi_details">Source:</FeildName>
          <FeildData style={{ fontSize: "16px" }}>{data.source}</FeildData>
        </Feilds>
      </ViolationDetails>
    </Container>
  );
}

export default CarDetailsCard;
const Container = styled.div`
  display: flex;
  border: 1px solid #d0d1d2;
  border-radius: 5px;
  padding: 8px 5px;
  margin-bottom: 8px;
  /* gap: 20px; */
`;
const Entry = styled.div`
  display: flex;
  gap: 10px;
  padding: 0 10px;
  position: relative;
  border-right: 1px solid #d0d1d2;
  flex: 1;
`;
const Exit = styled.div`
  display: flex;
  gap: 10px;
  border-right: 1px solid #d0d1d2;
  padding: 0 10px;
  flex: 1;
`;
const VehicleDetails = styled.div`
  border-right: 1px solid #d0d1d2;
  padding: 0 10px;
  min-width: 250px;
`;
const ViolationDetails = styled.div`
  padding: 0 10px;
`;
const ActionSection = styled.div`
  padding: 0 10px;
  flex: 1;
  padding-right: 5px;
  max-width: 180px;
`;
const CarImage = styled.a`
  width: 100%;
  display: block;
  /* width: 100px; */
  /* min-width: 100px; */
  max-width: 200px;
  height: 120px;
  border-radius: 8px;
  overflow: hidden;
  img {
    display: block;
    /* object-fit: cover; */
    width: 100%;
    height: 120px;
    object-fit: cover;
  }
`;
const EntryDetails = styled.div``;
const NumberPlate = styled.a`
  height: 40px;
  width: 200px;
  display: block;
  img {
    display: block;
    height: 100%;
    width: 100%;
  }
  margin-bottom: 5px;
`;
const Feilds = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;
const FeildName = styled.p`
  font-size: 13px;
  color: #6a6a6a;
  width: 90px;
  &.veh_details {
    width: 60px !important;
  }
  &.voi_details {
    width: 130px;
  }
`;
const FeildData = styled.p`
  font-size: 12px;
  color: #6a6a6a;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #000;
  font-weight: 500;
  text-transform: capitalize;
  min-width: 140px;
  max-width: 140px;

  &.valid {
    color: green;
    font-size: 16px;
    font-weight: 600;
  }
  &.invalid {
    color: red;
    font-size: 16px;
    font-weight: 600;
  }
`;
const Radio = styled.span`
  display: block;
  width: 30px;
  height: 16px;
  background-color: #dfdfdf;
  border-radius: 10px;
  border: 2px solid #dfdfdf;
  position: relative;
  cursor: pointer;
  span {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    left: 0;
  }
  &.active {
    background-color: #3d619b;
    border-radius: 10px;
    border: 2px solid #3d619b;
    span {
      left: 15px;
    }
  }
`;
const TittleBox = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
`;

const Tittle = styled.span`
  font-size: 14px;
  color: #3d619b;
`;

const Status = styled.span`
  min-width: 140px;
  max-width: 140px;
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  color: #6a6a6a;
  text-transform: capitalize;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 50px;
  background-color: #e2eeff;
  border: 1px solid #bed9ff;
`;

const SectionTitle = styled.p`
  font-size: 14px;
  color: #3d619b;
  margin-bottom: 15px;
`;
const RejectButton = styled.span`
  height: 30px;
  border: 1px solid #3d619b;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3d619b;
  font-size: 13px;
  border-radius: 5px;
  cursor: not-allowed;
  transition: 0.3s;
  margin-top: 5px;
  filter: grayscale(1);
  opacity: 0.5;
  width: 100%;

  &.active {
    cursor: pointer;
    filter: grayscale(0);
    opacity: 1;
    &:hover {
      background-color: #3d619b;
      color: #fff;
    }
  }
`;
const AcceptButton = styled.span`
  height: 30px;
  border: 1px solid #3d619b;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3d619b;
  color: #fff;
  font-size: 13px;
  border-radius: 5px;
  flex: 1;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: #fff;
    color: #3d619b;
  }
`;
const Cover = styled.div`
  border-radius: 5px;
  flex: 1;
  margin-top: 10px;
  .ant-select-selector {
    border: 1px solid #1a73e8 !important;
    ::placeholder {
      color: #000 !important;
    }
  }

  /* border: 1px solid #d0d1d2; */
`;
const CorrectPlateBTN = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  gap: 10px;
  color: #1a73e8;
  margin-top: 5px;
  cursor: pointer;
`;
const Icon = styled.img`
  display: block;
  width: 10px;
`;
const GoodToPrint = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
`;
const ActionButton = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  margin-top: 5px;
`;
const ShortBotton = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const Button = styled.span`
  display: flex;
  align-items: center;
  font-size: 12px;
  gap: 10px;
  color: #1a73e8;
  margin-top: 5px;
  cursor: pointer;
`;
const ReasonSelect = styled.select`
  height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #1a73e8 !important;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
`;
