import React, { useEffect, useState } from "react";

// Antd
import { Table } from "antd";

// Component Used
import axiosConfig from "../../../axiosConfig";
import { formatDatev2 } from "../../../utils/HelperFunctions";

const PendingProcess = () => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  //-------| CCTV Table Details Get Api |-------//
  const fetchTableData = () => {
    setLoading(true);

    axiosConfig
      .get(`PendingtoBeProcessedUI`)

      .then((res) => {
        const { statusCode, pendingToBeProcessedUIDtos } = res?.data;

        if (statusCode === 200) {
          setTableData(pendingToBeProcessedUIDtos);
        }

        setLoading(false);
      })

      .catch((err) => {
        console.error("Error Pending to be Processed Api", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  const data = tableData.map((item, index) => ({
    ...item,
    slno: index + 1,
  }));

  const columns = [
    {
      title: <span style={textStyleOne}>Sl.No</span>,
      dataIndex: "slno",
      key: "slno",
      width: "80px",
    },
    {
      title: <span style={textStyleOne}>Violation Date</span>,
      dataIndex: "violationDate",
      key: "violationDate",
      render: (data) => <div>{formatDatev2(data)}</div>,
    },
    {
      title: <span style={textStyleOne}>Source Screen</span>,
      dataIndex: "sourceScreen",
      key: "sourceScreen",
    },
    {
      title: <span style={textStyleOne}>Total Count</span>,
      dataIndex: "totalCount",
      key: "totalCount",
    },
    {
      title: <span style={textStyleOne}>Pending</span>,
      dataIndex: "pending",
      key: "pending",
    },
    {
      title: <span style={textStyleOne}>Twelve Hour</span>,
      dataIndex: "twelveHour",
      key: "twelveHour",
    },
    {
      title: <span style={textStyleOne}>DVLA</span>,
      dataIndex: "dvla",
      key: "dvla",
    },
    {
      title: <span style={textStyleOne}>Rejected</span>,
      dataIndex: "rejected",
      key: "rejected",
    },
    {
      title: <span style={textStyleOne}>Plate Corrected</span>,
      dataIndex: "plateCorrected",
      key: "plateCorrected",
    },
    {
      title: <span style={textStyleOne}>Sum Count</span>,
      dataIndex: "sumCount",
      key: "sumCount",
    },
  ];

  return (
    <>
      <Table
        style={{ overflow: "scroll" }}
        scroll={{ y: "calc(100vh - 100px)" }}
        bordered
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={false}
      />
    </>
  );
};

export default PendingProcess;

const textStyleOne = {
  fontSize: "16px",
  fontWeight: "600",
  color: "#242424",
};
