import React from "react";
import { styled } from "styled-components";
import animationData from "../../assets/lotties/animation_lnmzdp1c.json";
import Lottie from "react-lottie";

function NoData({ message }) {
	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};
	return (
		<Container>
			<Lottie options={defaultOptions} width={200} height={200} />
			<Title>{message?.length > 0 ? message : "No Data Available"}</Title>
		</Container>
	);
}

export default NoData;
const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;
const Title = styled.div`
	font-size: 20px;
	font-weight: 500;
`;
