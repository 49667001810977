import { Tooltip, notification } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../../Constants";
import { formatDatev2, formatTime } from "../../../utils/HelperFunctions";
import axiosConfig from "../../../axiosConfig";

function CleanPairsSilmilarPairs({ data }) {
	const [PlateNumber, setPlateNumber] = useState("");

	useEffect(() => {
		setPlateNumber(data.plate);
	}, [data]);
	const submitPlate = (plateId, plateNumber) => {
		if (plateNumber.length > 0) {
			const queryParams = {
				CorrectedPlate: JSON.stringify({
					plateId: plateId,
					correctedplate: plateNumber,
					category: "CleanPair",
					carEntryExit: "",
				}),
			};
			axiosConfig
				.get(`FuzzyPairsCorrectPlate/`, {
					params: queryParams,
				})
				.then((res) => {
					const { statusCode } = res.data;
					if (statusCode === 200) {
						notification.success({
							message: "Successfully Updated",
							duration: 2,
						});
					} else {
						notification.error({
							message: "Something went Wrong!",
							duration: 2,
						});
					}
				})
				.catch((err) => {
					notification.error({
						message: "Something went Wrong!",
						duration: 2,
					});
					console.log(err);
				});
		} else {
			notification.error({
				message: "Plate Number is not added",
				duration: 2,
			});
		}
	};

	function convertToUpperCaseAndRemoveSpaces(inputString) {
		// Convert the string to uppercase
		let uppercasedString = inputString.toUpperCase();

		// Remove spaces from the string
		let stringWithoutSpaces = uppercasedString.replace(/\s/g, "");

		return stringWithoutSpaces;
	}

	return (
		<Container>
			<LeftSection>
				<Tooltip
					placement="right"
					overlayInnerStyle={{ width: "620px" }}
					title={
						<img
							src={data?.fullImage}
							alt=""
							style={{
								maxWidth: "600px",
								width: "100%",
							}}
						/>
					}
					color={"black"}
				>
					<CarImage>
						<img src={data?.fullImage} alt="Entry Img" />
					</CarImage>
				</Tooltip>
				<EntryDetails>
					<Tooltip
						overlayInnerStyle={{ width: "420px" }}
						title={
							<img
								src={data?.croppedImage}
								alt=""
								style={{
									maxWidth: "400px",
									width: 400,
								}}
							/>
						}
						color={"black"}
					>
						<NumberPlate>
							<img src={data?.croppedImage} alt="" />
						</NumberPlate>
					</Tooltip>
					<Feilds>
						<FeildName>Plate No:</FeildName>
						<FeildData
							style={{
								fontFamily: "Roboto Mono",
								fontWeight: 600,
								fontSize: "18px",
							}}
						>
							{data?.plate}
						</FeildData>
					</Feilds>
				</EntryDetails>
			</LeftSection>
			<MiddleSection>
				<Feilds>
					<FeildName>Site:</FeildName>
					<FeildData>{data.siteCode}</FeildData>
				</Feilds>
				<Feilds>
					<FeildName> Date:</FeildName>
					<FeildData
						style={{
							// fontFamily: "Roboto Mono",
							fontWeight: 600,
							fontSize: "16px",
						}}
					>
						{formatDatev2(data?.date)}
					</FeildData>
				</Feilds>
				<Feilds>
					<FeildName> Time:</FeildName>
					<FeildData
						style={{
							// fontFamily: "Roboto Mono",
							fontWeight: 600,
							fontSize: "16px",
						}}
					>
						{formatTime(data?.date)}
					</FeildData>
				</Feilds>
				<Feilds>
					<FeildName>Motion:</FeildName>
					<FeildData>{data?.motion}</FeildData>
				</Feilds>
				<Feilds>
					<FeildName>Direction</FeildName>
					<FeildData>{data.direction}</FeildData>
				</Feilds>
			</MiddleSection>
			<RightSection>
				<CorrectThePlateSection>
					Correct The Plate{" "}
					<input
						type="text"
						value={PlateNumber}
						style={{
							fontFamily: "Roboto Mono",
							fontWeight: 600,
						}}
						onChange={(e) =>
							setPlateNumber(
								convertToUpperCaseAndRemoveSpaces(
									e.target.value
								)
							)
						}
					/>
				</CorrectThePlateSection>
				<ButtonSection>
					<RejectButton onClick={() => setPlateNumber("")}>
						Cancel
					</RejectButton>
					<AcceptButton
						onClick={() => {
							if (PlateNumber !== data.plate) {
								submitPlate(data.plateid, PlateNumber);
							}
						}}
						className={PlateNumber === data.plate && "inactive"}
					>
						Update Plate
					</AcceptButton>
				</ButtonSection>
			</RightSection>
		</Container>
	);
}

export default CleanPairsSilmilarPairs;
const Container = styled.div`
	border-bottom: 1px solid #d9d9d9;
	padding: 10px 0;
	display: flex;
`;
const LeftSection = styled.div`
	display: flex;
	gap: 10px;
	flex: 1;
	border-right: 1px solid #d9d9d9;
	padding: 0 10px;
	max-width: 450px;
`;
const CarImage = styled.div`
	width: 100%;
	/* width: 100px; */
	max-width: 150px;
	height: 100px;
	border-radius: 8px;
	overflow: hidden;
	img {
		display: block;
		/* object-fit: cover; */
		width: 100%;
		height: 100px;
		object-fit: cover;
	}
`;
const EntryDetails = styled.div``;
const NumberPlate = styled.span`
	height: 35px;
	display: block;
	img {
		display: block;
		height: 100%;
	}
	margin-bottom: 5px;
`;
const Feilds = styled.div`
	margin-bottom: 5px;
	display: flex;
	align-items: center;
`;
const FeildName = styled.p`
	font-size: 13px;
	color: #6a6a6a;
	width: 90px;
	&.veh_details {
		width: 60px !important;
	}
	&.voi_details {
		width: 130px;
	}
`;
const FeildData = styled.p`
	font-size: 12px;
	color: ${COLORS.THEME_COLOR};
	white-space: nowrap;
	font-weight: 500;
`;
const MiddleSection = styled.div`
	padding: 0 50px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-right: 1px solid #d9d9d9;
	max-width: 2500px;
	min-width: 250px;
`;
const RightSection = styled.div`
	flex: 1;
	padding: 0 10px;
`;
const CorrectThePlateSection = styled.div`
	display: flex;
	align-items: center;
	white-space: nowrap;
	margin-top: 10px;
	font-size: 14px;
	font-weight: 500;
	input {
		border: 1px solid #d9d9d9;
		width: 200px;
		margin-left: 20px;
	}
`;
const ButtonSection = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 10px;
	margin-top: 20px;
`;
const RejectButton = styled.span`
	height: 25px;
	border: 1px solid #3d619b;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #3d619b;
	font-size: 13px;
	border-radius: 5px;
	width: 80px;
	cursor: pointer;
	transition: 0.3s;
	&:hover {
		background-color: #3d619b;
		color: #fff;
	}
`;
const AcceptButton = styled.span`
	height: 25px;
	border: 1px solid #3d619b;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #3d619b;
	color: #fff;
	font-size: 13px;
	border-radius: 5px;
	width: 120px;
	cursor: pointer;
	transition: 0.3s;
	&.inactive {
		filter: grayscale(100%);
		cursor: not-allowed;
		opacity: 0.8;
		:hover {
			background-color: #fff;
			color: #3d619b;
		}
	}
`;
