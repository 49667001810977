import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { COLORS } from "../../Constants";
import edit from "../../assets/images/editIconUser-Mapping.svg";
import delete_btn from "../../assets/images/delete.svg";
import { Button, Flex, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { DatePicker, Input, Pagination, Select, Table } from "antd";
import { Link } from "react-router-dom";
import axiosConfig from "../../axiosConfig";
import { Switch } from "antd";
import { CSVLink } from "react-csv";
import downloadIcon from "../../assets/images/Export CSV.svg";

import {
  CaretUpOutlined,
  CaretDownOutlined,
  SearchOutlined,
} from "@ant-design/icons";

function SitePerformance() {
  const [isLoading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [pageCount, setPageCount] = useState(10);
  const [sortFeild, setSortFeild] = useState("");
  const [sortOrder, setSortOrder] = useState("0");
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState("");
  const [editUserEmail, setEditUserEmail] = useState("");
  const [editUserRole, setEditUserRole] = useState("");
  const [editUserId, setEditUserId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [message, setMessage] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [switchValue, setSwitchValue] = useState(false);
  const [siteVal, setSiteVal] = useState("");
  const [csvData, setCSVData] = useState([]);
  const [search, setSearch] = useState({
    siteCode: null,
    siteName: null,
    startDate: null,
    endDate: null,
  });

  function formatAntdDatePickerDate(selectedDate) {
    // Use the `format` function from moment.js to format the date
    return selectedDate.format("YYYY-MM-DD");
  }

  const handleSearch = (column, value) => {
    const data = { ...search };

    if (column === "startDate" || column === "endDate") {
      if (value) {
        const selectedDate = value;
        const formattedDate = formatAntdDatePickerDate(selectedDate);
        data[column] = formattedDate;
      } else {
        data[column] = null;
      }
    } else {
      data[column] = value;
    }

    setSearch(data);
  };

  const handleCustomSort = (column, currentSort) => {
    if (currentSort === "0") {
      setSortFeild(column);
      setSortOrder("1");
    } else if (currentSort === "1") {
      setSortFeild(null);
      setSortOrder(null);
    } else {
      setSortFeild(column);
      setSortOrder("0");
    }
  };
  function formatDatev2(inputDate) {
    const dateObject = new Date(inputDate);

    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
    const year = dateObject.getFullYear();

    // Pad day and month with leading zeros if necessary
    const formattedDay = String(day).padStart(2, "0");
    const formattedMonth = String(month).padStart(2, "0");
    if (inputDate) {
      return `${formattedDay}-${formattedMonth}-${year}`;
    } else {
      return null;
    }
  }

  const handleSwitch = (e) => {
    setSiteVal(e.site);
    holdUnhold(e.site);
  };

  const columns = [
    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("siteCode", sortOrder)}>
            <HeaderName>Site Code</HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: "site",
      key: "site",
      // Enable sorting for this column
      className: "custom-sort-header", // Add the custom class
    },
    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("emailId", sortOrder)}>
            <HeaderName>
              {formatDatev2(tableData[0]?.performanceDate[0].processDate)}
            </HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: "performanceDate",
      key: "uncleanPairCount",
      render: (performanceDate) => {
        return (
          <div>
            <span>clean Pair - </span>
            <span style={{ color: "gray", marginLeft: "5px" }}>
              {performanceDate[0]?.cleanUncleanCount.cleanPairCount}
            </span>
            <br />
            <span>Potential Pair - </span>
            <span style={{ color: "gray", marginLeft: "5px" }}>
              {performanceDate[0]?.cleanUncleanCount.uncleanPairCount}
            </span>
          </div>
        );
      },
    },

    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("userRole", sortOrder)}>
            <HeaderName>
              {formatDatev2(tableData[0]?.performanceDate[1]?.processDate)}
            </HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: "performanceDate",
      key: "uncleanPairCount",
      render: (performanceDate) => {
        return (
          <div>
            <span>clean Pair - </span>
            <span style={{ color: "gray", marginLeft: "5px" }}>
              {/* {performanceDate
                .map((clean) => clean.cleanUncleanCount)
                .map((data) => data.cleanPairCount)} */}
              {performanceDate[1]?.cleanUncleanCount.cleanPairCount}
            </span>
            <br />
            <span>Potential Pair - </span>
            <span style={{ color: "gray", marginLeft: "5px" }}>
              {performanceDate[1]?.cleanUncleanCount.uncleanPairCount}
            </span>
          </div>
        );
      },
    },
    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("userRole", sortOrder)}>
            <HeaderName>
              {formatDatev2(tableData[0]?.performanceDate[2]?.processDate)}
            </HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: "performanceDate",
      key: "uncleanPairCount",
      render: (performanceDate) => {
        return (
          <div>
            <span>clean Pair - </span>
            <span style={{ color: "gray", marginLeft: "5px" }}>
              {performanceDate[2]?.cleanUncleanCount.cleanPairCount}
            </span>
            <br />
            <span>Potential Pair - </span>
            <span style={{ color: "gray", marginLeft: "5px" }}>
              {performanceDate[2]?.cleanUncleanCount.uncleanPairCount}
            </span>
          </div>
        );
      },
    },
    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("userRole", sortOrder)}>
            <HeaderName>
              {formatDatev2(tableData[0]?.performanceDate[3]?.processDate)}
            </HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: "performanceDate",
      key: "uncleanPairCount",
      render: (performanceDate) => {
        return (
          <div>
            <span>clean Pair - </span>
            <span style={{ color: "gray", marginLeft: "5px" }}>
              {performanceDate[3]?.cleanUncleanCount.cleanPairCount}
            </span>
            <br />
            <span>Potential Pair - </span>
            <span style={{ color: "gray", marginLeft: "5px" }}>
              {performanceDate[3]?.cleanUncleanCount.uncleanPairCount}
            </span>
          </div>
        );
      },
    },
    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("userRole", sortOrder)}>
            <HeaderName>
              {formatDatev2(tableData[0]?.performanceDate[4]?.processDate)}
            </HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: "performanceDate",
      key: "uncleanPairCount",
      render: (performanceDate) => {
        return (
          <div>
            <span>clean Pair - </span>
            <span style={{ color: "gray", marginLeft: "5px" }}>
              {performanceDate[4]?.cleanUncleanCount.cleanPairCount}
            </span>
            <br />
            <span>Potential Pair - </span>
            <span style={{ color: "gray", marginLeft: "5px" }}>
              {performanceDate[4]?.cleanUncleanCount.uncleanPairCount}
            </span>
          </div>
        );
      },
    },
    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("userRole", sortOrder)}>
            <HeaderName>
              {formatDatev2(tableData[0]?.performanceDate[5]?.processDate)}
            </HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: "performanceDate",
      key: "uncleanPairCount",
      render: (performanceDate) => {
        return (
          <div>
            <span>clean Pair - </span>
            <span style={{ color: "gray", marginLeft: "5px" }}>
              {performanceDate[5]?.cleanUncleanCount.cleanPairCount}
            </span>
            <br />
            <span>Potential Pair - </span>
            <span style={{ color: "gray", marginLeft: "5px" }}>
              {performanceDate[5]?.cleanUncleanCount.uncleanPairCount}
            </span>
          </div>
        );
      },
    },
    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("userRole", sortOrder)}>
            <HeaderName>
              {formatDatev2(tableData[0]?.performanceDate[6]?.processDate)}
            </HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: "performanceDate",
      key: "uncleanPairCount",
      render: (performanceDate) => {
        return (
          <div>
            <span>clean Pair - </span>
            <span style={{ color: "gray", marginLeft: "5px" }}>
              {performanceDate[6]?.cleanUncleanCount.cleanPairCount}
            </span>
            <br />
            <span>Potential Pair - </span>
            <span style={{ color: "gray", marginLeft: "5px" }}>
              {performanceDate[6]?.cleanUncleanCount.uncleanPairCount}
            </span>
          </div>
        );
      },
    },

    {
      title: (
        <TableHeader>
          <Top>
            <HeaderName>Hold/Unhold</HeaderName>
          </Top>
        </TableHeader>
      ),
      align: "right",
      key: "siteHoldFromUI",

      render: (e) => {
        return (
          <ButtonContainer>
            <Edit>
              <Switch
                checked={e.siteHoldFromUI === 1 ? true : false}
                // // checked={switchValue}
                onChange={() => handleSwitch(e)}
              />
            </Edit>
          </ButtonContainer>
        );
      },
    },
  ];

  const handlePageSizeChange = (size) => {
    setPageCount(size);
    setCurrentPage(1);
  };

  const fetchData = () => {
    setLoading(true);
    const queryParams = {
      SitePerformanceInput: JSON.stringify({
        pageNumber: currentPage,
        pageSize: pageCount,
      }),
    };
    axiosConfig
      .get(
        `GetSitePerformanceCount/?`,

        {
          params: queryParams,
        }
      )
      .then((res) => {
        const { statusCode, data, paginationData } = res.data;
        if (statusCode === 200) {
          setTableData(data);

          setPaginationData(paginationData);
        } else {
          setTableData([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const [csv, setCsv] = useState([]);
  const fetchDataCsv = () => {
    setLoading(true);
    const queryParams = {
      SitePerformanceInput: JSON.stringify({
        pageNumber: currentPage,
        pageSize: 10000,
      }),
    };
    axiosConfig
      .get(
        `GetSitePerformanceCount/?`,

        {
          params: queryParams,
        }
      )
      .then((res) => {
        const { statusCode, data, paginationData } = res.data;
        if (statusCode === 200) {
          setCsv(data);

          setPaginationData(paginationData);
        } else {
          setCsv([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const fetchDataforCSV = async () => {
    setLoading(true);
    let allData = [];
    let currentPage = 1;
    let totalPages = 1; // Assume at least one page to start

    do {
      try {
        const queryParams = {
          SitePerformanceInput: JSON.stringify({
            pageNumber: currentPage,
            pageSize: 1000,
          }),
        };
        const res = await axiosConfig.get(`GetSitePerformanceCount/?`, {
          params: queryParams,
        });
        const { statusCode, data, paginationData } = res.data;

        if (statusCode === 200) {
          allData = allData.concat(data);
          totalPages = Math.ceil(paginationData.total / pageCount);
          currentPage++;
        } else {
          console.error("Failed to fetch data");
          break; // Exit loop on failure
        }
      } catch (err) {
        console.error(err);
        break; // Exit loop on error
      }
    } while (currentPage <= totalPages);

    setLoading(false);

    if (allData.length > 0) {
      setCSVData(allData); // Proceed to convert and download CSV
    }
  };

  const holdUnhold = (site) => {
    setLoading(true);
    const queryParams = {
      site: site,
    };
    axiosConfig
      .get(
        `UpdateSiteHoldFromUI?`,

        {
          params: queryParams,
        }
      )
      .then((res) => {
        setLoading(false);
        fetchData();
        fetchDataCsv();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // fetchDataforCSV();
    fetchDataCsv();
    // getSpecificUser()
  }, [pageCount, currentPage]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter?.column?.key) {
      const { field, order } = sorter;
      setSortFeild(order === "ascend" || "descend" ? field : null);
      setSortOrder(order === "ascend" ? "0" : order === "descend" ? "1" : "0");
    }
  };

  const [formatedArray, setFormatedArray] = useState([]);

  useEffect(() => {
    const temp = csv.map((data, i) => ({
      siteCode: data.site,
      siteHoldFromUI: data.siteHoldFromUI === 0 ? "Unhold" : "Hold",
      PairCount: data.performanceDate.map(
        (count) =>
          `Clean Pair: ${count.cleanUncleanCount.cleanPairCount}
		  Unclean Pair: ${count.cleanUncleanCount.uncleanPairCount}`
      ),
    }));

    setFormatedArray(temp);
  }, [csv]);
  var dateField = ["", "", "", "", "", "", ""];
  if (csv.length) {
    dateField = [];
    for (let i = 0; i < csv[0].performanceDate.length; i++) {
      dateField.push(formatDatev2(csv[0].performanceDate[i].processDate));
    }
  }

  const headers = [
    { label: "Site Code", key: "siteCode" },
    { label: dateField[0], key: "PairCount[0]" },
    { label: dateField[1], key: "PairCount[1]" },
    { label: dateField[2], key: "PairCount[2]" },
    { label: dateField[3], key: "PairCount[3]" },
    { label: dateField[4], key: "PairCount[4]" },
    { label: dateField[5], key: "PairCount[5]" },
    { label: dateField[6], key: "PairCount[6]" },
    { label: "Hold/Unhold", key: "siteHoldFromUI" },
  ];

  return (
    <Container>
      <TopSection>
        <Title>Site Performance</Title>
        <RightSection>
          <CsvIcon
            data={formatedArray}
            headers={headers}
            filename={"Site Performance.csv"}
          >
            <Tooltip title="Download CSV">
              <img
                style={{ width: "35px", height: "35px" }}
                src={downloadIcon}
              />
            </Tooltip>
          </CsvIcon>
        </RightSection>
      </TopSection>
      <SiteListContainer>
        {" "}
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          onChange={handleTableChange}
          loading={isLoading}
        />
        <BottomSection>
          <div>
            <span>Rows per page: </span>
            <Select
              defaultValue={pageCount}
              onChange={handlePageSizeChange}
              style={{ width: 80 }}
            >
              <Select.Option value={10}>10</Select.Option>
              <Select.Option value={15}>15</Select.Option>
              <Select.Option value={30}>30</Select.Option>
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={100}>100</Select.Option>
            </Select>
          </div>
          <PaginationCover>
            <Pagination
              total={paginationData?.count}
              currentPage={currentPage}
              pageSize={pageCount}
              onChange={handlePageChange}
              current={currentPage}
            />
          </PaginationCover>
        </BottomSection>
      </SiteListContainer>
    </Container>
  );
}

export default SitePerformance;
const Container = styled.div`
  padding: 20px;
  .ant-pagination-options {
    display: none;
  }
  :where(.css-dev-only-do-not-override-1vr7spz).ant-picker {
    padding: 0 11px;
  }
  :where(.css-dev-only-do-not-override-1vr7spz).ant-table-wrapper
    .ant-table-column-title,
  :where(.css-dev-only-do-not-override-1vr7spz).ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    font-size: 14px;
    font-weight: 500;
  }
  :where(.css-dev-only-do-not-override-3mqfnx).ant-table-wrapper
    .ant-table-thead
    > tr
    > th {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .custom-sort-header {
    cursor: pointer;
  }
`;
const TopSection = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
`;
const Title = styled.h2`
  color: ${COLORS.THEME_COLOR};
  font-size: 18px;
  font-weight: 500;
`;
const RightSection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
const SiteListContainer = styled.div`
  margin-top: 10px;
  max-height: calc(100vh - 180px);
  min-height: calc(100vh - 180px);
  overflow: scroll;
  border: 0.5px solid #d0d1d2;
  border-radius: 10px;
  .ant-table {
    min-height: calc(100vh - 240px) !important;
    max-height: calc(100vh - 240px) !important;
    overflow: scroll;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: 0.5px solid #d0d1d2;
    border-collapse: collapse;
    /* background-color: #fff; */
    padding: 10px 15px;
    font-size: 12px;
  }
  .ant-table-thead > tr > th {
    font-weight: 600;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
  }
  .ant-pagination-item a {
    color: #000 !important;
  }
`;
const ButtonContainer = styled.div`
  display: block;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
`;
const Edit = styled(Link)``;
const Delete = styled.span``;
const TableHeader = styled.div`
  input {
    padding: 0 10px;
  }
`;

const HeaderName = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
  white-space: nowrap;
`;
const BottomSection = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
`;
const PaginationCover = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;
const Top = styled.div`
  display: flex;
  align-items: center;
`;
const Sort = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translate(0, -5px);
  margin-left: 10px;
`;
const Icon = styled.span`
  display: block;
  height: 10px;
`;
const CsvIcon = styled(CSVLink)`
  padding: 4px;
`;
